const server = '/api'

const days: number[] = [30, 60, 90, 180, 360, 540]; // 计算最近表现

//echarts颜色大全，用于线条、柱状图等
//https://www.cnblogs.com/wuhairui/p/15561755.html
const echartsColor = ['#5470c6', '#73c0de', '#fac858', '#3ba272', '#fc8452', '#9a60b4', '#ea7ccc', '#000000', '#ee6666', '#FF3EFF']

type Callback = (data: any) => void
type CodeCallback = (code: string) => void
type CodesCallback = (codes: string[]) => void

//缓存
let cache: any = {}
let requestWaitMap: any = {}
//k线
let klineCodes: string[] = []
let code: string = ''
//选中的股票/基金代码s
let selectedCodes: string[] = []
let stgIdx = 1
let reportsMap: any
let markPoints: any = []
//@ts-ignore
const $ = (selector: string): any => document.querySelector(selector)
const log = (...data: any[]) => console.log(...data)
const info = (...data: any[]) => console.info(...data)
const debug = (...data: any[]) => console.debug(...data)
const zeroPad = (num: number, places: number) => String(num).padStart(places, '0')
const queryString = (obj: any) => Object.keys(obj).map(k => `${k}=${encodeURIComponent(obj[k])}`).join('&')

const securities = [
  //代码 名字
  //数字币
  ['COIN.US', 'coinbase'],
  ['BTC-USD.DC', '比特币'],
  ['ETH-USD.DC', '以太币'],
  ['ETHE.DC', '以太基金'],
  //ETF
  ['513180.SF', '恒生科技ETF'],
  ['159605.ZF', '中概互联ETF'],
  ['513050.SF', '中概互联网ETF'],
  ['KWEB.US', '中概互联网'],
  ['513330.SF', '恒生互联网ETF'],
  ['513050.SF', '中概互联'],
  ['510300.SF', '沪深300ETF'],
  ['159601.ZF', 'A50ETF'],
  ['510050.SF', '上50ETF'],
  //消费
  ['600519.SH', '贵州茅台'],
  ['000858.SZ', '五粮液'],
  ['000568.SZ', '泸州老窖'],
  ['000333.SZ', '美的集团'],
  ['000651.SZ', '格力电器'],
  ['600690.SH', '海尔智家'],
  ['600887.SH', '伊利股份'],
  ['09633.HK', '农夫山泉'],
  //互联网
  ['01024.HK', '快手'],
  ['00700.HK', '腾讯'],
  ['03690.HK', '美团'],
  ['01810.HK', '小米'],
  ['PDD.US', '拼多多'],
  ['BABA.US', '阿里巴巴'],
  ['JD.US', '京东'],
  ['NTES.US', '网易'],
  //新能车
  ['300750.SZ', '宁德时代'],
  ['002594.SZ', '比亚迪'],
  ['LI.US', '理想'],
  //银行保险
  ['601398.SH', '工商银行'],
  ['601288.SH', '农业银行'],
  ['601939.SH', '建设银行'],
  ['601328.SH', '交通银行'],
  ['601988.SH', '中国银行'],
  ['600036.SH', '招商银行'],
  ['601658.SH', '邮储银行'],
  ['601318.SH', '中国平安'],
  //医美
  ['300760.SZ', '迈瑞医疗'],
  ['603259.SH', '药明康德'],
  ['02269.HK', '药明生物'],
  ['300122.SZ', '智飞生物'],
  ['300896.SZ', '爱美客'],
  //通信
  ['600941.SH', '中国移动'],
  ['601728.SH', '中国电信'],
  ['600050.SH', '中国联通'],
  ['000063.SZ', '中兴通讯'],
  
  
  //公共事业
  ['601088.SH', '中国神华'],
  ['601225.SH', '陕西煤业'],
  ['600900.SH', '长江电力'],
  ['601985.SH', '中国核电'],
  ['601857.SH', '中国石油'],
  ['600938.SH', '中国海油'],
  ['600028.SH', '中国石化'],
  ['601006.SH', '大秦铁路'],
  ['600803.SH', '新奥股份'],
  //大宗商品
  ['601899.SH', '紫金矿业'],
  //军工
  ['002179.SZ', '中航光电'],
  ['600760.SH', '中航沈飞'],
  //其它
  ['600660.SH', '福耀玻璃'],
  ['002475.SZ', '立讯精密'],
  ['002027.SZ', '分众传媒'],
  ['002415.SZ', '海康威视'],
]

//美股期权
const usOptionSecurities: any[][] = [
  // 代码,名称, 做多保证金, 做空保证金比例
  ['COIN.US', 'coinbase', 0.55, 0.65],
  ['TLT.US', '20+债券', 0.3, 0.35],
  ['ARKK.US', 'ARKK', 0.3, 0.35],
  ['AAPL.US', '苹果', 0.3, 0.35],
  ['BITO.US', 'bito', 0.3, 0.25],
  ['SQ.US', 'square', 0.3, 0.35],
  ['HOOD.US', '罗宾汉', 0.3, 0.35],
  ['NVDA.US', 'nvidia', 0.3, 0.35],
  ['BABA.US', '阿里巴巴', 0.3, 0.35],
  ['BEKE.US', '贝壳', 0.3, 0.35],
  ['DIDIY.US', '滴滴', 1, 1],
  ['MSFT.US', '微软', 0.3, 0.35],
  ['TSLA.US', '特斯拉', 0.3, 0.35],
  ['MSTR.US', 'MicroStrategy', 0.4, 0.4],
  ['BAC.US', '美国银行', 0.3, 0.35],
  ['JPM.US', 'JP摩根', 0.3, 0.35],
  ['GOLD.US', '巴里克', 0.3, 0.35],
  ['NFLX.US', 'Netflix', 0.3, 0.35],
  ['AMD.US', 'AMD', 0.3, 0.35],
  ['FB.US', '脸书', 0.3, 0.35],
  ['JD.US', '京东', 0.3, 0.35],
  ['CRM.US', 'salesforce', 0.35, 0.4],
  ['ADBE.US', 'adobe', 0.3, 0.35],
  ['SPY.US', '标普500', 0.3, 0.35],
  ['QQQ.US', '纳指100', 0.3, 0.35],
  ['IWM.US', '罗素2000', 0.3, 0.35],
  ['^VIX.US', '波动率指数', 1, 1],
  ['GLD.US', '黄金ETF', 0.3, 0.35],
  ['AMZN.US', '亚马逊', 0.3, 0.35],
  ['ARKW.US', 'ARKW', 0.3, 0.35],
  ['HD.US', '家得宝', 0.3, 0.35],
  ['JNJ.US', '强生', 0.3, 0.35],
]

//后缀和东财映射表
const marketMap: any = {
  'SZ': 0,//深证
  'BJ': 0,//北交所
  'SH': 1,//上证
  'ZF': 0,//深证场内基金
  'SF': 1,//上证场内基金
  'ZI': 0,//深证指数
  'SI': 1,//上证指数
  'HI': 2,//H开头的指数
  'SO': 10,//上证期权
  'ZO': 12,//深证期权
  'LO': -1,//本地存储期权
  'OF': 150,//场外基金
  'HK': 116,//港股
  'O': 105,//纳斯达克
  'N': 106,//纽交所
  'AF': 107,//美股基金
}

//核心指标
const coreKeys: string[][] = [
  //key、描述、字体颜色(默认无需填写)
  ['totalOperateIncome', '营业总收入'], //营业总收入
  ['netProfit', '净利润'], //净利润
  ['roa', '总资产收益率(%)'],
  ['roe', '净资产收益率(%)'],
  ['grossProfitRatio', '毛利润率(%)'],
  ['netProfitRatio', '净利润率(%)'],
  ['totalAssetsTurnover', '总资产周转率'],
  ['assetLiabRatio', '资产负债率(%)'],
  ['equityMultiplier', '权益乘数']
]

//利润表财报key映射
const incomeKeys = [
  //key，中文名, A股字段名，港股字段名，美股字段名，标色
  //收入
  ['totalOperateIncome', '营业总收入', 'TOTAL_OPERATE_INCOME', '营运收入', '营业收入', 'info'], //营业总收入
  ['operateIncome', '营业收入', 'OPERATE_INCOME', '营业额', '主营收入'], //营业收入
  ['interestIncome', '利息收入', 'INTEREST_INCOME', '', ''], //利息收入
  ['feeCommissionIncome', '手续费及佣金收入', 'FEE_COMMISSION_INCOME', '', ''], //手续费及佣金收入
  ['otherBusinessIncome', '其他业务收入', 'OTHER_BUSINESS_INCOME', '其他营业收入', '其他业务收入'], //其他营业收入
  ['otherBusinessIncome', '其他业务收入', '', '营运收入其他项目', ''], //营运收入其他项目(old) -美团-2020-06
  
  //成本
  ['totalOperateCost', '营业总成本', 'TOTAL_OPERATE_COST', '营运支出', '营业成本', 'info'], //营业总成本
  ['operateCost', '营业成本', 'OPERATE_COST', '销售成本', '主营成本'], //营业成本
  ['grossProfit', '毛利', '', '毛利', '毛利'], //毛利
  ['interestExpense', '利息支出', 'INTEREST_EXPENSE', '', ''], //利息支出
  ['feeCommissionExpense', '手续费及佣金支出', 'FEE_COMMISSION_EXPENSE', '', ''], //手续费及佣金支出
  ['operateTaxAdd', '营业税金及附加', 'OPERATE_TAX_ADD'], //营业税金及附加
  //费用
  ["totalOperateExpense", '营业总费用', '', '',"营业费用", 'info'], //销售、管理、研发、财务费用、减值等总计总计(美股)
  ['saleExpense', '销售费用', 'SALE_EXPENSE', '销售及分销费用', '营销费用'], //销售费用
  ['manageExpense', '管理费用', 'MANAGE_EXPENSE', '行政开支', '一般及行政费用'], //管理费用
  ['researchExpense', '研发费用', 'RESEARCH_EXPENSE', '研发费用', '研发费用'], //研发费用
  ['financeExpense', '财务费用', 'FINANCE_EXPENSE', '融资成本'], //财务费用
  ['feInterestExpense', '其中:利息费用', 'FE_INTEREST_EXPENSE', '', '利息支出'], //其中:利息费用
  ['feInterestIncome', '其中:利息收入', 'FE_INTEREST_INCOME', '利息收入', '利息收入'], //其中:利息收入
  ['assetImpairmentLoss', '资产减值损失(旧)', 'ASSET_IMPAIRMENT_LOSS', '减值及拨备', '减值及拨备'], //资产减值损失(旧)
  ['creditImpairmentLoss', '信用减值损失(旧)', 'CREDIT_IMPAIRMENT_LOSS'], //信用减值损失(旧)
  //其他经营收益
  ['fairvalueChangeIncome', '加:公允价值变动收益', 'FAIRVALUE_CHANGE_INCOME', '重估盈余', '公允价值变动损益'], //加:公允价值变动收益
  ["equityInvestIncome", '权益性投资损益', '', '溢利其他项目',"权益性投资损益"],
  ['investIncome', '投资收益', 'INVEST_INCOME', '', '投资性减值准备'], //投资收益
  ['investJointIncome', '其中:对联营企业和合营企业的投资收益', 'INVEST_JOINT_INCOME', '应占联营公司溢利'], //其中:对联营企业和合营企业的投资收益
  ['assetDisposalIncome', '资产处置收益', 'ASSET_DISPOSAL_INCOME'], //资产处置收益
  ['assetImpairmentIncome', '资产减值损失(新)', 'ASSET_IMPAIRMENT_INCOME'], //资产减值损失(新)
  ['creditImpairmentIncome', '信用减值损失(新)', 'CREDIT_IMPAIRMENT_INCOME'], //信用减值损失(新)
  ["exchangeIncome", '汇兑损益', '', '',"汇兑损益"],
  ['otherIncome', '其他收益', 'OTHER_INCOME', '其他收益', '其他收入(支出)'], //其他收益
  //利润
  ['operateProfit', '营业利润', 'OPERATE_PROFIT', '经营溢利', '营业利润', 'info'], //营业利润
  ['nonbusinessIncome', '加:营业外收入', 'NONBUSINESS_INCOME', '其它收入'], //加:营业外收入
  ['noneCurrentDisposalIncome', '其中:非流动资产处置利得', 'NONCURRENT_DISPOSAL_INCOME'], //其中:非流动资产处置利得
  ['nonbusinessExpense', '减:营业外支出', 'NONBUSINESS_EXPENSE', '其他支出'], //减:营业外支出
  ['noneCurrentDisposalLoss', '其中:非流动资产处置净损失', 'NONCURRENT_DISPOSAL_LOSS'], //其中:非流动资产处置净损失
  ['totalProfit', '利润总额', 'TOTAL_PROFIT', '除税前溢利', '持续经营税前利润'], //利润总额
  ['incomeTax', '减:所得税', 'INCOME_TAX', '税项', '所得税'], //减:所得税
  ['netProfit', '净利润', 'NETPROFIT', '除税后溢利', '净利润'], //净利润
  //(一)按经营持续性分类
  ['continuedNetProfit', '持续经营净利润', 'CONTINUED_NETPROFIT', '持续经营业务税后利润', '持续经营净利润', 'info'], //持续经营净利润
  //(二)按所有权归属分类
  ['priorityNetprofit', '归属于优先股净利润及其他项', '', '', '归属于优先股净利润及其他项'], //归属于优先股净利润及其他项
  ['commonShareHoldersNetprofit', '归属于普通股股东净利润', '', '', '归属于普通股股东净利润'], //归属于普通股股东净利润
  ['parentNetprofit', '归属于母公司股东的净利润', 'PARENT_NETPROFIT', '股东应占溢利', '归属于母公司股东净利润'], //归属于母公司股东的净利润
  ['minorityInterest', '少数股东损益', 'MINORITY_INTEREST', '少数股东损益', '少数股东损益'], //少数股东损益
  ['deductParentNetprofit', '扣除非经常性损益后的净利润', 'DEDUCT_PARENT_NETPROFIT'], //扣除非经常性损益后的净利润
  //每股收益
  ['basicEps', '基本每股收益', 'BASIC_EPS', '每股基本盈利', '基本每股收益-普通股', 'info'], //基本每股收益
  ['dilutedEps', '稀释每股收益', 'DILUTED_EPS', '每股摊薄盈利', '摊薄每股收益-普通股'], //稀释每股收益
  ["basicEpsAds", '基本每股收益-ADS', '', '',"基本每股收益-ADS"],
  ["dilutedEpsAds", '摊薄每股收益-ADS', '', '',"摊薄每股收益-ADS"],
  //综合收益总额
  ['totalCompreIncome', '综合收益总额', 'TOTAL_COMPRE_INCOME', '全面收益总额', '全面收益总额', 'info'], //综合收益总额
  ['parentTci', '归属于母公司股东的综合收益总额', 'PARENT_TCI', '本公司拥有人应占全面收益总额', '本公司拥有人占全面收益总额'], //归属于母公司股东的综合收益总额
  ['minorityTci', '归属于少数股东的综合收益总额', 'MINORITY_TCI', '非控股权益应占全面收益总额', '非控股权益占全面收益总额'], //归属于少数股东的综合收益总额
  ['otherCompreIncome', '其他综合收益', 'OTHER_COMPRE_INCOME', '其他全面收益', '其他全面收益合计项'], //其他综合收益
  ['otherCompreIncomeOther', '其他全面收益其他项目', '', '其他全面收益其他项目', '其他全面收益其他项目'], //其他全面收益其他项目
  ['parentOci', '归属于母公司股东的其他综合收益', 'PARENT_OCI'], //归属于母公司股东的其他综合收益
  ['minorityOci', '归属于少数股东的其他综合收益', 'MINORITY_OCI'], //归属于少数股东的其他综合收益
]

//资产负债表
const balanceKeys = [
  //key，中文名, A股字段名，港股字段名，美股字段名，标色
  //资产
  ['totaAssets', '资产总计', 'TOTAL_ASSETS', '总资产', '总资产', 'info'], //资产总计
  //流动资产
  ['totalCurrentAssets', '流动资产合计', 'TOTAL_CURRENT_ASSETS', '流动资产合计', '流动资产合计', 'info'], //流动资产合计
  ['monetaryFunds', '货币资金', 'MONETARYFUNDS', '现金及等价物', '现金及现金等价物'], //货币资金
  ['restrictedMonetary', '受限制存款及现金', '', '受限制存款及现金', '限制性现金及其他(流动)'], //受限制存款及现金
  ['lendFund', '拆出资金', 'LEND_FUND'], //拆出资金
  ['tradeFinassetNotfvtpl', '交易性金融资产', 'TRADE_FINASSET_NOTFVTPL', '短期投资', '短期投资'], //交易性金融资产
  //['tradeFinassetNotfvtpl', '交易性金融资产', 'TRADE_FINASSET'], //交易性金融资产(旧) - 五粮液 - 2013-03-31
  ['deriveFinasset', '衍生金融资产', 'DERIVE_FINASSET'], //衍生金融资产
  ['noteAccountsRece', '应收票据及应收账款', 'NOTE_ACCOUNTS_RECE'], //应收票据及应收账款
  ['noteRece', '其中:应收票据', 'NOTE_RECE'], //其中:应收票据
  ['accountsRece', '其中:应收账款', 'ACCOUNTS_RECE', '应收帐款', '应收账款'], //其中:应收账款
  ['accountsReceToRelatedParties', '应收关联方款项', '', '', '应收关联方款项'], //应收关联方款项
  ['financeRece', '应收款项融资', 'FINANCE_RECE'], //应收款项融资
  ['prepayment', '预付款项', 'PREPAYMENT', '预付款按金及其他应收款', '预付款项(流动)'], //预付款项
  ['totalOtherRece', '其他应收款合计', 'TOTAL_OTHER_RECE'], //其他应收款合计
  ['interestRece', '其中:应收利息', 'INTEREST_RECE'], //其中:应收利息
  ['dividendRece', '其中:应收股利', 'DIVIDEND_RECE'], //其中:应收股利
  ['otherRece', '其中:其他应收款', 'OTHER_RECE'], //其中:其他应收款
  ['buyResaleFinasset', '买入返售金融资产', 'BUY_RESALE_FINASSET'], //买入返售金融资产
  ['inventory', '存货', 'INVENTORY', '存货'], //存货
  ['contractAsset', '合同资产', 'CONTRACT_ASSET'], //合同资产
  ['noncurrentAsset1year', '一年内到期的非流动资产', 'NONCURRENT_ASSET_1YEAR'], //一年内到期的非流动资产
  ['holdsaleAsset', '持有待售资产', 'HOLDSALE_ASSET', '持作出售的资产(流动)'], //持有待售资产
  ['otherCurrentAsset', '其他流动资资产', 'OTHER_CURRENT_ASSET'], //其他流动资资产
  //非流动资产
  ['totalNoncurrentAssets', '非流动资产合计', 'TOTAL_NONCURRENT_ASSETS', '非流动资产合计', '非流动资产合计', 'info'], //非流动资产
  ['loanAdvance', '发放贷款及垫款', 'LOAN_ADVANCE'], //发放贷款及垫款
  ['creditorInvest', '债权投资', 'CREDITOR_INVEST'], //债权投资
  ['avaiableSaleFinasset', '可供出售金融资产', 'AVAILABLE_SALE_FINASSET'], //可供出售金融资产
  ['longRece', '长期应收款', 'LONG_RECE', '', '其他长期应收款'], //长期应收款
  ['advancePayment', '预付款项', '', '预付款项'], //预付款项
  ['holdMaturityInvest', '持有至到期投资', 'HOLD_MATURITY_INVEST'], //持有至到期投资
  ['longEquityInvest', '长期股权投资', 'LONG_EQUITY_INVEST', '长期投资'], //长期股权投资
  ['otherEquityInvest', '其他权益工具投资', 'OTHER_EQUITY_INVEST', '其他投资'], //其他权益工具投资
  ['otherNoncurrentFinasset', '其他非流动金融资产', 'OTHER_NONCURRENT_FINASSET', '指定以公允价值记账之金融资产'], //其他非流动金融资产
  ['investRealestate', '投资性房地产', 'INVEST_REALESTATE'], //投资性房地产
  ['fixedAsset', '固定资产', 'FIXED_ASSET', '物业厂房及设备', '物业、厂房及设备'], //固定资产
  ['cip', '在建工程', 'CIP'], //在建工程
  ['projectMaterial', '工程物资', 'PROJECT_MATERIAL'], //工程物资
  ['userightAsset', '使用权资产', 'USERIGHT_ASSET'], //使用权资产
  ['productiveBiologyAsset', '生产性生物资产', 'PRODUCTIVE_BIOLOGY_ASSET'],
  ['fixedAssetDisposal', '固定资产清理', 'FIXED_ASSET_DISPOSAL'], //固定资产清理
  ['intangibleAsset', '无形资产', 'INTANGIBLE_ASSET', '无形资产', '无形资产'], //无形资产
  ['developExpense', '开发支出', 'DEVELOP_EXPENSE'], //开发支出
  ['goodwill', '商誉', 'GOODWILL'], //商誉
  ['longPrepaidExpense', '长期待摊费用', 'LONG_PREPAID_EXPENSE'], //长期待摊费用
  ['deferTaxAsset', '递延所得税资产', 'DEFER_TAX_ASSET', '递延税项资产', '递延所得税资产(非流动)'], //递延所得税资产
  ['otherNoncurrentAsset', '其他非流动资资产', 'OTHER_NONCURRENT_ASSET', '', '其他非流动资产'], //其他非流动资资产
  ['noncurrentAssetOther', '非流动资产其他项目', '', '', '非流动资产其他项目'], //非流动资产其他项目
  //负债
  ['totalLiabilities', '负债合计', 'TOTAL_LIABILITIES', '总负债', '总负债', 'info'], //总负债金额
  //流动负债
  ['totalCurrentLiab', '流动负债合计', 'TOTAL_CURRENT_LIAB', '流动负债合计', '流动负债合计', 'info'], //流动负债合计
  ['shortLoan', '短期借款', 'SHORT_LOAN', '短期贷款'], //短期借款
  ['tradeFinliabNotfvtpl', '交易性金融负债', 'TRADE_FINLIAB_NOTFVTPL'], //交易性金融负债
  ['tradeFinliabNotfvtpl', '交易性金融负债', 'FVTPL_FINLIAB', '指定以公允价值记账之金融负债'], //以公允价值计量且其变动计入当期损益的金融负债
  ['deriveFinliab', '衍生金融负债', 'DERIVE_FINLIAB'], //衍生金融负债
  ['noteAccountsPayable', '应付票据及应付账款', 'NOTE_ACCOUNTS_PAYABLE'], //应付票据及应付账款
  ['notePayable', '其中:应付票据', 'NOTE_PAYABLE', '应付票据'], //其中:应付票据
  ['accountsPayable', '其中:应付账款', 'ACCOUNTS_PAYABLE', '应付帐款', '应付账款'], //其中:应付账款
  ['accountsPayableToRelatedparties', '应付关联方款项(流动)', '', '应付关联方款项(流动)', '应付关联方款项(流动)'], //应付关联方款项(流动)
  ['shortDebt', '短期债务', '', '', '短期债务'], //短期债务
  ['shortLeaseLiab', '短期租赁负债', '', '融资租赁负债(流动)', '资本租赁债务(流动)'], //租赁负债(流动)
  ['shortDeferIncome', '短期递延收入', '', '递延收入(流动)'], //短期递延收入
  ['advanceReceivables', '预收款项', 'ADVANCE_RECEIVABLES', '预收款项', '预收及预提费用'], //预收款项
  ['customerDeposit', '客户存款及垫款', '', '', '客户存款及垫款'], //客户存款及垫款
  ['contractLiab', '合同负债', 'CONTRACT_LIAB'], //合同负债
  ['acceptDepositInterbank', '吸收存款及同业存放', 'ACCEPT_DEPOSIT_INTERBANK'], //吸收存款及同业存放
  ['staffSalaryPayable', '应付职工薪酬', 'STAFF_SALARY_PAYABLE'], //应付职工薪酬
  ['taxPayable', '应交税费', 'TAX_PAYABLE', '应付税项'], //应交税费
  ['totalOtherPayable', '其他应付款合计', 'TOTAL_OTHER_PAYABLE', ''], //其他应付款合计
  ['interestPayable', '其中:应付利息', 'INTEREST_PAYABLE'], //其中:应付利息
  ['dividendPayable', '其中:应付股利', 'DIVIDEND_PAYABLE'], //其中:应付股利
  ['otherPayable', '其中:其他应付款', 'OTHER_PAYABLE', '其他应付款及应计费用'], //其中:其他应付款
  ['noncurrentLiab1year', '一年内到期的非流动负债', 'NONCURRENT_LIAB_1YEAR'], //一年内到期的非流动负债
  ['holdsaleLiab', '持作出售的负债(流动)', '', '持作出售的负债(流动)'], //持作出售的负债(流动)
  ['otherCurrentLiab', '其他流动负债', 'OTHER_CURRENT_LIAB', '流动负债其他项目'], //其他流动负债
  //['otherCurrentLiab', '其他流动负债', 'NONCURRENT_LIAB_OTHER'], //其他流动负债(旧)
  //非流动负债
  ['totalNoncurrentLiab', '非流动负债合计', 'TOTAL_NONCURRENT_LIAB', '非流动负债合计', '非流动负债合计', 'info'], //非流动负债合计
  ['longLoan', '长期借款', 'LONG_LOAN', '长期贷款'], //长期借款
  ['specialPayable', '长期应付款', 'SPECIAL_PAYABLE'], //长期应付款(旧)
  ['longStaffsalaryPayable', '长期应付职工薪酬', 'LONG_STAFFSALARY_PAYABLE'], //长期应付职工薪酬
  ['bondPayable', '应付债券', 'BOND_PAYABLE'], //应付债券
  ['longNotePayable', '长期应付票据', '', '应付票据(非流动)'], //应付票据
  ['noteBondPayable', '可转换票据及债券', '', '', '可转换票据及债券'], //可转换票据及债券
  ['leaseLiab', '租赁负债', 'LEASE_LIAB', '融资租赁负债(非流动)', '资本租赁债务(非流动)'], //租赁负债
  ['longPayable', '长期应付款', 'LONG_PAYABLE'], //长期应付款
  ['predictLiab', '预计负债', 'PREDICT_LIAB'], //预计负债
  ['deferIncome', '递延收益', 'DEFER_INCOME', '递延收入(非流动)'], //递延收益
  ['deferTaxLiab', '递延所得税负债', 'DEFER_TAX_LIAB', '递延税项负债', '递延所得税负债(非流动)'], //递延所得税负债
  ['otherNoncurrentLiab', '其他非流动负债', 'OTHER_NONCURRENT_LIAB', '其他非流动负债', '其他非流动负债'], //其他非流动负债
  ['noncurrentLiabOther', '非流动负债其他项目', '', '非流动负债其他项目'], //非流动负债其他项目
  //所有者权益(或股东权益)
  ['totalLiabEquity', '负债和股东权益总计', 'TOTAL_LIAB_EQUITY', '总权益及总负债', '负债及股东权益合计', 'info'], //负债和股东权益总计
  ['totalEquity', '股东权益合计', 'TOTAL_EQUITY', '总权益', '股东权益合计'], //股东权益合计
  ['totalParentEquity', '归属于母公司股东权益总计', 'TOTAL_PARENT_EQUITY', '股东权益', '归属于母公司股东权益'], //归属于母公司股东权益总计
  ['minorityEquity', '少数股东权益', 'MINORITY_EQUITY', '少数股东权益'], //少数股东权益
  //资本构成
  ['shareCapital', '实收资本（或股本）', 'SHARE_CAPITAL', '股本', '普通股', 'info'], //实收资本（或股本）
  ['capitalReserve', '资本公积', 'CAPITAL_RESERVE', '股本溢价', '股本溢价'], //资本公积
  ['treasuryShares', '减:库存股', 'TREASURY_SHARES'], //减:库存股
  ['balanceOtherCompreIncome', '其他综合收益', 'OTHER_COMPRE_INCOME', '', '其他综合收益'], //其他综合收益
  ['specialReserve', '专项储备', 'SPECIAL_RESERVE'], //专项储备
  ['surplusReserve', '盈余公积', 'SURPLUS_RESERVE'], //盈余公积
  ['unassignRpofit', '未分配利润', 'UNASSIGN_RPOFIT', '保留溢利(累计亏损)', '留存收益'], //未分配利润
  ['generalRiskReserve', '一般风险准备', 'GENERAL_RISK_RESERVE'], //一般风险准备
]

//现金流量表
const cashflowKeys = [
  //key，中文名, A股字段名，港股字段名，美股字段名，标色
  //经营活动产生的现金流量
  ['netcashOperate', '经营活动产生的现金流量净额', 'NETCASH_OPERATE', '经营业务现金净额', '经营活动产生的现金流量净额', 'info'], //经营活动产生的现金流量净额
  //流入
  ['totalOperateInflow', '经营活动现金流入小计', 'TOTAL_OPERATE_INFLOW'], //经营活动现金流入小计
  ['salesServices', '销售商品、提供劳务收到的现金', 'SALES_SERVICES', '经营产生现金'], //销售商品、提供劳务收到的现金
  ['depositInterbankAdd', '客户存款和同业存放款项净增加额', 'DEPOSIT_INTERBANK_ADD'], //客户存款和同业存放款项净增加额
  ['receiveInterestCommission', '收取利息、手续费及佣金的现金', 'RECEIVE_INTEREST_COMMISSION'], //收取利息、手续费及佣金的现金
  ['receiveTaxRefund', '收到的税收返还', 'RECEIVE_TAX_REFUND'], //收到的税收返还
  ['receiveOtherOperate', '收到其他与经营活动有关的现金', 'RECEIVE_OTHER_OPERATE'], //收到其他与经营活动有关的现金
  //流出
  ['totalOperateOutflow', '经营活动现金流出小计', 'TOTAL_OPERATE_OUTFLOW'], //经营活动现金流出小计
  ['buyServices', '购买商品、接受劳务支付的现金', 'BUY_SERVICES'], //购买商品、接受劳务支付的现金
  ['loanAdvanceAdd', '客户贷款及垫款净增加额', 'LOAN_ADVANCE_ADD'], //客户贷款及垫款净增加额
  ['pbcInterbankAdd', '存放中央银行和同业款项净增加额', 'PBC_INTERBANK_ADD'], //存放中央银行和同业款项净增加额
  ['payInterestCommission', '支付利息、手续费及佣金的现金', 'PAY_INTEREST_COMMISSION'], //支付利息、手续费及佣金的现金
  ['payStaffCash', '支付给职工以及为职工支付的现金', 'PAY_STAFF_CASH'], //支付给职工以及为职工支付的现金
  ['payAllTax', '支付的各项税费', 'PAY_ALL_TAX', '已付税项'], //支付的各项税费
  ['payOtherOperate', '支付其他与经营活动有关的现金', 'PAY_OTHER_OPERATE'], //支付其他与经营活动有关的现金
  ['operateOutflowOther', '经营活动现金流出的其他项目', 'OPERATE_OUTFLOW_OTHER'], //经营活动现金流出的其他项目
 
  //投资活动产生的现金流量
  ['netcashInvest', '投资活动产生的现金流量净额', 'NETCASH_INVEST', '投资业务现金净额', '投资活动产生的现金流量净额', 'info'], //投资活动产生的现金流量净额
  //流入
  ['totalInvestInflow', '投资活动现金流入小计', 'TOTAL_INVEST_INFLOW'], //投资活动现金流入小计
  ['withdrawInvest', '收回投资收到的现金', 'WITHDRAW_INVEST', '收回投资所得现金'], //收回投资收到的现金
  ['receiveInvestIncome', '取得投资收益收到的现金', 'RECEIVE_INVEST_INCOME'], //取得投资收益收到的现金
  ['receiveInvestDividend', '已收股息(投资)', '', '已收股息(投资)'], //已收股息(投资)
  ['disposalLongAsset', '处置固定资产、无形资产和其他长期资产收回的现金净额', 'DISPOSAL_LONG_ASSET', '处置固定资产'], //处置固定资产、无形资产和其他长期资产收回的现金净额
  ['obtainSubsidiaryOther', '取得子公司及其他营业单位支付的现金净额', 'OBTAIN_SUBSIDIARY_OTHER'], //收到的其他与投资活动有关的现金
  ['receiveOtherInvest', '收到的其他与投资活动有关的现金', 'RECEIVE_OTHER_INVEST'], //收到的其他与投资活动有关的现金
  //流出
  ['totalInvestOutflow', '投资活动现金流出小计', 'TOTAL_INVEST_OUTFLOW'], //投资活动现金流出小计
  ['constructLongAsset', '购建固定资产、无形资产和其他长期资产支付的现金', 'CONSTRUCT_LONG_ASSET', '购建固定资产'], //购建固定资产、无形资产和其他长期资产支付的现金
  ['investPayCash', '投资支付的现金', 'INVEST_PAY_CASH', '投资支付现金'], //投资支付的现金
  ['payOtherInvest', '支付其他与投资活动有关的现金', 'PAY_OTHER_INVEST'], //支付其他与投资活动有关的现金
  
  //筹资活动产生的现金流量
  ['netcashFinance', '筹资活动产生的现金流量净额', 'NETCASH_FINANCE', '融资业务现金净额', '筹资活动产生的现金流量净额', 'info'], //筹资活动产生的现金流量净额
  //流入
  ['totalFinanceInflow', '筹资活动现金流入小计', 'TOTAL_FINANCE_INFLOW'], //筹资活动现金流入小计
  ['acceptInvestCash', '吸收投资收到的现金', 'ACCEPT_INVEST_CASH'], //吸收投资收到的现金
  ['subsidiaryAcceptInvest', '其中:子公司吸收少数股东投资收到的现金', 'SUBSIDIARY_ACCEPT_INVEST'], //其中:子公司吸收少数股东投资收到的现金
  ['receiveLoanCash', '取得借款收到的现金', 'RECEIVE_LOAN_CASH'], //取得借款收到的现金
  ['receiveOtherFinance', '收到的其他与筹资活动有关的现金', 'RECEIVE_OTHER_FINANCE'], //收到的其他与筹资活动有关的现金
  //流出
  ['totalFinanceOutflow', '筹资活动现金流出小计', 'TOTAL_FINANCE_OUTFLOW'], //筹资活动现金流出小计
  ['payDebtCash', '偿还债务所支付的现金', 'PAY_DEBT_CASH'], //偿还债务所支付的现金
  ['assignDividendPorfit', '分配股利、利润或偿付利息支付的现金', 'ASSIGN_DIVIDEND_PORFIT'], //分配股利、利润或偿付利息支付的现金
  ['subsidiaryPayDividend', '其中:子公司支付给少数股东的股利、利润', 'SUBSIDIARY_PAY_DIVIDEND'], //其中:子公司支付给少数股东的股利、利润
  ['payOtherFinance', '支付的其他与筹资活动有关的现金', 'PAY_OTHER_FINANCE'], //支付的其他与筹资活动有关的现金

  //其它
  ['cceAdd', '现金及现金等价物净增加额', 'CCE_ADD', '现金净额', '现金及现金等价物增加(减少)额', 'info'], //现金及现金等价物净增加额
  ['beginCce', '期初现金及现金等价物余额', 'BEGIN_CCE', '期初现金', '现金及现金等价物期初余额'], //加:期初现金及现金等价物余额
  ['endCce', '期末现金及现金等价物余额', 'END_CCE', '期末现金', '现金及现金等价物期末余额'], //期末现金及现金等价物余额
  ['rateChangeEffect', '汇率变动对现金及现金等价物的影响', 'RATE_CHANGE_EFFECT', '', '汇率变动影响'], //汇率变动对现金及现金等价物的影响
]

//财报忽略的keys
const ignoreKeys = [
  'CONVERT_DIFF',
  'OPERATE_PROFIT_BALANCE',
  'ABLE_OCI',
  'CURRENT_ASSET_BALANCE',
  'LIAB_BALANCE',
  'NONCURRENT_ASSET_BALANCE',
  'NONCURRENT_LIAB_BALANCE',
  'CURRENT_LIAB_BALANCE',
  'PARENT_EQUITY_BALANCE',
  'EQUITY_BALANCE',
  'ASSET_BALANCE',
  '基本加权平均股数-普通股',
  '摊薄加权平均股数-普通股',
  '非运算项目',
  '其他储备',
  '总权益及非流动负债',
  '净流动资产',
  '总资产减流动负债',
  '净资产',
  '总资产减总负债合计',
  '股东权益其他项目',
  '可转换可赎回优先股',
  '优先股',
  '归属于母公司股东权益其他项目',
]

// 代码和名字映射
let codeNameMap: any = {
  'PDD.US': '拼多多'
}
let usCodeMap: any ={
  'PDD.US': 'PDD.O',
  'BEKE.US': 'BEKE.N'
}
let query = parseQuery(window.location.search)

//@ts-ignore
HTMLElement.prototype.val = function(){
  let arr: string[] = []
  //@ts-ignore
  this.querySelectorAll('option').forEach((option)=>{
    if (option.hasAttribute('selected')) {
      arr.push(option.value)
    }
  })
  return arr.filter((item, pos) => arr.indexOf(item) == pos)
}

//保留小数位数
//@ts-ignore
Number.prototype.toFixed = function(n: number): number {
  let pow = Math.pow(10, n)
  return Math.round(this.valueOf()*pow)/pow
}

//@ts-ignore
String.prototype.toFixed = function(n: number): string {
  return this.toString()
}

//获取当前页面名字，比如company.html
function currentPage() {
  let arr = window.location.pathname.split('/')
  return arr[arr.length-1]
}

//替换url参数
function replaceUrlParam(key: string, value: string) {
  const url = new URL(window.location.href)
  //@ts-ignore
  url.searchParams.set(key, value)
  //@ts-ignore
  window.history.replaceState(null, null, url) // or pushState
}

//按照亿单位格式化数据
function formatReportNumber(n: any, isPercent: boolean): string {
  if (n == '-') {
    return n
  } else if (isPercent) {
    return (n*100).toFixed(2)
  } else if (n >= 1e11 || n <= -1e11) { //千亿
    return (n/1e8).toFixed(0)
  } else if (n >= 1e10 || n <= -1e10) { //百亿
    return (n/1e8).toFixed(1)
  } else if (n >= 1e9 || n <= -1e9) { //十亿
    return (n/1e8).toFixed(2)
  } else if (n >= 1e8 || n <= -1e8) { //亿
    return (n/1e8).toFixed(3)
  } else if (n >= 1e4 || n <= -1e4) { //万
    return (n/1e8).toFixed(4)
  } else {
    //不到1w的
    return n.toFixed(2)
  }
}

//将数字格式化成带单位的字符串，方便和东财对比
function financeFormat(n: number): string {
  if (n >= 1e11 || n <= -1e11) { //千亿
    return (n/1e8).toFixed(0) + '亿'
  } else if (n >= 1e10 || n <= -1e10) { //百亿
    return (n/1e8).toFixed(1) + '亿'
  } else if (n >= 1e9 || n <= -1e9) { //十亿
    return (n/1e8).toFixed(2) + '亿'
  } else if (n >= 1e8 || n <= -1e8) { //亿
    return (n/1e8).toFixed(3) + '亿'
  } else if (n >= 1e7 || n <= -1e7) { //千万
    return (n/1e4).toFixed(0) + '万'
  } else if (n >= 1e6 || n <= -1e6) { //百万
    return (n/1e4).toFixed(1) + '万'
  } else if (n >= 1e5 || n <= -1e5) { //十万
    return (n/1e4).toFixed(2) + '万'
  } else if (n >= 1e4 || n <= -1e4) { //万
    return (n/1e4).toFixed(3) + '万' 
  } else {
    return n.toFixed(0)
  }
}

const hash = (str: string, seed: number = 0) => {
  let h1 = 0xdeadbeef ^ seed,
    h2 = 0x41c6ce57 ^ seed;
  for (let i = 0, ch; i < str.length; i++) {
    ch = str.charCodeAt(i);
    h1 = Math.imul(h1 ^ ch, 2654435761);
    h2 = Math.imul(h2 ^ ch, 1597334677);
  }
  
  h1 = Math.imul(h1 ^ (h1 >>> 16), 2246822507) ^ Math.imul(h2 ^ (h2 >>> 13), 3266489909);
  h2 = Math.imul(h2 ^ (h2 >>> 16), 2246822507) ^ Math.imul(h1 ^ (h1 >>> 13), 3266489909);
  
  return 4294967296 * (2097151 & h2) + (h1 >>> 0);
}

//时间戳对齐到当天
function alignTs(ts: number): number {
  let day = 3600 * 1000 * 24
  return Math.floor(ts / day) * day
}

//产生财报日期
function genReportDates(years: number) {
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let dates: string[] = []
  for (let i = 0; i<years; i++) {
    if (i > 0)
      dates.push(`${year-i}-12-31`)
    if (i != 0 || month > 9)
      dates.push(`${year-i}-09-30`)
    if (i != 0 || month > 6)
      dates.push(`${year-i}-06-30`)
    if (i != 0 || month > 3)
      dates.push(`${year-i}-03-31`)
  }
  return dates
}

function genReportDatesUS(years: number, seasonOnly: boolean) {
  const seasonMap: string[][] = [
    ['Q1', 'Q1'],
    ['Q2', 'Q6'],
    ['Q3', 'Q9'],
    ['Q4', 'FY'],
  ]
  let idx = seasonOnly? 0: 1
  let date = new Date()
  let year = date.getFullYear()
  let month = date.getMonth() + 1
  let dates: string[] = []
  for (let i = 0; i<years; i++) {
    if (i > 0)
      dates.push(`${year-i}/${seasonMap[3][idx]}`)
    if (i != 0 || month > 9)
      dates.push(`${year-i}/${seasonMap[2][idx]}`)
    if (i != 0 || month > 6)
      dates.push(`${year-i}/${seasonMap[1][idx]}`)
    if (i != 0 || month > 3)
      dates.push(`${year-i}/${seasonMap[0][idx]}`)
  }
  return dates
}

function trans2SecurityId(code: string): string {
  let parts = code.split('.')
  let market = marketMap[parts[1]]
  return `${market}.${parts[0]}`
}

function genXueqiuSymbol(code: string): string {
  if (code.endsWith('.SZ') || code.endsWith('.ZF')) {
    return `SZ${code.split('.')[0]}`
  } else if (code.endsWith('.SH') || code.endsWith('.SF')) {
    return `SH${code.split('.')[0]}`
  } else if (code.endsWith('.BJ')) {
    return `BJ${code.split('.')[0]}`
  } else if (code.endsWith('.HK')) {
    return `${code.split('.')[0].padStart(5, '0')}`
  } else if (code.endsWith('.US') || code.endsWith('.O') || code.endsWith('.N') || code.endsWith('.AF')) {
    return `${code.substring(0, code.lastIndexOf('.'))}`
  } else {
    //a股,没后缀的形式
    let nr = parseInt(code)
    if (nr < 400000) {
      return `SZ${code.padStart(6, '0')}`
    } else {
      return `SH${code.padStart(6, '0')}`
    }
  }
}

function genMarketCodeStr(code: string): string {
  if (code.endsWith('.SZ')) {
    return `SZ${code.split('.')[0]}`
  } else if (code.endsWith('.SH')) {
    return `SH${code.split('.')[0]}`
  } else if (code.endsWith('.BJ')) {
    return `BJ${code.split('.')[0]}`
  } else {
    log('genMarketCodeStr,wrong code', code)
    return ''
  }
}

//开始结束时间都是指基于UTC当天的秒数，endTs可能小于startTs
function calcCacheTtl(startTs: number, endTs: number, minTtl: number): number {
  //周六6点后长缓存、周日
  let ts = Date.now()
  //先将当前时间换算到UTC一天中的秒数(相当于北京时间-8小时)
  ts = Math.floor(ts / 1000)
  ts = ts - Math.floor(ts / (3600 * 24)) * 3600 * 24

  let d = new Date()
  if (d.getDay() == 6 && d.getHours() > 5) {
    //周六盘后
    return 3600 * 24
  } else if (d.getDay() == 0) {
    //周日,到周一开盘前
    return startTs - ts + 3600 * 24
  }

  let ttl = 0
  //交易时间段内缓存时间为minTtl秒，非交易时间段缓存时间为到第二天盘前交易开始时间(最少minTtl秒)
  if (startTs < endTs) {
    if (ts >= startTs && ts < endTs) {
      return minTtl
    }
  } else {
    if (ts >= startTs || ts < endTs) {
      return minTtl
    }
  }

  ttl = startTs - ts
  if (ttl < 0) {
    ttl += 3600 * 24
  }

  if (ttl < minTtl) {
    ttl = minTtl
  }

  return ttl
}

//根据交易所对应的交易时间计算缓存的TTL
function calcTradeCacheTtl(code: string): number { 
  //先将当前时间换算到UTC一天中的秒数(相当于北京时间-8小时)
  let startTs = 0, endTs = 0
  if (code.endsWith('.HK')) {
    //变动开始时间(包括准备阶段)8:49
    startTs = 49 * 60
    //变动结束时间16:15
    endTs = (8 * 60 + 15) * 60
  } else if (code.endsWith('.O') || code.endsWith('.N') || code.endsWith('.AF') || code.endsWith('.US') || code.endsWith('.UO')) {
    //美股，夏令时：北京时间：21:30～次日4：00
    //美股，冬令时：北京时间：22：30~次日5：00
    //变动开始时间(包括准备阶段)21:29
    startTs = (13 * 60 + 29) * 60
    //变动结束时间4：03
    endTs = (20 * 60 + 3) * 60
  } else if (code.endsWith('.OF')) {
    //A股场外基金
    //变动开始时间(包括准备阶段)15:10
    startTs = (7 * 60 + 10) * 60
    //变动结束时间23:59
    endTs = (15 * 60 + 59) * 60
  } else if (code.endsWith('.DC')) {
    //数字货币
    //变动开始时间(包括准备阶段)15:10
    startTs = 0
    //变动结束时间23:59
    endTs = (15 * 60 + 59) * 60
  } else {
    //A股
    //变动开始时间(包括准备阶段)8:49
    startTs = 49 * 60
    //变动结束时间15:15
    endTs = (7 * 60 + 15) * 60
  }

  let ttl = calcCacheTtl(startTs, endTs, 180)
  //log.error('calcTradeCacheTtl', code, new Date().toLocaleTimeString(), ttl)
  return ttl
}


//财报都是盘后计算
function calcFinanceCacheTtl(code: string): number {
  //先将当前时间换算到UTC一天中的秒数(相当于北京时间-8小时)
  let startTs = 0, endTs = 0
  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ') || code.endsWith('.ZF') || code.endsWith('.SF')
    || code.endsWith('.ZI') || code.endsWith('.SI') || code.endsWith('.HI')) {
    //A股
    //变动开始时间(包括准备阶段)15:10
    startTs = (7 * 60 + 10) * 60
    //变动结束时间9:00
    endTs = 60 * 60
  } else if (code.endsWith('.HK')) {
    //变动开始时间(包括准备阶段)16:10
    startTs = (8 * 60 + 10) * 60
    //变动结束时间9:00
    endTs = 60 * 60
  } else if (code.endsWith('.US') || code.endsWith('.O') || code.endsWith('.N') || code.endsWith('.AF')) {
    //美股，冬令时：北京时间：22：30~次日5：00
    //变动开始时间(包括准备阶段)5:10
    startTs = (21 * 60 + 10) * 60
    //变动结束时间22:30
    endTs = (14 * 60 + 30) * 60
  } else if (code.endsWith('.OF')) {
    //A股场外基金
    //变动开始时间(包括准备阶段)15:10
    startTs = (7 * 60 + 10) * 60
    //变动结束时间23:59
    endTs = (15 * 60 + 59) * 60
  } else {
    throw Error(`calcFinanceCacheTtl unknown code:${code}`)
  }

  let ttl = calcCacheTtl(startTs, endTs, 1800)
  //log.error('calcFinanceCacheTtl', code, new Date().toLocaleTimeString(), ttl)
  return ttl
}

function convertResponse(response: Response, accept: string) {
  let contentType = response.headers.get("Content-Type")?.split(';')[0]
  switch (contentType) {
    case 'application/json':
      return response.json()
    case 'text/plain':
      if (accept == 'json') {
        return response.json()
      } else {
        return response.text()
      }
    case 'text/javascript':
      return response.text()
    default:
      log('convertResponse,Content-Type', contentType)
      return response.text()
  }
}

function parseResponseData(data: any, accept: string) {
  if (typeof data == 'string') {
    let begin = 0
    let end = data.length
    if (data.startsWith('jQuery')) {
      begin = data.indexOf('(')
      end = data.lastIndexOf(')')
      return JSON.parse(data.substring(begin + 1, end))
    } else if (accept == 'json') {
      //js或者 json 数据处理
      begin = data.indexOf('=')
      if (begin > 0) {
        return eval(`(${data.substring(begin+1, data.length-1)})`)
      } else {
        return JSON.parse(data)
      }
    }
  }
  
  return data
}

function fetchRequest(request: any, success: (data: any, cacheMiss: boolean)=>void) {
  let cacheKey = request.cacheKey
  if (cacheKey) {
    if (cache[cacheKey] != undefined) {
      success(cache[cacheKey], false)
      return
    } else if (requestWaitMap[cacheKey]) {
      //等待结果
      requestWaitMap[cacheKey].push(success)
      return
    } else {
      requestWaitMap[cacheKey] = [success]
    }
  }

  const callback = (data: any) =>{
    if (cacheKey) {
      cache[cacheKey] = data
      for (let i=0; i< requestWaitMap[cacheKey].length;i++) {
        if (i == 0) {
          requestWaitMap[cacheKey][i](cache[cacheKey], true)
        } else {
          requestWaitMap[cacheKey][i](cache[cacheKey], false)
        }
      }
      delete requestWaitMap[cacheKey]
    } else {
      success(data, true)
    }
  }

  let url = ''
  let accept = request.accept ? request.accept: ''
  let config: any = {headers:{}}
  if (typeof request == 'string') {
    url = request
    config.method = 'GET'
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  } else if (request.proxy) {
    //后端代理
    url = `${server}/proxy`
    config.method = 'POST'
    config.body = JSON.stringify(request)
    config.headers['Content-Type'] = 'application/json'
  } else if (request.params) {
    //get
    url = request.url + '?' + queryString(request.params)
    config.method = 'GET'
    if (request.headers) {
      config.headers = request.headers
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  } else if (request.data) {
    //post
    url = request.url
    config.method = 'POST'
    config.body = JSON.stringify(request.data)
    if (request.headers) {
      config.headers = request.headers
    }
    config.headers['Content-Type'] = 'application/json'
  } else if (request.url) {
    //get
    url = request.url
    config.method = 'GET'
    if (request.headers) {
      config.headers = request.headers
    }
    config.headers['Content-Type'] = 'application/x-www-form-urlencoded'
  } else {
    //直接就是结果数据
    callback(request)
    return
  }

  fetch(url, config)
  .then(resp=> convertResponse(resp, accept))
  .then((resp: any) => {
    if (url.startsWith(server)) {
      //访问服务端
      switch (resp.code) {
        case 200:
          callback(parseResponseData(resp.data, accept))
          return
        case 401:
          window.location.href = `login.html?url=${encodeURIComponent(window.location.href)}`
        return
        case 403:
          alert('<a href="account.html" target="_blank">权限不够，点击去购买</a>')
          return
        case 402:
          alert('<a href="recharge.html" target="_blank">账户余额不足，点击去充值</a>')
          return
        default:
          log('fetchServer Failed', resp.code, resp.msg)
          alert(resp.code, resp.msg)
          return
      }
    } else {
      callback(parseResponseData(resp, accept))
    }
  })
  /*.catch(function (error: Error) {
    log('fetchRequest,fetch Failed', error)
  })*/
}


function parseQuery(queryString: string) {
  let query: Record<string, any> = {}
  let pairs = (queryString[0] === '?' ? queryString.substring(1) : queryString).split('&')
  for (let i = 0; i < pairs.length; i++) {
    let pair = pairs[i].split('=')
    query[decodeURIComponent(pair[0])] = decodeURIComponent(pair[1] || '')
  }
  return query
}

function alert(message: string, type: string = 'warning') {
  let html = `<div class="alert alert-${type} alert-dismissible" role="alert">
     <div class="text-center">${message}</div>
     <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
  </div>`

  //@ts-ignore
  document.getElementById('alert').innerHTML = html
}

function isNumber(val: any): boolean {
  let regPos = /^\d+(\.\d+)?$/; //非负浮点数
  let regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; //负浮点数
  if (regPos.test(val) || regNeg.test(val)) {
    return true;
  } else {
    return false;
  }
}

//查找val在arr数组中的位置(从0开始)，数组已经有序,如果不存在的元素返回的是应该插入的位置
function findInsertIndex(arr: number[], val: number) {
  let low = 0, high = arr.length
  while (low < high) {
    let mid = (low + high) >>> 1
    if (arr[mid] < val) {
      low = mid + 1
    } else {
      high = mid
    }
  }
  return low
}

//在一个已经有序的数组中插入元素，插入后仍然有序
function insert(arr: number[], val: number) {
  arr.splice(findInsertIndex(arr, val), 0, val)
  return arr
}

//将20211001转换成 2021-10-01
function date2String(date: number): string {
  let str = date.toString()
  return `${str.substring(0, 4)}-${str.substring(4, 6)}-${str.substring(6, 8)}`
}

//根据传入的时间戳(毫秒或者秒)，返回时间格式：2021-08-01
function toDateString(ts: number|Date): string {
  let d: Date
  if (ts instanceof Date) {
    d = ts
  } else {
    if (ts < 4300000000) {
      //转换成毫秒
      ts *= 1000
    }
    d = new Date(ts)
  }
  
  return `${d.getFullYear()}-${zeroPad(d.getMonth() + 1, 2)}-${zeroPad(d.getDate(), 2)}`
}

//根据传入的时间戳(毫秒或者秒)，返回时间格式(北京时间)：2021-08-01 14:00:00
function toTimeString(ts: number): string {
  if (ts < 4300000000) {
    //转换成毫秒
    ts *= 1000
  }
  let d = new Date(ts)
  return `${d.getFullYear()}-${zeroPad(d.getMonth() + 1, 2)}-${zeroPad(d.getDate(), 2)} ${zeroPad(d.getHours(), 2)}:${zeroPad(d.getMinutes(), 2)}:${zeroPad(d.getSeconds(), 2)}`
}

//日期格式转换成时间戳(如2021-01-02/20210102)
function toTimestamp(str: string): number {
  str = str.replace(/[-/]/g, '')
  let d = new Date(parseInt(str.substring(0, 4)), parseInt(str.substring(4, 6)) - 1, parseInt(str.substring(6, 8)))
  return d.getTime() + 8 * 3600 * 1000
}

//是否是银行股
function isCodeBank(code: string) {
  const bankCodes = [
    '600036.SH', //招商银行
    '601398.SH', //工商银行
    '601288.SH', //农业银行
    '601939.SH', //建设银行
    '601328.SH', //交通银行
    '601988.SH', //中国银行
    '601658.SH', //邮储银行
  ]
  return bankCodes.includes(code)
}

function isCodeFund(code: string) {
  return code.endsWith('F')
}

function isCodeCompany(code: string) {
  return code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ') || code.endsWith('.HK') || code.endsWith('.US') || code.endsWith('.N') || code.endsWith('.O')
}

function isAstockCompany(code: string): boolean {
  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    return true
  }
  return false
}

function isHkCompany(code: string): boolean {
  if (code.endsWith('.HK')) {
    return true
  }
  return false
}

function isUsCompany(code: string): boolean {
  if (code.endsWith('.US') || code.endsWith('.N') || code.endsWith('.O')) {
    return true
  }
  return false
}

function isCodeIndex(code: string): boolean {
  if (code.endsWith('.ZI') || code.endsWith('.SI') || code.endsWith('.HI')) {
    return true
  }
  return false
}

function genFullCode(code: string): string {
  code = code.toUpperCase()
  if (code.indexOf('.') > 0) {
    if (code.endsWith('.HK')) {
      return code.padStart(8, '0')
    } else {
      return code
    }
  }
  //没有后缀的情况
  if (code.length < 6) {
    return code + '.HK'
  } else if (code.startsWith('0') || code.startsWith('1') || code.startsWith('3')) {
    return code + '.SZ'
  } else if (code.startsWith('8')) {
    return code + '.BJ'
  } else {
    return code + '.SH'
  }
}

//根据要计算财报的信息的日期获取之前n个季度的财报日期
function generateFinanceDates(date: number, num: number): string[] {
  let year = Math.floor(date / 10000)
  let month = Math.floor(date / 100) % 100
  let dates: string[] = []
  for (let i = 0; i < num; i++) {
    if (month < 4) {
      year--
      dates.push(`${year}-12-31`)
    } else if (month < 7) {
      dates.push(`${year}-03-31`)
    } else if (month < 10) {
      dates.push(`${year}-06-30`)
    } else {
      dates.push(`${year}-09-30`)
    }
    month = month > 3 ? month - 3 : 12
  }
  return dates
}

/**
 * 在k线序列(每个元素第一个字段为时间戳)中找到小于等于ts的索引
 * kline 数据序列
 * ts 时间戳(ms)
 */
function findTsIndex(kline: number[][], ts: number) {
  let low = 0, high = kline.length
  while (low < high) {
    let mid = (low + high) >>> 1
    if (kline[mid][0] < ts) {
      low = mid + 1
    } else {
      high = mid
    }
  }
  if (low < kline.length && kline[low][0] == ts) {
    return low
  } else {
    return low - 1
  }
}

//找到刚好等于小于的索引
function findIndex(arr: number[], val: number) {
  let low = 0, high = arr.length
  while (low < high) {
    let mid = (low + high) >>> 1
    if (arr[mid] < val) {
      low = mid + 1
    } else {
      high = mid
    }
  }
  if (low < arr.length && arr[low] == val) {
    return low
  } else {
    return low - 1
  }
}

function alignKlines(kline1: number[][], kline2: number[][], startTs: number, endTs: number) {
  let endIdx1 = findTsIndex(kline1, endTs)
  let endIdx2 = findTsIndex(kline2, endTs)
  let idx1 = findTsIndex(kline1, startTs)
  if (idx1 < 0) {
    idx1 = 0
    startTs = kline1[0][0]
  }
  let idx2 = findTsIndex(kline2, startTs)

  if (idx2 < 0) {
    idx2 = 0
    startTs = kline2[0][0]
    idx1 = findTsIndex(kline1, startTs)
  }

  if (idx1 < 0 || idx2 < 0) {
    throw 'startTs can not found in kline'
  }
  let ak1 = []
  let ak2 = []
  let ts = 0
  while (idx1 <= endIdx1 || idx2 <= endIdx2) {
    if (idx1 <= endIdx1 && idx2 <= endIdx2) {
      if (kline1[idx1][0] < kline2[idx2][0]) {
        ts = kline1[idx1][0]
        ak1.push([ts, kline1[idx1][1]])
        let k = idx2 - 1 < 0 ? 0: idx2 - 1
        ak2.push([ts, kline2[k][1]])
        idx1++
      } else if (kline1[idx1][0] > kline2[idx2][0]) {
        ts = kline2[idx2][0]
        let k = idx1 - 1 < 0 ? 0: idx1 - 1
        ak1.push([ts, kline1[k][1]])
        ak2.push([ts, kline2[idx2][1]])
        idx2++
      } else {
        ts = kline2[idx2][0]
        ak1.push([ts, kline1[idx1][1]])
        ak2.push([ts, kline2[idx2][1]])
        idx1++
        idx2++
      }
    } else if (idx1 <= endIdx1) {
      ts = kline1[idx1][0]
      ak1.push([ts, kline1[idx1][1]])
      ak2.push([ts, kline2[idx2 - 1][1]])
      idx1++
    } else if (idx2 <= endIdx2) {
      ts = kline2[idx2][0]
      ak1.push([ts, kline1[idx1 - 1][1]])
      ak2.push([ts, kline2[idx2][1]])
      idx2++
    }
  }
  return [ak1, ak2]
}

function average(kline: number[][]) {
  let sum = 0.0
  for (let i = 0; i < kline.length; i++) {
    sum += kline[i][1]
  }
  // log('average:', JSON.stringify(kline), sum, kline.length, sum / kline.length)
  return sum / kline.length
}
/**
 * 协方差
 * @param kline1 序列1
 * @param kline2 序列2
 */
function covariance(kline1: number[][], kline2: number[][]) {
  // cov(A,B) = E(A*B) - avgA*avgB
  if (kline1.length != kline2.length) {
    throw `kline1.length != kline12length,${kline1.length},${kline2.length}`
  }
  let avg1 = average(kline1)
  let avg2 = average(kline2)
  let sum = 0
  for (let i = 0; i < kline1.length; i++) {
    sum += (kline1[i][1] - avg1) * (kline2[i][1] - avg2)
  }
  return sum / kline1.length
}

/**
 * 方差
 * @param kline 序列
 */
function variance(kline: number[][]) {
  let epn = average(kline)
  let sum = 0
  for (let i = 0; i < kline.length; i++) {
    sum += (kline[i][1] - epn) * (kline[i][1] - epn)
  }
  return sum / kline.length
}
/**
 * 标准差
 * @param kline 序列
 */
function standardDeviation(kline: number[][]) {
  return Math.sqrt(variance(kline))
}
/**
 * 相关系数
 * @param kline1
 * @param kline2
 */
function correlationCoefficient(kline1: number[][], kline2: number[][]) {
  return covariance(kline1, kline2) / (standardDeviation(kline1) * standardDeviation(kline2))
}

//删除type类型kline
function deleteKline(type: string) {
  for (let i=0;i < klineCodes.length;i++) {
    let parts = klineCodes[i].split('-')
    if (type == '' && parts.length == 1) {
      klineCodes.splice(i)
    } else if (type != '' && type == parts[1]) {
      klineCodes.splice(i)
    }
  }
}

// 生成相关性系数表
function genrateCorrelationCoefficientTable() {
  if (selectedCodes.length < 2) {
    return
  }

  let rows = genrateCorrelationCoefficientRows()
  let html = `<thead class="table-info">
        <tr>
            <th>相关系数</th>`

  for (let i = 0; i < selectedCodes.length; i++) {
    html += `<th class="sortable">${codeNameMap[selectedCodes[i]]}(${selectedCodes[i]})</th>`
  }

  html += `</tr>
    </thead>
    <tbody>`

  for (let i = 0; i < rows.length; i++) {
    html += `<tr><td>${codeNameMap[selectedCodes[i]]}(${selectedCodes[i]})</td>`
    for (let j = 0; j < rows[i].length; j++) {
      let color = ''
      if (rows[i][j] > 0) {
        color = 'text-danger'
      } else if (rows[i][j] < 0) {
        color = 'text-success'
      }
      html += `<td class="${color}">${rows[i][j].toFixed(3)}</td>`
    }
    html += `</tr>`
  }
  html += `</tbody>`
  bsTable('correlation', {data: html})
}

// 生成相关性系数行s
function genrateCorrelationCoefficientRows(): number[][] {
  let startTs = new Date($('#dateRange-start').value).getTime()
  let endTs = new Date($('#dateRange-end').value).getTime()
  let rows: number[][] = []
  // 初始化矩阵
  for (let i = 0; i < selectedCodes.length; i++) {
    rows[i] = []
    for (let j = 0; j < selectedCodes.length; j++) {
      rows[i][j] = 1.0
    }
  }
  // 开始计算
  for (let i = 0; i < selectedCodes.length - 1; i++) {
    for (let j = i + 1; j < selectedCodes.length; j++) {
      let aks = alignKlines(cache[selectedCodes[i]], cache[selectedCodes[j]], startTs, endTs)
      let correlation = correlationCoefficient(aks[0], aks[1])
      rows[i][j] = correlation
      rows[j][i] = correlation
    }
  }

  return rows
}

function genColorTbody(rows: any, colorColum: (idx: number) => boolean): string {
  let html = '<tbody>'
  for (let row of rows) {
    html += '<tr>'
    for (let i=0; i < row.length;i++) {
      if (colorColum(i)) {
        html += `<td class="${cellColor(row[i])}">${row[i]}</td>`
      } else {
        html += `<td>${row[i]}</td>`
      }
    }
    html += '</tr>'
  }
  html += '</tbody>'
  
  return html
}

function genrateRegressTable() {
  let years = 10
  let yearsTs = getYearsTs(years)
  let rows = genrateRegressRows(yearsTs)
  let html = `<thead class="table-info">
        <tr>
            <th>年度回测</th>
            <th>名称简称</th>`

  for (let i = 0; i < years; i++) {
    let year = new Date(yearsTs[i]).getFullYear()
    html += `<th>${year}年收益/回撤(%)</th>`
  }

  html += `</tr>
    </thead>${genColorTbody(rows, (idx)=> idx > 1)}`
  bsTable('regress', {data: html})
}

function getYearsTs(years: number) {
  let yearsTs = []
  // @ts-ignore
  let date = new Date()
  for (let i = 0; i < years; i++) {
    yearsTs.push(new Date(date.getFullYear()-i, date.getMonth(), date.getDate()).getTime())
  }
  return yearsTs
}

function genrateRegressRows(yearsTs: number[]) {
  let rows = []
  for (let code of selectedCodes) {
    // 代码，名称，最近n年最大回撤....
    let row = [code, codeNameMap[code]]
    let maxDrop = 0.0
    let minPrice = 9999999.9
    let yearEndPrice = cache[code][cache[code].length - 1][1]
    let j = 0
    for (let i = cache[code].length - 1; i >= 0; i--) {
      let ts = yearsTs[j]
      if (cache[code][i][0] < ts) {
        let profit = yearEndPrice * 100.0 / cache[code][i][1] - 100.0
        maxDrop = maxDrop * 100.0

        row.push(`${profit.toFixed(2)}/${maxDrop.toFixed(2)}`)
        maxDrop = 0.0
        yearEndPrice = minPrice = cache[code][i][1]
        j++
      }

      if (j == yearsTs.length) {
        break
      }

      let value = cache[code][i][1]
      if (value < minPrice) {
        minPrice = value
      }

      if ((value - minPrice) / value > maxDrop) {
        maxDrop = (value - minPrice) / value
      }
    }

    /*for (let k = 2; k < row.length; k++) {
      row[k] = row[k].toFixed(2)
    }*/

    // 有些股票上市时间不够长，补齐
    for (let k = row.length; k < 2 + yearsTs.length; k++) {
      row.push('-')
    }

    rows.push(row)
  }

  return rows
}

function genratePositionTable(selectedCodes: string[]) {
  let codes: string[] = []
  for (let code of selectedCodes) {
    if (isCodeFund(code)) {
      codes.push(code)
    }
  }

  if (codes.length == 0) {
    log('genratePositionTable no valid codes')
    return
  }

  let colorPos = 4
  let html = `<thead>
        <tr>
            <th>基金名称</th>
            <th>更新日期</th>
            <th>重仓占比</th>`

  for (let i = 0; i < 10; i++) {
    html += `<th>重仓${i + 1}</th>`
  }

  html += `</tr>
    </thead>
    <tbody>`

  // 先预处理多行时是否有相同的持仓
  if (codes.length > 1) {
    let base = cache[`${codes[0]}-fp`][0].data
    for (let i = 1; i < codes.length; i++) {
      let compare = cache[`${codes[i]}-fp`][0].data
      for (let j = 0; j < compare.length; j++) {
        for (let k = 0; k < base.length; k++) {
          if (compare[j][0] == base[k][0]) {
            // 相同的持仓代码
            base[k][colorPos] = 1
            compare[j][colorPos] = 1
          }
        }
      }
    }
  }

  for (let code of codes) {
    let position = cache[`${code}-fp`][0]
    let totalRatio = 0
    html += `<tr><td style='color:black'>${codeNameMap[code]}</td>`
    html += `<td style='color:black'>${position.updateDate}</td>`
    // 先累加重仓占比
    let data = position.data
    for (let j = 0; j < data.length; j++) {
      totalRatio += parseFloat(data[j][2])
    }
    html +=
      `<td style='color:black'>${totalRatio.toFixed(2)}%</td>`
    for (let j = 0; j < data.length; j++) {
      totalRatio += data[j][2]
      let color = 'black'
      if (data[j].length > colorPos) {
        color = 'blue'
      }
      html +=
        `<td style='color:${color}'>${data[j][1]}-${data[j][0]}(${data[j][2]}%)</td>`
    }
    html += `</tr>`
  }
  html += `</tbody></table>`
  $('#position').innerHTML = html
}

function formatKlineValue(value: number) {
  if (value > 10) {
    return Math.round(100*value)/100
  } else if (value > 1.0) {
    return Math.round(1000*value)/1000
  } else {
    return Math.round(10000*value)/10000
  }
}

function rerenderMyChart() {
  let startTs = 0
  let endTs = Date.now()
  if ($('#dateRange-start')) {
    startTs = new Date($('#dateRange-start').value).getTime()
  }
  if ($('#dateRange-end')) {
    endTs = new Date($('#dateRange-end').value).getTime()
  }
  renderLineChart(startTs, endTs)
}

function renderLineChart(startTs: number, endTs: number) {
  if (klineCodes.length < 1) {
    return
  }
  klineCodes = klineCodes.filter((v, idx)=> klineCodes.indexOf(v) == idx)
  if (klineCodes.length == 1 && $('#candlestick')?.checked && ['SZ', 'SH', 'SF', 'ZF', 'BJ', 'HK', 'US'].includes(klineCodes[0].split('.')[1])) {
    rerenderCandlestick(klineCodes[0], startTs, endTs)
  } else {
    renderKline(klineCodes, startTs, endTs)
  }
}

function renderKline(codes: string[], startTs: number, endTs: number) {
  let series: any = []
  let ts = 0
  let value = 0
  let codeNum = codes.length
  let xAxis: number[] = []
  for (let code of codes) {
    if (cache[code].length < 1) {
      log('renderLineChart cache[code].length < 1', code)
      continue
    }
    let data = []
    let baseStart = -1
    let baseEnd = -1
    let arr: number[] = []
    let begin = 0
    let end = cache[code].length
    let isFund = code.endsWith('.OF')  ? true: false
    //先找起止点
    for (let j = 0; j < cache[code].length; j++) {
      ts = cache[code][j][0]
      if (ts <= startTs) {
        begin = j
        arr[0] = cache[code][j][1]
        continue
      }

      if (ts > endTs) {
        end = j
        break
      }
      arr.push(cache[code][j][1])
    }

    baseEnd = cache[code][end-1][1]
    arr.sort((a, b) => a - b)
    for (let j = begin; j < end; j++) {
      ts = cache[code][j][0]
      value = cache[code][j][1]
      if (baseStart < 0) {
        baseStart = value
      }

      // 考虑基金分红等情况
      if (isFund && cache[code][j].length > 2) {
        value = baseStart + cache[code][j][2] - baseStart
      }

      //相比昨日
      let change = j > 0 ? value/cache[code][j-1][1] -1: 0
      // 和基数的比率
      let ratio = value / baseStart //截止比率
      let ratio2 = baseEnd/value //结束比率
      let quantile = 0
      let idx = findInsertIndex(arr, value)
      if (idx == arr.length - 1) {
        quantile = 100
      } else {
        quantile = idx * 100 / arr.length
      }
      if (codeNum > 1) {
        // 把起始数据作为标准1进行比较
        data.push([ts, ratio, value, ratio2, change, quantile])
      } else {
        data.push([ts, value, ratio, ratio2, change, quantile])
      }
      xAxis.push(ts)
    }
    //yAxisIndex: 1,
    series.push({
      name: `${codeNameMap[code]}(${code})`,
      type: 'line',
      showSymbol: false,
      emphasis: {
        scale: false,
      },
      data: data,
      endLabel: {
        show: true,
        color: 'white',
        padding: 4,
                fontWeight: 'bold',
        backgroundColor: 'inherit',
        formatter: function (param: any) {
          let ratio
          if (codeNum > 1) {
            ratio = param.value[1]
          } else {
            ratio = param.value[2]
          }
          return codeNameMap[code] + ': ' + (100*ratio-100).toFixed(2) + '%'
        }
      }
    })
  }

  let yAxis: any = [{
    type: 'value',
    scale: true,
    position: 'right',
    //boundaryGap: [0.1, 0.1],
    axisLabel: {
      formatter: '{value}'
    },
    splitLine: {
      show: false
    }
  }]

  xAxis = xAxis.filter((v: number,i: number)=> xAxis.indexOf(v) == i).sort()
  addMarkPoints(series[0], xAxis)
  // @ts-ignore
  myChartSetOption(series, xAxis, yAxis)
}


function myChartSetOption(series: any, xAxis: any,  yAxis: any) {
  let id = 'kline'
  //let html = `<div id="${id}" style="min-height: 600px; min-width: 300px;"></div>`
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)

  // @ts-ignore
  myChart.setOption({
    title: {
      text: ''
    },
    color: echartsColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params: any) {
        params.sort((a: any,b: any)=> b.value[1] - a.value[1])
        let str = toTimeString(params[0].value[0])
        for (let param of params) { // get data sorted
          let name = param.seriesName
          let value, ratio
          if (series.length > 1) {
            ratio = (100*param.value[1]-100).toFixed(2)
            value = param.value[2]
          } else {
            ratio = (100*param.value[2]-100).toFixed(2)
            value = param.value[1]
          }
          let ratio2 = (100*param.value[3]-100).toFixed(2)
          let change = (100*param.value[4]).toFixed(2)
          let quantile = param.value[5].toFixed(2)
          str += `</br>${param.marker}${name}: ${formatKlineValue(value)}/当天:${change}%/比开始:${ratio}%/到结束:${ratio2}%/分位:${quantile}%`
        }

        //带上markLines
        str += markPoints2Str(params[0].axisValue, xAxis)
        return str
      }
    },
    legend: {
      middle: 10
    },
    xAxis: {
      type: 'time',
      splitLine: {
        show: false
      }
    },
    yAxis: yAxis,
    series: series
  })
}

//重新渲染蜡烛图
function rerenderCandlestick(code: string, startTs: number, endTs: number) {
  const upColor = '#ec0000'
  const downColor = '#00da3c'
  let chartDom: any = document.getElementById('kline')
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)
  let data = splitCandlestickData(cache[code], startTs, endTs)
  let series: any = [
    {
      name: 'K线图',
      type: 'candlestick',
      data: data.values,
      itemStyle: {
        color: upColor,
        color0: downColor,
        borderColor: 0,
        borderColor0: 0
      }
    },
    {
      name: 'Volume',
      type: 'bar',
      xAxisIndex: 1,
      yAxisIndex: 1,
      data: data.volumes
    }
  ]

  let legend: string[] = ['K线图']
  for (let ma of [5, 10, 20, 30, 60]) {
    let name = 'MA' + ma
    legend.push(name)
    series.push({
      name: name,
      type: 'line',
      showSymbol: false,
      data: calculateMA(data.values, ma),
      smooth: true,
      lineStyle: {
        opacity: 0.5
      }
    })
  }
  let option = {
    animation: false,
    legend: {
      middle: 10,
      data: legend
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      backgroundColor: 'rgba(255, 255, 255, 0.8)',
      extraCssText: 'width: 170px',
      position: function (pos: number[], params: any, el: any, elRect: any, size: any) {
        const obj: any = {
          top: 10
        }
        obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 4)]] = 30
        return obj
      },
      formatter: function (params: any) {
        let str = `${params[0].axisValue}`
        for (let i = 0; i< params.length;i++) {
          if (i == 0) {
            let volume = params[i].value[5]
            if (volume > 1e8) {
              volume = (volume/1e8).toFixed(1) + '亿'
            } else if (volume > 1e4) {
              volume = (volume/1e4).toFixed(0) + '万'
            }

            let changeColor = params[i].value[8] > 0? 'danger': 'success'
            let compareStartColor = params[i].value[6] > 0? 'danger': 'success'
            let compareEndColor = params[i].value[7] > 0? 'danger': 'success'
            str += `<br>${params[i].marker}${params[i].seriesName}
            <br>开盘: ${params[i].value[1]}
            <br>收盘: ${params[i].value[2]}
            <br>最低: ${params[i].value[3]}
            <br>最高: ${params[i].value[4]}
            <br>当天: <span class="text-${changeColor}">${params[i].value[8]}%</span>
            <br>交易量: ${volume}
            <br>比开始: <span class="text-${compareStartColor}">${params[i].value[6]}%</span>
            <br>到结束: <span class="text-${compareEndColor}">${params[i].value[7]}%</span>
            <br>分位值: ${params[i].value[9]}%`
          } else {
            let valueColor = params[0].value[2] > params[i].value ? 'danger': 'success'
            let value = typeof params[i].value == 'number' ? params[i].value.toFixed(3): params[i].value
            str += `<br>${params[i].marker}${params[i].seriesName}: <span class="text-${valueColor}">${value}</span>`
          }
        }

        //带上markLines
        str += markPoints2Str(params[0].axisValue, data.categoryData)
        return str
      }
    },
    axisPointer: {
      link: { xAxisIndex: 'all' },
      label: {
        backgroundColor: '#777'
      }
    },
    visualMap: {
      show: false,
      seriesIndex: 1,
      dimension: 2,
      pieces: [
        {
          value: 1,
          color: upColor
        },
        {
          value: -1,
          color: downColor
        }
      ]
    },
    grid: [
      {
        left: '10%',
        right: '8%',
        height: '50%'
      },
      {
        left: '10%',
        right: '8%',
        bottom: '20%',
          height: '15%'
      }
    ],
    xAxis: [
      {
        type: 'category',
        data: data.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        splitLine: { show: false },
        min: 'dataMin',
        max: 'dataMax',
        axisPointer: {
          z: 100
        }
      },
      {
        type: 'category',
        gridIndex: 1,
        data: data.categoryData,
        scale: true,
        boundaryGap: false,
        axisLine: { onZero: false },
        axisTick: { show: false },
        splitLine: { show: false },
        axisLabel: { show: false },
        min: 'dataMin',
        max: 'dataMax'
      }
    ],
    yAxis: [
      {
        scale: true,
        position: 'right',
        splitArea: {
          show: true
        }
      },
      {
        scale: true,
        gridIndex: 1,
        splitNumber: 2,
        axisLabel: { show: false },
        axisLine: { show: false },
        axisTick: { show: false },
        splitLine: { show: false }
      }
    ],
    series: series
  }

  addMarkPoints(option.series[0], data.categoryData)
  myChart.setOption(option)
}

function addMarkPoints(seriesI: any, xAxis: any) {
  if (!markPoints.length) {
    return
  }

  markPoints = markPoints.filter((v1: any, i: number)=> i == markPoints.findIndex((v2: any)=> v1.x==v2.x && v1.name==v2.name))
  markPoints.sort((a: any,b: any)=> a.x - b.x)
  let data = []
  let preIs: number[] = Array(6).fill(-10)
  let offset = 0
  let maxOffset = 0
  for (let i=0;i<markPoints.length;i++) {
    let x = typeof xAxis[0]=='string'? toDateString(markPoints[i].x): markPoints[i].x
    if (x < xAxis[0]) {
      //不在数据范围
      continue
    }
    offset = 0
    maxOffset = 0
    for (let i=0;i< xAxis.length;i++) {
      if (x <= xAxis[i]) {
        x = xAxis[i]
        for (let j=0;j< preIs.length;j++) {
          offset = (i-preIs[j])/xAxis.length
          if (offset > 0.1) {
            offset = j
            break
          } else if (offset > maxOffset) {
            maxOffset = offset
            offset = j
          }
        }
        preIs[offset] = i
        break
      }
    }
    
    data.push(
      {
        name: markPoints[i].name,
        coord: [x, 'max'],
        itemStyle: {
          color: markPoints[i].color
        },
        label: {
          color: markPoints[i].color,
          offset: [0, -15 - 15*offset]
        }
      }
    )
  }
  seriesI.markPoint = {
    symbol: "circle",
    symbolSize: 5,
    label: {
      formatter: '{b}',
      
    },
    data: data
}
}

function markPoints2Str(axisValue: string|number, xAxis: any) {
  let str = ''
  for (let markPoint of markPoints) {
    let x = typeof xAxis[0]=='string'? toDateString(markPoint.x): markPoint.x
    if (x <= xAxis[0]) {
      //不在数据范围
      continue
    }
    for (let i=0;i< xAxis.length;i++) {
      if (x <= xAxis[i]) {
        x = xAxis[i]
        break
      }
    }
    if (x == axisValue) {
      str += `<br><span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:${markPoint.color};"></span>${markPoint.name}`
    }
  }
  return str
}

function splitCandlestickData(rawData: any[][], startTs: number, endTs: number) {
  let categoryData = []
  let values = []
  let volumes = []
  let baseStart = -1
  let baseEnd = -1
  let arr: number[] = []
  let begin = 0
  let end = rawData.length
  //先找起止点
  for (let j = 0; j < rawData.length; j++) {
    let ts = rawData[j][0]
    if (ts <= startTs) {
      begin = j
      arr[0] = rawData[j][1]
      continue
    }

    if (ts > endTs) {
      end = j
      break
    }
    arr.push(rawData[j][1])
  }

  baseEnd = rawData[end-1][1]
  arr.sort((a, b) => a - b)
  for (let i = begin; i < end; i++) {
    let value = rawData[i][1]
    if (baseStart < 0) {
      baseStart = value
    }
    //相比昨日
    let change = i > 0 ? Math.round(10000*value/rawData[i-1][1]-10000)/100: 0
    // 和基数的比较
    let ratio = Math.round(10000*value / baseStart-10000)/100 //截止比率
    let ratio2 = Math.round(10000*baseEnd/value-10000)/100 //结束比率
    let quantile = 0
    let idx = findInsertIndex(arr, value)
    if (idx == arr.length - 1) {
      quantile = 100
    } else {
      quantile = Math.round(idx * 10000 / arr.length)/100
    }
    categoryData.push(toDateString(rawData[i][0]))
    //转成：开盘、收盘、最低、最高、交易量、和开始比、结束比、分位值
    values.push([rawData[i][2], rawData[i][1], rawData[i][3], rawData[i][4], rawData[i][5], ratio, ratio2,change, quantile])
    //收盘是否大于昨天收盘
    volumes.push([i, rawData[i][5], i > 0 && rawData[i][1] > rawData[i-1][1] ? 1 : -1])
  }

  return {
    categoryData: categoryData,
    values: values,
    volumes: volumes
  }
}

//num个线在index1
function renderMultiChart(codes: string[], num: number) {
  let startTs = new Date($('#dateRange-start').value).getTime()
  let endTs = new Date($('#dateRange-end').value).getTime()
  let ymin: number[] = [999999, 999999]
  let ymax: number[] = [-999999, -999999]
  let series = []
  let ts = 0
  let value = 0
  for (let code of codes) {
    ts = cache[code][0][0]
    if (ts > startTs) {
      startTs = ts
    }
  }

  for (let code of codes) {
    let gridIndex: number = codes.length - series.length > num ? 0 : 1
    let data = []
    for (let j = 0; j < cache[code].length; j++) {
      ts = cache[code][j][0]
      if (ts < startTs) {
        continue
      }

      if (ts > endTs) {
        break
      }

      value = cache[code][j][1]
      if (value < ymin[gridIndex]) {
        ymin[gridIndex] = value
      }

      if (value > ymax[gridIndex]) {
        ymax[gridIndex] = value
      }
      data.push([ts, value.toFixed(4)])
    }
    series.push({
      name: `${codeNameMap[code]}(${code})`,
      type: 'line',
      xAxisIndex: gridIndex,
      yAxisIndex: gridIndex,
      showSymbol: false,
      emphasis: {
        scale: false,
      },
      data: data
    })
  }

  multiChartSetOption(series, ymin, ymax)
}

function multiChartSetOption(series: any, ymin: number[], ymax: number[]) {
  let id = 'kline'
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)

  // @ts-ignore
  myChart.setOption({
    title: {
      text: ''
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      }
    },
    axisPointer: {
      link: [
        {
          xAxisIndex: 'all'
        }
      ],
      label: {
        backgroundColor: '#777'
      }
    },
    legend: {
      middle: 10
    },
    grid: [
      {
        left: '10%',
        right: '8%',
        height: '50%'
      },
      {
        left: '10%',
        right: '8%',
        top: '50%',
        height: '50%'
      }
    ],
    xAxis: [{
      type: 'time',
      splitLine: { show: false },
    }, {
      type: 'time',
      gridIndex: 1,
      axisLine: { onZero: false },
      axisTick: { show: false },
      splitLine: { show: false },
      axisLabel: { show: false },
    }],
    yAxis: [{
      type: 'value',
      min: ymin[0].toFixed(3),
      max: ymax[0].toFixed(3),
      position: 'right',
      axisLabel: {
        formatter: '{value}'
      },
      splitLine: {
        show: false
      }
    }, {
      type: 'value',
      gridIndex: 1,
      min: ymin[1].toFixed(3),
      max: ymax[1].toFixed(3),
      position: 'right',
      axisLabel: {
        formatter: '{value}'
      },
      splitLine: {
        show: false
      }
    }],
    series: series
  })
}

function getSearchResultCodeText(code: string): string {
  if (codeNameMap[code]) {
    return `${codeNameMap[code]}(${code.split('.')[0]})`
  } else {
    return code
  } 
}

function cacheCodeNameMap(data: any[]) {
  let needSave = false
  for (let x of data) {
    if (cacheCodeName(x.id, x.name, false)) {
      needSave = true
    }
  }
  if (needSave) {
    localStorage.setItem('codeNameMap', JSON.stringify(codeNameMap))
  }
}

function cacheCodeName(code: string, name: string, save: boolean): boolean {
  if (code.endsWith('.HK') && !name.endsWith('HK')) {
    name = `${name}.HK`
  } else {
    name = name
  }
  if (codeNameMap[code] == name) {
    return false
  }
  codeNameMap[code] = name
  if (save) {
    localStorage.setItem('codeNameMap', JSON.stringify(codeNameMap))
  }
  return true
}

function transSearchResult(cats: string[], data: any) {
  if (cats.length == 0) {
    cats = ['SZ', 'SH', 'BJ', 'HK', 'US', 'OF']
  }
  let result: any = []
  for (let item of data) {
    let cat = ''
    let code = ''
    switch (item.stock_type) {
      case 11:
        //上证主板、深证主板、创业板
      case 13:
        //指数ETF
      case 82:
        //科创板
      case 90:
        //北交所
        cat = item.code.substring(0, 2)
        code = item.code.substring(2)
        break
      case 30:
        //港股
        cat = 'HK'
        code = item.code
        break
      case 0:
        //美股
      case 4:
        //债券
      case 6:
        //粉单、ADR等
        cat = 'US'
        code = item.code
        break
      case 23:
        //A股场外基金
        cat = 'OF'
        code = item.code.substring(1)
        break
      default:
        log('transSearchResult, unknown type', item)
        break
    }
    if (!cat || !cats.includes(cat)) {
      continue
    }
    let id = `${code}.${cat}`
    cacheCodeName(id, item.query, true)
    result.push({id: id, name: getSearchResultCodeText(id)})
  }
  return result
}

function securitiesFilter(cats: string[], securities: any) {
  let result: any = []
  for (let item of securities) {
    let is = false
    if (cats.length == 0) {
      is = true
    } else {
      is = false
      for (let cat of cats) {
        if (item[0].endsWith(cat)) {
          is = true
        }
      }
    }
    if (is) {
      result.push({ id: item[0], name: item[1] })
    }
  }
  return result
}

function codeSelectInit(cats: string[], id: string, placeholder: string, disabled: boolean) {
  bsSelect(id, {
    placeholder: placeholder,
    cache: true,
    urlParam: 'code',
    request: (term: string)=> {
      if (!term || cats.includes('DC')) {
        return securitiesFilter(cats, securities)
      } else {
        return {
          url: 'https://xueqiu.com/query/v1/suggest_stock.json',
          proxy: true,
          cookie: 'xueqiu',
          params: {
            q: term
          }
        }
      }
    },
    valueTextMap: (value: string)=>{
      return getSearchResultCodeText(value)
    },
    transResults: (data: any, term: string)=>{
      let result: any = []
      if (data.data) {
        return transSearchResult(cats, data.data)
      } else {
        cacheCodeNameMap(data)
        for (let item of data) {
          result.push({id: item.id, name: getSearchResultCodeText(item.id)})
        }
      }
      return result
    }
  })
}

//基金公司筛选
function gsSelectInit(id: string, placeholder: string) {
  /*bsSelect(id, {
    placeholder: placeholder,
    cache: true,
    request: (term: string)=>{
      let data: any = []
      for (let item of gs) {
        if (term == undefined || term == '' || item[1].indexOf(term) > -1) {
          data.push({ id: item[0], name: item[1] })
        }
      }
      return data
    }
  })*/

  bsSelect(id, {
    placeholder: placeholder,
    cache: true,
    request: (term: string)=> {
      return {
        url: 'https://fund.eastmoney.com/js/jjjz_gs.js',
        proxy: true,
        params: {
          v: 0.8898499773117612
        }
      }
    },
    transResults: (data: any, term: string)=>{
      let begin = data.indexOf('[')
      let gs = JSON.parse(data.substring(begin, data.length-1))
      let result: any = []
      for (let item of gs) {
        if (term == undefined || term == '' || item[1].indexOf(term) > -1) {
          result.push({ id: item[0], name: item[1] })
        }
      }
      return result
    }
  })
}

function genAstockOptionCodes() {
  let codes: any[] = []
  for (let type of ['C', 'P']) {
    for (let month of ['2112']) {
      for (let price of ['04500', '04600', '04700', '04800', '04900', '05000', '05250']) {
        let code = `510300${type}${month}M${price}.LO`
        let name = `510300${type}${month}M${price}`
        codeNameMap[code] = name
        codes.push({ id: code, text: name })
      }
    }
  }

  return codes
}

//处理搜索结果数据
function processAstockOptionResults(resp: any) {
  let results: any = []
  for (let x of resp.data) {
    let code = `${x.f12}.SO`
    codeNameMap[code] = x.f14
    results.push({ id: code, text: x.f14 })
  }
  // Transforms the top-level key of the response object from 'data' to 'results'
  return {
    results: results.concat(genAstockOptionCodes())
  }
}

//A股期权代码选择初始化
function astockOptionSelectInit(id: string, code: string) {
  bsSelect(id, {
    placeholder: '下拉选择期权',
    cache: true,
    request: (term: string)=>{
      return `${server}/options/eastmoney?code=${encodeURIComponent(code)}`
    },
    transResults: (data: any, term: string)=>{
      let results: any = []
      for (let x of data) {
        let code = `${x.f12}.SO`
        codeNameMap[code] = x.f14
        results.push({ id: code, text: x.f14 })
      }
      results = results.concat(genAstockOptionCodes())
      return results
    }
  })
}

interface AutocompleteConfig {
  //最小触发字符数
  minLength: number;
  //搜索等待延时
  delay?: number;
  //缓存最近点击的
  cacheSelecNum: number;
  //远端数据获取请求拼装
  request: (term: string) => any;
  //转换服务端返回数据
  transResults?: (data: any) => any;
  //选择事件处理
  select: (event: Event, item: any)=>void;
}

// 搜索补全
function bsAutocomplete(id: string, config: AutocompleteConfig) {
  const recentKey = `${id}-recentClick`
  let recents: any = []
  if (config.cacheSelecNum) {
    let str = localStorage.getItem(recentKey)
    if (str) {
      recents = JSON.parse(str)
    }
  }
  
  if (!config.delay) {
    config.delay = 250
  }
  //@ts-ignore
  let input: HTMLInputElement = document.getElementById(id)
  let list = document.createElement('div')
  list.classList.add('list-group', 'list-autocomplete', 'mt-1')
  //@ts-ignore
  input.parentNode.insertBefore(list, input.nextSibling)
  let tid: number
  ['input', 'click'].forEach((type)=>{
    input.addEventListener(type, (e)=>{
      e.stopPropagation()
      if (tid) {
        clearTimeout(tid)
      }
      tid = setTimeout(()=>{
        let term = input.value.trim()
        if (term.trim().length < config.minLength) {
          if (config.cacheSelecNum && recents.length) {
            response(recents)
          }
        } else {
          fetchRequest(config.request(term), (data: any)=>{
            response(config.transResults? config.transResults(data): data)
          })
        }
        //@ts-ignore
      }, config.dealy)
    })
  })

  document.addEventListener('click', (e)=>{
    list.classList.add('d-none')
  })
  
  const response = (result: any)=> {
    let html = ''
    for (let item of result) {
      html += `<a href="#" class="list-group-item list-group-item-action" data-id="${item.id}">${item.name}</a>`
    }
    list.innerHTML = html
    list.classList.remove('d-none')
    list.querySelectorAll('.list-group-item').forEach((elem)=> {
      elem.addEventListener('click', (e)=>{
        e.stopPropagation()
        if (config.cacheSelecNum) {
          //@ts-ignore
          recents.unshift({id: elem.dataset.id, name: elem.textContent})
          //去重
          recents = recents.filter((v1: any, i: number)=> i == recents.findIndex((v2: any)=> v2.id==v1.id))
          if (recents.length > config.cacheSelecNum) {
            recents = recents.slice(0, config.cacheSelecNum)
          }
          localStorage.setItem(recentKey, JSON.stringify(recents))
        }
        //@ts-ignore
        config.select(e, {id: elem.dataset.id, name: elem.textContent})
      })
    })
  }
}

//代码搜索补全
function codeSearchInit() {
  bsAutocomplete('autocomplete', {
    minLength: 2,
    cacheSelecNum: 20,
    request: (term: string)=> {
      if (term) {
        return {
          url: 'https://xueqiu.com/query/v1/suggest_stock.json',
          cacheKey: 'autocomplete-'+term,
          cacheTtl: 360000,
          proxy: true,
          cookie: 'xueqiu',
          params: {
            q: term
          }
        }
      } else {
        return securitiesFilter([], securities)
      }
    },
    transResults: (data: any)=>{
      if (data.data) {
        return transSearchResult([], data.data)
      } else {
        let result: any = []
        cacheCodeNameMap(data)
        for (let item of data) {
          result.push({id: item.id, name: getSearchResultCodeText(item.id)})
        }
        return result
      }
    },
    // @ts-ignore
    select: function (event, item) {
      let code: string = item.id
      let parts = code.split(".")
      let herf = ''
      switch (parts[1]) {
        case 'SZ'://深证
        case 'SH'://上证
        case 'BJ'://北交
        case 'HK'://香港
        case 'US'://美股
          if (currentPage().startsWith('company')) {
            herf = `${currentPage()}?code=${code}`
          } else {
            herf = `company.html?code=${code}`
          }
          break;
        case 'OF': //场外
        case 'SF': //上证
        case 'ZF': //深证
          herf = `fund.html?code=${code}`
          break;
        case 'ZI': //深证
        case 'SI': //上证
        case 'HI':
          herf = `index.html?code=${code}`
          break;
        default:
          break;
      }
      if (herf != '') {
        window.location.href = herf
      }
    }
  })
}

function calculateMA(data: number[][], window: number) {
  let result: any = [ ]
  if (data.length < window) {
    return result;
  }
  let sum = 0
  for (let i = 0; i < window; i++) {
      sum += data[i][1]
      if (i < window-1) {
        result.push('-')
      }
  }
  result.push(sum / window)
  var steps = data.length - window
  for (var i = 0; i < steps; i++) {
      sum -= data[i][1]
      sum += data[i + window][1]
      result.push(sum / window)
  }
  return result
}

function onRatioCheckChange(checked: boolean) {
  if (checked) {
    if (selectedCodes.length < 2) {
      log('selected.length < 2')
      return
    }

    cache['ratio'] = []
    let c1 = selectedCodes[0]
    let c2 = selectedCodes[1]
    //用大的除以小的
    if (cache[c1][cache[c1].length - 1][1] < cache[c2][cache[c2].length - 1][1]) {
      c1 = selectedCodes[1]
      c2 = selectedCodes[0]
    }
    let i = 0;
    let j = 0;
    let k = 0;
    while (i < cache[c1].length && j < cache[c2].length) {
      if (cache[c1][i][0] < cache[c2][j][0]) {
        i++
        continue
      } else if (cache[c1][i][0] > cache[c2][j][0]) {
        j++
        continue
      }

      cache['ratio'][k++] = [cache[c1][i][0], cache[c1][i][1] / cache[c2][j][1]]
      i++
      j++
    }
    codeNameMap['ratio'] = 'ratio'
    klineCodes.push( 'ratio')
  } else {
    klineCodes = klineCodes.filter(code => code != 'ratio')
  }

  rerenderMyChart()
}

//差值k线
function diffKline(c1: string, c2: string) {
  cache['diff'] = []
  //用大的减去小的
  if (cache[c1][cache[c1].length - 1][1] < cache[c2][cache[c2].length - 1][1]) {
    c1 = selectedCodes[1]
    c2 = selectedCodes[0]
  }
  let i = 0;
  let j = 0;
  let k = 0;
  while (i < cache[c1].length && j < cache[c2].length) {
    if (cache[c1][i][0] < cache[c2][j][0]) {
      i++
      continue
    } else if (cache[c1][i][0] > cache[c2][j][0]) {
      j++
      continue
    }

    cache['diff'][k++] = [cache[c1][i][0], cache[c1][i][1] - cache[c2][j][1]]
    i++
    j++
  }
  codeNameMap['diff'] = 'diff'
  klineCodes.push('diff')
}

//k线求合
function addKline(c1: string, c2: string) {
  cache['add'] = []
  let i = 0;
  let j = 0;
  let k = 0;
  while (i < cache[c1].length && j < cache[c2].length) {
    if (cache[c1][i][0] < cache[c2][j][0]) {
      i++
      continue
    } else if (cache[c1][i][0] > cache[c2][j][0]) {
      j++
      continue
    }

    cache['add'][k++] = [cache[c1][i][0], cache[c1][i][1] + cache[c2][j][1]]
    i++
    j++
  }
  codeNameMap['add'] = 'add'
  klineCodes.push('add')
}

function onFinanceCheckChange(selected: any[], type: string, checked: boolean) {
  if (!checked) {
    deleteKline(type)
    rerenderMyChart()
    return
  }

  let idx = 1
  switch (type) {
    case 'value':
      //市值
      idx = 7
      break
    case 'pettm':
      idx = 8
      break
    case 'pb':
      idx = 9
      break
    case 'ps':
      idx = 10
      break
    default:
      log('onFinanceCheckChange, unknown type', type)
      return
  }

  for (let code of selected) {
    //非A股公司不处理
    if (!isAstockCompany(code) && !isHkCompany(code) && !code.endsWith('.US')) {
      continue
    }
    let data = cache[code]
    let items: any[] = []
    for (let i=0;i< data.length;i++) {
      if (type == 'value') {
        //市值换算成亿元
        items.push([data[i][0], data[i][idx]/1e8])
      } else {
        items.push([data[i][0], data[i][idx]])
      }
    }
    let key = `${code}-${type}`
    cache[key] = items
    codeNameMap[key] = `${codeNameMap[code]}-${type}`
    klineCodes.push(key)
  }

  rerenderMyChart()
}

//累加4个季度的数据
function sum4SeasonData(report: any[], idx: number, key: string): number {
  let sum = 0
  let len = 4
  let months = parseInt(report[0].reportDate.substring(5, 7)) - parseInt(report[1].reportDate.substring(5, 7))
  if (months < 0) {
    months += 12
  }
  if (months == 3) {
    len = 4
  } else if (months == 6) {
    len = 2
  } else {
    log('wrong months', code, months)
    return 0
  }
  
  for (let i = idx; i < len; i++) {
    sum += report[i][key]
  }
  return sum
}

function onBonusCheckChange(selected: any[], checked: boolean) {
  if (!checked) {
    deleteKline('bs')
    rerenderMyChart()
    return
  }

  //计算实际需要处理的code
  let codes: any = []
  for (let code of selected) {
    //非A股和港股公司不处理
    if (!isAstockCompany(code) && !isHkCompany(code)) {
      continue
    }
    let cacheKey = `${code}-bs`
    if (cache[cacheKey]) {
      continue
    }
    codes.push(code)
  }

  if (codes.length == 0) {
    rerenderMyChart()
    return
  }

  let should = 2
  let done = 0
  let success = function (codes: string[]) {
    done++
    if (done == should) {
      calcBonusKline(codes)
      rerenderMyChart()
      return
    }
  }

  fetchKlines(codes, 'normal', success)
  fetchSharesBonus(codes, success)
}


function calcBonusKline(codes: any[]) {
  const bonusYearMin = 3600*1000*24*280
  const bonusYearMax = 3600*1000*24*450
  for (let code of codes) {
    let klineKey = `${code}normal`
    let bonusKey = `${code}-bs`
    let bonusIdx = 0
    let shareIdx = 0
    let bonus = 0
    let nextCalcTs = Date.now() + 3600*24*1000 //小于这个时间戳得重新计算股息率
    let value = 0
    let items: any[] = []
    //标记分红线
    for (let i=0;i< cache[bonusKey].length; i++) {
      markPoints.push({ name: `分红-${codeNameMap[code]}: ${cache[bonusKey][i].plan}`, x: toTimestamp(cache[bonusKey][i].divDate), color: 'red'})
    }
    //k线倒序
    for (let i = cache[klineKey].length - 1; i > -1; i--) {
      let ts = cache[klineKey][i][0]
      let marketValue = cache[klineKey][i][7] //企业市值
      if (ts < nextCalcTs) {
        //重新计算分红
        if (bonusIdx >= cache[bonusKey].length) {
          //没有分红了
          break
        }
        bonus = 0
        for (let j = bonusIdx; j< cache[bonusKey].length; j++) {
          let recordTs = toTimestamp(cache[bonusKey][j].recordDate)
          //没有分红时，按照尽量长计算，避免每年分红时间不匀的空档期
          if (bonus == 0 && recordTs + bonusYearMax > ts) {
            nextCalcTs = recordTs
            bonusIdx++
          } else if (bonus > 0 && recordTs + bonusYearMin > ts) {
            //累加
          } else {
            break
          }
          
          //查找分红登记日时的股份总数，计算分红总额
          let k = findTsIndex(cache[klineKey], recordTs)
          //总份额=
          let totalShares = cache[klineKey][k][7]/cache[klineKey][k][1]
          //股息率只算现金分红(因为转股和送股等于没有分红)
          bonus += cache[bonusKey][j].bonus * totalShares
        }
        //log('new calc bouns', new Date(ts).toLocaleDateString(), bonus)
      }

      value = bonus * 100 / marketValue
      items.push([ts, value])
    }
    let key = `${code}-bs`
    cache[key] = items.reverse()
    codeNameMap[key] = `${codeNameMap[code]}-股息率%`
    klineCodes.push(key)
  }
}

function genratePerformanceTable() {
  let rows = genratePerformanceRows()
  let html = `<thead class="table-info">
        <tr>
            <th>周期表现</th>
            <th>名称简称</th>
            <th>最新价格</th>
            <th>最新涨跌幅(%)</th>`

  for (let i = 0; i < days.length; i++) {
    html += `<th>相比${days[i]}日(%)</th>`
  }

  html += `</tr>
    </thead>${genColorTbody(rows, (idx)=> idx > 2)}`
  bsTable('performance', {data: html})
}

function genratePerformanceRows() {
  let rows = []
  for (let code of selectedCodes) {
    let j = 0
    // 代码，名称，最新价、最新涨跌幅....
    let row = [code, codeNameMap[code]]
    let lastDayTs = cache[code][cache[code].length - 1][0]
    let currentPrice = cache[code][cache[code].length - 1][1]
    for (let i = cache[code].length - 1; i >= 0; i--) {
      if (i == cache[code].length - 1) {
        row.push(cache[code][i][1])
      } else if (i == cache[code].length - 2) {
        row.push((currentPrice - cache[code][i][1]) * 100 / cache[code][i][1])
      }

      let ts = lastDayTs - 24 * 3600 * 1000 * days[j]
      if (cache[code][i][0] < ts) {
        row.push((currentPrice - cache[code][i][1]) * 100 / cache[code][i][1])
        j++
      }

      if (j == days.length) {
        break
      }
    }

    for (let k = 2; k < row.length; k++) {
      row[k] = row[k].toFixed(2)
    }

    // 有些股票上市时间不够长，补齐
    for (let k = row.length; k < 4 + days.length; k++) {
      row.push('-')
    }

    rows.push(row)
  }

  return rows
}

function klineOptionsChange() {
  let vals = $('#klineOptions').val()
  let success = () => {
    for (let val of ['value', 'pettm', 'pb', 'ps', 'bonus']) {
      let checked = vals.includes(val)
      if (val == 'bonus') {
        onBonusCheckChange(selectedCodes, checked)
      } else {
        onFinanceCheckChange(selectedCodes, val, checked)
      }
    }
  }
  if (vals.length > 0) {
    fetchKlines(selectedCodes, '', function (codes) {
      success()
    })
  } else {
    success()
  }
}

function onKlineCodeSelectChange() {
  if ($('#codes').val().length == 0) {
    log('codes none')
    return
  }

  selectedCodes = $('#codes').val()
  klineCodes = [] //清空
  changeCodeSpecHref()
  fetchKlines(selectedCodes, '', function (codes) {
    klineCodes.push(...selectedCodes)
    klineOptionsChange()
    rerenderMyChart()
    genrateRegressTable()
    genratePerformanceTable()
    generateMarketTable(selectedCodes)
    if (selectedCodes.length > 1) {
      genrateCorrelationCoefficientTable()
    }
  })

  /*fetchFundPositions(selectedCodes, 1, function (error, codes) {
    if (error != null) {
      log(error)
      return
    }
    genratePositionTable(codes)
  })*/
}

//网格策略
function trade1(params: any, code: string, price: number, cash: number, position: { [key: string]: number }, data: { [key: string]: any }): number {
  let amount = 0;//金额
  let quantity = 0;//数量
  const minUnit= 100 //最小交易单位
  let dropRatio = params.initDrop
  if (position[code] > 1) {
    //连续买入
    dropRatio = params.drop
  }

  const codeData = data[code]
  if (position[code] < 1 && (!codeData.lastTradePrice || price > codeData.lastTradePrice)) {
    //空仓时从最高价格算跌幅
    codeData.lastTradePrice = price
  }

  //降价超过某个比例买
  if (price < codeData.lastTradePrice * (1 - dropRatio) && cash > params.initCash * 0.01) {
    amount = params.initCash * params.ratio
    if (amount > cash) {
      amount = cash
    }
    quantity = Math.floor(amount / price/minUnit)*minUnit
    return quantity
  }

  //涨价超过某个比例卖
  if (price > codeData.lastTradePrice * (1 + params.raise) && position[code] > 99) {
    quantity = Math.round(params.initCash * params.ratio / price/minUnit)*minUnit
    if (quantity > position[code]) {
      quantity = position[code]
    }

    //卖出是负数
    return -quantity
  }

  return 0
}

//买入时按照移动均线买入，卖出时移动止盈
function trade2(params: any, code: string, price: number, cash: number, position: { [key: string]: number }, data: { [key: string]: any }): number {
  const ma = Math.round(params.ma)
  let amount = 0;//金额
  let quantity = 0;//数量
  const minUnit= 100 //最小交易单位
  let dropRatio = params.initDrop
  if (position[code] > 1) {
    //连续买入
    dropRatio = params.drop
  }

  const codeData = data[code]
  if (position[code] < 1 && (!codeData.lastTradePrice || price > codeData.lastTradePrice)) {
    //空仓时从最高价格算跌幅
    codeData.lastTradePrice = price
  }

  let avg = 0
  if (codeData.lastPrices && codeData.lastPrices.length >= ma) {
    codeData.lastPrices.map((v: any)=> avg += v)
    avg /= ma
  } else {
    avg = price
  }

  if (codeData.lastPrices) {
    codeData.lastPrices.unshift(price)
  } else {
    codeData.lastPrices = [price]
  }

  if (codeData.lastPrices.length > ma) {
    codeData.lastPrices = codeData.lastPrices.slice(0, ma)
  }

  //降价超过某个比例并且价格企稳再买，防止买在半山腰
  if (price < codeData.lastTradePrice * (1 - dropRatio) && price > avg && cash > params.initCash * 0.01) {
    amount = params.initCash * params.ratio
    if (amount > cash) {
      amount = cash
    }
    quantity = Math.floor(amount / price/minUnit)*minUnit
    return quantity
  }

  //涨价超过某个比例后回撤一定幅度卖
  if (price > codeData.lastTradePrice * (1 + params.raise) && price < avg && position[code] > 99) {
    quantity = Math.round(params.initCash * params.ratio / price/minUnit)*minUnit
    if (quantity > position[code]) {
      quantity = position[code]
    }

    //卖出是负数
    return -quantity
  }

  return 0
}

function stockStgRun(codes: string[], klines: any, startTs: number, endTs: number, params: any, trades: any[], trade: (params: any, code: string, price: number, cash: number, position: { [key: string]: number }, data: { [key: string]: any }) => number): any {
  let cash = params.initCash
  let quantity: number = 0
  let totalAsset = cash; //总资产
  let idxMap: { [key: string]: number } = {}
  let position: { [key: string]: number } = {} //持仓
  let priceMap: { [key: string]: number } = {} //当前价格
  let data: { [key: string]: any } = {} //个性化数据
  let kline = [[startTs, totalAsset]]

  //init
  for (let code of codes) {
    idxMap[code] = 0
    position[code] = 0
    data[code] = {}
  }

  let openDay = false
  let isTrade = false
  let currentTs = startTs
  let amount = 0
  while (currentTs <= endTs) {
    //依次处理每个成分股或者基金
    openDay = false
    isTrade = false
    for (let code of codes) {
      let ts = klines[code][idxMap[code]][0]
      while (ts < currentTs) {
        if (idxMap[code] >= klines[code].length - 1) {
          //此股票已经遍历完了
          break
        }

        idxMap[code] += 1
        ts = klines[code][idxMap[code]][0]
      }

      if (ts < currentTs || ts > currentTs) {
        //完了，或者太早需要等
        continue
      }

      openDay = true
      priceMap[code] = klines[code][idxMap[code]][1]
      quantity = trade(params, code, priceMap[code], cash, position, data)
      if (quantity != 0) {
        isTrade = true
        data[code].lastTradePrice = priceMap[code]
        position[code] += quantity
        //考虑交易成本
        if (quantity > 0) {
          amount = Math.ceil(priceMap[code] * quantity)
        } else {
          amount = Math.floor(priceMap[code] * quantity)
        }
        cash -= amount
        //log.info(`${func.toDateNumber(ts)} 交易 ${code} ${quantity}股 at ￥${priceMap[code]}/股 with ￥${(quantity * priceMap[code]).toFixed(0)},总现金￥${cash.toFixed(0)},持仓${position[code].toFixed(0)}股`)
        totalAsset = cash
        for (let code of codes) {
          totalAsset += position[code] * priceMap[code]
        }
        //交易记录
        trades.push([toDateString(ts), `${codeNameMap[code]}(${code})`, priceMap[code], quantity, amount, cash, totalAsset.toFixed(2)])
        if (quantity > 0) {
          markPoints.push({name: `买入-${codeNameMap[code]}`, x: ts, color: 'red'})
        } else {
          markPoints.push({name: `卖出-${codeNameMap[code]}`, x: ts, color: 'blue'})
        }
      }
    }

    if (openDay) {
      totalAsset = cash
      for (let code of codes) {
        totalAsset += position[code] * priceMap[code]
      }
      kline.push([currentTs, totalAsset])
    }

    currentTs += 3600 * 24 * 1000
  }

  return {ratio: totalAsset / params.initCash - 1, kline: kline}
}

function stgRun(stg: string) {
  let startTs = new Date($('#dateRange-start').value).getTime()
  let endTs = new Date($('#dateRange-end').value).getTime()
  //0.35 0.4 0.05 0.2
  //{ "initCash":1000000, "ratio":0.35, "initDrop":0.4, "drop":0.05, "raise":0.2 }
  let params: any = {}
  params.initCash = 1e6
  let maxRatio = -1e9
  switch (stg) {
    case "1":
      for (let key of ['ratio', 'initDrop', 'drop', 'raise']) {
        //@ts-ignore
        params[key] = parseFloat($(`#${key}`).value)
      }
      stgRunInternal(selectedCodes, startTs, endTs, params, maxRatio, trade1)
      break
    case "2":
      for (let key of ['ratio', 'initDrop', 'drop', 'raise', 'ma']) {
        //@ts-ignore
        params[key] = parseFloat($(`#${key}`).value)
      }
      stgRunInternal(selectedCodes, startTs, endTs, params, maxRatio, trade2)
      break
    case "3":
      for (let ratio=0.05;ratio < 0.3; ratio += 0.05) {
        for (let initDrop=0.05;initDrop < 0.35; initDrop += 0.05) {
          for (let drop=0.01;drop < 0.15; drop += 0.01) {
            for (let raise=0.01;raise < 0.2; raise += 0.01) {
              params = {ratio,initDrop,drop,raise}
              let r = stgRunInternal(selectedCodes, startTs, endTs, params, maxRatio, trade1)
              if (r > maxRatio) {
                maxRatio = r
              }
            }
          }
        }
      }
      break
    default:
      log('wrong stg', stg)
      break
  }
}

function stgRunInternal(codes: string[], startTs: number, endTs: number, params: any, maxRatio: number, trade: (params: any, code: string, price: number, cash: number, position: { [key: string]: number }, lastTradePrice: { [key: string]: number }) => number) {
  params.initCash = 1e6
  let trades: any = []

  let id = stgIdx
  let result = stockStgRun(codes, cache, startTs, endTs, params, trades, trade)
  if (trades.length > 0 && result.ratio > maxRatio) {
    let name = `stg${stgIdx}`
    cache[name] = result.kline
    codeNameMap[name] = name
    klineCodes.push(name)
    stgIdx++
    $('#stg-params').innerHTML = `<tr><td>stg${id}</td><td>${JSON.stringify(params)}</td></tr>` + $('#stg-params').innerHTML
    //计算每步盈亏
    let base = trades[0][6]
    for (let i=0;i< trades.length;i++) {
      trades[i].push((100*trades[i][6]/base -100).toFixed(2))
    }

    let table = document.createElement('table')
    table.id = `stgTable-${id}`
    table.classList.add('table', 'table-sm', 'table-bordered', 'table-hover')
    table.innerHTML = `<thead class="table-dark">
    <tr>
      <th scope="col">${table.id}</th>
      <th scope="col">股票</th>
      <th scope="col">价格</th>
      <th scope="col">买卖股数</th>
      <th scope="col" >买卖金额</th>
      <th scope="col" >剩余现金</th>
      <th scope="col" >资产总额</th>
      <th scope="col">总盈亏(%)</th>
    </tr>
    </thead>`
    
    let div = $('#trades')
    div.insertBefore(table, div.firstChild)
    bsTable(table.id, {data: trades})
  }
  
  return  result.ratio
}

function genStg1Params() {
  //{ "initCash":1000000, "ratio":0.35, "initDrop":0.4, "drop":0.05, "raise":0.2 }
  let html = `<div class="col"></div>
<div class="col-10">
  <div class="input-group">
    <span class="input-group-text">单次加减仓比例:</span>
    <input id="ratio" type="text" class="form-control" value="0.2">
    <span class="input-group-text">初始跌幅多少入场:</span>
    <input id="initDrop" type="text" class="form-control" value="0.2">
    <span class="input-group-text">再次下跌多少加仓:</span>
    <input id="drop" type="text" class="form-control" value="0.05">
    <span class="input-group-text">上涨多少减仓:</span>
    <input id="raise" type="text" class="form-control" value="0.1">
    <button class="btn btn=sm btn-secondary" type="button" id="stgCompute">计算</button>
  </div>
</div>
<div class="col"></div>`

  $('#params').innerHTML = html
}

function onStgSelectChange() {
  let stg = $('#stg').value
  document.getElementsByName('params').forEach((elem: any)=>{
    if (elem.dataset.id == stg) {
      elem.classList.remove('d-none')
    } else {
      elem.classList.add('d-none')
    }
  })
}

function onStgCodeSelectChange() {
  selectedCodes = $('#codes').val()
  if (selectedCodes.length == 0) {
    log('codes none')
    return
  }

  for (let i=0;i< klineCodes.length;i++) {
    if (!selectedCodes.includes(klineCodes[i])) {
      klineCodes.splice(i)
    }
  }

  fetchKlines(selectedCodes, '', function (codes) {
    klineCodes.push(...selectedCodes)
    rerenderMyChart()
  })
}

//计算最大回撤
function calcMaxDrop(kline: number[][], startTs: number, endTs: number) {
  let sIdx = findTsIndex(kline, startTs)
  let eIdx = findTsIndex(kline, endTs)
  if (sIdx < 0) {
    sIdx = 0
  }
  let minPrice = 1e9
  let maxDrop = 0
  for (let i = eIdx; i >= sIdx; i--) {
    let value = kline[i][1]
    if (value < minPrice) {
      minPrice = value
    }

    if ((value - minPrice) / value > maxDrop) {
      maxDrop = (value - minPrice) / value
    }
  }

  return maxDrop
}

function genRegressTable() {
  let startTs = new Date($('#dateRange-start').value).getTime()
  let endTs = new Date($('#dateRange-end').value).getTime()
  let years = (endTs - startTs) / (1000 * 3600 * 24 * 365)
  let rows: any = []
  for (let code of klineCodes) {
    let sIdx = findTsIndex(cache[code], startTs)
    let eIdx = findTsIndex(cache[code], endTs)
    if (sIdx < 0) {
      sIdx = 0
    }
    let times = cache[code][eIdx][1] / cache[code][sIdx][1]
    let ratio = 100 * times - 100
    let yr = 100 * Math.pow(times, 1 / years) - 100
    rows.push([code, codeNameMap[code], ratio.toFixed(2), yr.toFixed(2), (100 * calcMaxDrop(cache[code], startTs, endTs)).toFixed(2)])
    
  }

  bsTable('regress', {data: rows})
}

function onStgComputeClick() {
  markPoints = []
  //@ts-ignore
  stgRun($('#stg').value)
  rerenderMyChart()
genRegressTable()
}

function onFinanceCodeSelectChange() {
  selectedCodes = $('#codes').val()
  if (selectedCodes.length == 0) {
    log('codes none')
    return
  }
  
  changeCodeSpecHref()
  financeCharTableOnChange()
}

// 计算年化标准差
function calcAnnalStddev(kline: number[][], days: number): number[][] {
  let ratio: number = 0;
  let square: number = 0;
  let std: number = 0;
  let annalRatio = Math.pow(250, 0.5);
  let ratios: number[] = [];
  let sums: number[] = [];
  let squares: number[] = [];
  let sumSquares: number[] = [];
  let stds: number[][] = [];
  for (let i = 1; i < kline.length; i++) {
    ratio = Math.log(kline[i][1] / kline[i - 1][1]);
    square = Math.pow(ratio, 2);
    ratios.push(ratio);
    squares.push(square);
    if (i == 1) {
      sums.push(ratio);
      sumSquares.push(square);
    } else if (i < days + 1) {
      sums.push(sums[i - 2] + ratio);
      sumSquares.push(sumSquares[i - 2] + square);
    } else {
      sums.push(sums[i - 2] + ratio - ratios[i - days - 1]);
      sumSquares.push(sumSquares[i - 2] + square - squares[i - days - 1]);
    }

    std = annalRatio * Math.pow((sumSquares[i - 1] - Math.pow(sums[i - 1], 2) / days) / (days - 1), 0.5)
    stds.push([kline[i][0], std]);
  }
  return stds;
}

function echartsPie(id: string, title: string, name: string, data: any[]) {
  let option = {
    title: {
      text: title,
      left: 'center'
    },
    tooltip: {
      trigger: 'item',
      formatter: "{a} <br/>{b} : {c} ({d}%)"
    },
    series: [
      {
        name: name,
        type: 'pie',
        radius: '50%',
        data: data,
        label: {
          formatter: '{b}:{c}({d}%)',
          fontWeight: 'normal',
          fontSize: 15
        },
        emphasis: {
          itemStyle: {
            shadowBlur: 10,
            shadowOffsetX: 0,
            shadowColor: 'rgba(0, 0, 0, 0.5)'
          }
        }
      }
    ]
  };

  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)
  myChart.setOption(option)
}

function fetchIndexFunds(code: string, callback: (code: string, data: any) => void) {
  let cacheKey = `${code}-if`
  fetchRequest({
    url: `${server}/index/funds?code=${code}`,
    cacheKey: cacheKey,
    cacheTtl: 360000
  }, (data: any)=>{
    callback(code, data)
  })
}

//批量获取多个code的信息
function fetchCodesData(codes: string[], fn: (code: string, succ: (code: string) => void) => void, callback: CodesCallback) {
  let should = codes.length
  let done = 0
  let success = function (code: string) {
    done++
    if (done == should) {
      callback(codes)
    }
  }

  for (let code of codes) {
    fn(code, success)
  }
}

function fetchFinanceIncome(code: string, callback: (code: string) => void) {
  let cacheKey = `${code}-fsi`
  if (cache[cacheKey] != undefined) {
    callback(code)
    return
  }

  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    fetchFinanceIncomeCN(cacheKey, code, callback)
  } else if (code.endsWith('.HK')) {
    fetchFinanceIncomeHK(cacheKey, code, callback)
  } else if (code.endsWith('.US')) {
    if (usCodeMap[code]) {
      fetchFinanceIncomeUS(cacheKey, code, callback)
    } else {
      fetchCompanyInfo(code, (data: any)=>{
        fetchFinanceIncomeUS(cacheKey, code, callback)
      })
    }
  } else {
    log('fetchFinanceIncome,wrong code', code)
  }
}

function fetchFinanceIncomeCN(cacheKey: string, code: string, callback: (code: string) => void): void {
  let type = 'GINCOMEQC'
  if (isCodeBank(code)) {
    type = 'BINCOMEQC'
  }
  let dates = genReportDates(11)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/get',
    params: {
      type: `RPT_F10_FINANCE_${type}`,
      sty: `APP_F10_${type}`,
      filter: `(SECUCODE="${code}")(REPORT_DATE in ('${dates.join("','")}'))`,
      p: 1,
      ps: '',
      sr: -1,
      st: 'REPORT_DATE',
      source: 'HSF10',
      client: 'PC'
    }}, (data: any, cacheMiss: boolean)=>{
      if (cacheMiss) {
        parseFinanceIncomeCN(cacheKey, incomeKeys, 2, data.result.data, callback)
      } else {
        callback(code)
      }
    })
}

function parseFinanceIncomeCN(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let items: any = []
  let unknown: string[][] = []
  for (let i=0; i < list.length; i++) {
    let item: any = {
      reportDate: list[i].REPORT_DATE.substring(0, 10),
      noticeDate: list[i].NOTICE_DATE.substring(0, 10)
    }

    for (let key in list[i]) {
      if (typeof list[i][key] != 'number' || key.endsWith('_YOY') || key.endsWith('_QOQ') || ignoreKeys.includes(key)) {
        continue
      }
      let name = key
      let it = keys.find((item)=> item[col] == name)
      if (it) {
        name = it[0]
      } else if (list[i][key] && item.reportDate > '2018') {//老财报不管
        unknown.push([name, key, item.reportDate, financeFormat(list[i][key])])
      }

      if (item[name]) {
        //name重复了
        log('parseFinanceBalanceCN,dup name', key, it)
      }
      item[name] = list[i][key]
    }

    //毛利润
    item.grossProfit = item.operateIncome - item.operateCost
    //其他经营收益
    item.otherOperateIncome = item.fairvalueChangeIncome + item.investIncome + item.assetDisposalIncome + item.assetImpairmentIncome + item.creditImpairmentIncome
    + item.otherIncome
    items.push(item)
  }

  if (unknown.length) {
    log('parseFinanceIncomeCN', JSON.stringify(unknown.filter((item, index)=> index == unknown.findIndex(o=> o[1] == item[1])), null, 1))
  }
  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceIncomeHK(cacheKey: string, code: string, callback: (code: string) => void): void {
  let dates = genReportDates(11)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    params: {
      reportName: 'RPT_HKF10_FN_INCOME_PC',
      columns: 'SECUCODE,SECURITY_CODE,SECURITY_NAME_ABBR,ORG_CODE,REPORT_DATE,DATE_TYPE_CODE,FISCAL_YEAR,START_DATE,STD_ITEM_CODE,STD_ITEM_NAME,AMOUNT',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")(REPORT_DATE in ('${dates.join("','")}'))'`,
      pageNumber: 1,
      pageSize: '',
      sortTypes: '-1,1',
      sortColumns: 'REPORT_DATE,STD_ITEM_CODE',
      source: 'F10',
      client: 'PC'
    }}, (data: any, cacheMiss: boolean)=>{
      if (cacheMiss) {
        parseFinanceIncomeHK(cacheKey, incomeKeys, 3, data.result.data, callback)
      } else {
        callback(code)
      }
    })
}

function parseFinanceIncomeHK(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let dataMap: any = {}
  let items: any = []
  let all: string[][] = []
  for (let item of list) {
    if (ignoreKeys.includes(item.STD_ITEM_NAME)) {
      continue
    }
    let reportDate = item.REPORT_DATE.substring(0, 10)
    if (!dataMap[reportDate]) {
      dataMap[reportDate] = {reportDate}
    }
    let name = item.STD_ITEM_NAME
    let key = keys.find((item)=> item[col] == name)
    if (key) {
      name = key[0]
    } else if (item.AMOUNT && reportDate > '2018') {
      all.push([name, item.STD_ITEM_NAME, reportDate, financeFormat(item.AMOUNT)])
    }
    if (dataMap[reportDate][name]) {
      //name重复了
      log('parseFinanceIncomeHK,dup name', item.STD_ITEM_NAME, key)
    }
    dataMap[reportDate][name] = item.AMOUNT
  }
  if (all.length) {
    log('parseFinanceIncomeHK', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  let dates: string[] = Object.keys(dataMap).sort().reverse()
  for (let i=0;i<dates.length;i++) {
    if (!dataMap[dates[i]]) {
      continue
    }
    let month = dates[i].substring(5, 7)
    let item: any = {}
    switch (month) {
      case '03':
        item = dataMap[dates[i]]
        break
      case '06':
      case '09':
      case '12':
        if (i < dates.length-1) {
          for (let key in dataMap[dates[i]]) {
            if (key != 'reportDate' && dates[i].substring(0, 4) == dates[i+1].substring(0, 4)) {
              //算同一年当期的
              item[key] = dataMap[dates[i]][key] - dataMap[dates[i+1]][key]
            } else {
              item[key] = dataMap[dates[i]][key]
            }
          }  
        }
        break
      default:
        console.log('wrong report date', month)
        break
    }
    if (!item.totalOperateCost && item.operateCost) {
      item.totalOperateCost = item.operateCost
    }
    if (item.reportDate) {
      items.push(item)
    }
  }

  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceIncomeUS(cacheKey: string, code: string, callback: (code: string) => void): void {
  let dates = genReportDatesUS(11, true)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    params: {
      reportName: 'RPT_USF10_FN_INCOME',
      columns: 'SECUCODE,REPORT,REPORT_DATE,STD_ITEM_CODE,ITEM_NAME,AMOUNT',
      quoteColumns: '',
      filter: `(SECUCODE="${usCodeMap[code]}")(REPORT in ("${dates.join('","')}"))'`,
      //filter: `(SECUCODE="BABA.N")(REPORT in ("2023/Q1","2022/Q4","2022/Q3","2022/Q2","2022/Q1","2021/Q4"))`,
      pageNumber: '',
      pageSize: '',
      sortTypes: '1,-1',
      sortColumns: 'STD_ITEM_CODE,REPORT_DATE',
      source: 'SECURITIES',
      client: 'PC'
    }}, (data: any, cacheMiss: boolean)=>{
      if (cacheMiss) {
        parseFinanceIncomeUS(cacheKey, incomeKeys, 4, data.result.data, callback)
      } else {
        callback(code)
      }
      
    })
}

function parseFinanceIncomeUS(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let dataMap: any = {}
  let items: any = []
  let all: string[][] = []
  for (let item of list) {
    if (ignoreKeys.includes(item.ITEM_NAME)) {
      continue
    }
    let reportDate = item.REPORT_DATE.substring(0, 10)
    if (!dataMap[reportDate]) {
      dataMap[reportDate] = {reportDate}
    }

    let name = item.ITEM_NAME
    let key = keys.find((item)=> item[col] == name)
    if (key) {
      name = key[0]
    } else if (item.AMOUNT && reportDate > '2018') {
      all.push([name, item.ITEM_NAME, reportDate, financeFormat(item.AMOUNT)])
    }
    if (dataMap[reportDate][name]) {
      //name重复了
      log('parseFinanceIncomeUS,dup name', item.ITEM_NAME, key)
    }
    dataMap[reportDate][name] = item.AMOUNT
  }
  if (all.length) {
    log('parseFinanceIncomeUS', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  let dates: string[] = Object.keys(dataMap).sort().reverse()
  for (let i=0;i<dates.length;i++) {
    if (!dataMap[dates[i]]) {
      continue
    }
    items.push(dataMap[dates[i]])
  }

  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceBalance(code: string, callback: (code: string) => void) {
  let cacheKey = `${code}-fsb`
  if (cache[cacheKey] != undefined) {
    callback(code)
    return
  }

  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    fetchFinanceBalanceCN(cacheKey, code, callback)
  } else if (code.endsWith('.HK')) {
    fetchFinanceBalanceHK(cacheKey, code, callback)
  } else if (code.endsWith('.US')) {
    if (usCodeMap[code]) {
      fetchFinanceBalanceUS(cacheKey, code, callback)
    } else {
      fetchCompanyInfo(code, (data: any)=>{
        fetchFinanceBalanceUS(cacheKey, code, callback)
      })
    }
  } else {
    log('fetchFinanceIncome,wrong code', code)
  }
}

function fetchFinanceBalanceCN(cacheKey: string, code: string, callback: (code: string) => void): void {
  let type = 'GBALANCE'
  if (isCodeBank(code)) {
    type = 'BBALANCE'
  }
  let dates = genReportDates(11)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/get',
    params: {
      type: `RPT_F10_FINANCE_${type}`,
      sty: `F10_FINANCE_${type}`,
      filter: `(SECUCODE="${code}")(REPORT_DATE in ('${dates.join("','")}'))`,
      p: 1,
      ps: '',
      sr: -1,
      st: 'REPORT_DATE',
      source: 'HSF10',
      client: 'PC'
    }}, (data: any, cacheMiss: boolean)=>{
      if (cacheMiss) {
        parseFinanceBalanceCN(cacheKey, balanceKeys, 2, data.result.data, callback)
      } else {
        callback(code)
      }
    }
  )
}

function parseFinanceBalanceCN(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let items: any = []
  let all: string[][] = []
  for (let i=0; i < list.length; i++) {
    let item: any = {
      reportDate: list[i].REPORT_DATE.substring(0, 10),
      noticeDate: list[i].NOTICE_DATE.substring(0, 10),
    }

    for (let key in list[i]) {
      if (typeof list[i][key] != 'number' || key.endsWith('_YOY') || key.endsWith('_QOQ') || ignoreKeys.includes(key)) {
        continue
      }
      let name = key
      let it = keys.find((item)=> item[col] == name)
      if (it) {
        name = it[0]
      } else if (list[i][key] && item.reportDate > '2018') {
        all.push([name, key, item.reportDate, financeFormat(list[i][key])])
      }

      if (item[name]) {
        //name重复了
        log('parseFinanceBalanceCN,dup name', key, it)
      }
      
      item[name] = list[i][key]
    }
    items.push(item)
  }

  if (all.length) {
    log('parseFinanceBalanceCN', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceBalanceHK(cacheKey: string, code: string, callback: (code: string) => void): void {
  let dates = genReportDates(11)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    params: {
      reportName: 'RPT_HKF10_FN_BALANCE_PC',
      columns: 'SECUCODE,REPORT_DATE,STD_ITEM_CODE,STD_ITEM_NAME,AMOUNT',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")(REPORT_DATE in ('${dates.join("','")}'))'`,
      pageNumber: 1,
      pageSize: '',
      sortTypes: '-1,1',
      sortColumns: 'REPORT_DATE,STD_ITEM_CODE',
      source: 'F10',
      client: 'PC'
    }}, (data: any, cacheMiss)=>{
      if (cacheMiss)
        parseFinanceBalanceHK(cacheKey, balanceKeys, 3, data.result.data, callback)
      else 
        callback(code)
    })
}

function parseFinanceBalanceHK(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let dataMap: any = {}
  let items: any = []

  let all: string[][] = []
  for (let item of list) {
    if (ignoreKeys.includes(item.STD_ITEM_NAME)) {
      continue
    }
    let reportDate = item.REPORT_DATE.substring(0, 10)
    if (!dataMap[reportDate]) {
      dataMap[reportDate] = {reportDate}
    }
    let name = item.STD_ITEM_NAME
    let key = keys.find((item)=> item[col] == name)
    if (key) {
      name = key[0]
    } else if (item.AMOUNT && reportDate > '2018') {
      all.push([name, item.STD_ITEM_NAME, reportDate, financeFormat(item.AMOUNT)])
    }
    if (dataMap[reportDate][name]) {
      //name重复了
      log('parseFinanceBalanceHK,dup name', item.STD_ITEM_NAME, key)
    }
    dataMap[reportDate][name] = item.AMOUNT
  }
  if (all.length) {
    log('parseFinanceBalanceHK', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  let dates: string[] = Object.keys(dataMap).sort().reverse()
  for (let i=0;i<dates.length;i++) {
    if (!dataMap[dates[i]]) {
      continue
    }
    items.push(dataMap[dates[i]])
  }

  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceBalanceUS(cacheKey: string, code: string, callback: (code: string) => void): void {
  let dates = genReportDatesUS(11, false)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    params: {
      reportName: 'RPT_USF10_FN_BALANCE',
      columns: 'SECUCODE,REPORT,REPORT_DATE,STD_ITEM_CODE,ITEM_NAME,AMOUNT',
      quoteColumns: '',
      filter: `(SECUCODE="${usCodeMap[code]}")(REPORT in ("${dates.join('","')}"))'`,
      pageNumber: '',
      pageSize: '',
      sortTypes: '1,-1',
      sortColumns: 'STD_ITEM_CODE,REPORT_DATE',
      source: 'SECURITIES',
      client: 'PC'
    }}, (data: any, cacheMiss) => {
      if(cacheMiss)
        parseFinanceBalanceUS(cacheKey, balanceKeys, 4, data.result.data, callback)
      else 
        callback(code)
    })
}

function parseFinanceBalanceUS(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let dataMap: any = {}
  let items: any = []

  let all: string[][] = []
  for (let item of list) {
    if (ignoreKeys.includes(item.ITEM_NAME)) {
      continue
    }
    let reportDate = item.REPORT_DATE.substring(0, 10)
    if (!dataMap[reportDate]) {
      dataMap[reportDate] = {reportDate}
    }

    let name = item.ITEM_NAME
    let key = keys.find((item)=> item[col] == name)
    if (key) {
      name = key[0]
    } else if (item.AMOUNT && reportDate > '2018') {
      all.push([name, item.ITEM_NAME, reportDate, financeFormat(item.AMOUNT)])
    }
    if (dataMap[reportDate][name]) {
      //name重复了
      log('parseFinanceBalanceUS,dup name', item.ITEM_NAME, key)
    }
    dataMap[reportDate][name] = item.AMOUNT
  }

  if (all.length) {
    log('parseFinanceBalanceUS', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  let dates: string[] = Object.keys(dataMap).sort().reverse()
  for (let i=0;i<dates.length;i++) {
    if (!dataMap[dates[i]]) {
      continue
    }
    items.push(dataMap[dates[i]])
  }

  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceCashflow(code: string, callback: (code: string) => void) {
  let cacheKey = `${code}-fsc`
  if (cache[cacheKey] != undefined) {
    callback(code)
    return
  }

  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    fetchFinanceCashflowCN(cacheKey, code, callback)
  } else if (code.endsWith('.HK')) {
    fetchFinanceCashflowHK(cacheKey, code, callback)
  } else if (code.endsWith('.US')) {
    if (usCodeMap[code]) {
      fetchFinanceCashflowUS(cacheKey, code, callback)
    } else {
      fetchCompanyInfo(code, (data: any)=>{
        fetchFinanceCashflowUS(cacheKey, code, callback)
      })
    }
  } else {
    log('fetchFinanceIncome,wrong code', code)
  }
}

function fetchFinanceCashflowCN(cacheKey: string, code: string, callback: (code: string) => void): void {
  let type = 'GCASHFLOWQC'
  if (isCodeBank(code)) {
    type = 'BCASHFLOWQC'
  }
  let dates = genReportDates(11)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/get',
    params: {
      type: `RPT_F10_FINANCE_${type}`,
      sty: `APP_F10_${type}`,
      filter: `(SECUCODE="${code}")(REPORT_DATE in ('${dates.join("','")}'))`,
      p: 1,
      ps: '',
      sr: -1,
      st: 'REPORT_DATE',
      source: 'HSF10',
      client: 'PC'
    }}, (data: any, cacheMiss) => {
      if (cacheMiss)
        parseFinanceCashflowCN(cacheKey, cashflowKeys, 2, data.result.data, callback)
      else
        callback(code)
    })
}

function parseFinanceCashflowCN(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let items: any = []
  let unknown: string[][] = []
  for (let i=0; i < list.length; i++) {
    let item: any = {reportDate: list[i].REPORT_DATE.substring(0, 10),
      noticeDate: list[i].NOTICE_DATE.substring(0, 10)
    }
    for (let key in list[i]) {
      if (typeof list[i][key] != 'number' || key.endsWith('_YOY') || key.endsWith('_QOQ') || ignoreKeys.includes(key)) {
        continue
      }
      let name = key
      let it = keys.find((item)=> item[col] == name)
      if (it) {
        name = it[0]
      } else if (list[i][key] && item.reportDate > '2018') {
        unknown.push([name, key, item.reportDate, financeFormat(list[i][key])])
      }

      if (item[name]) {
        //name重复了
        log('parseFinanceCashflowCN,dup name', key, it)
      }
      item[name] = list[i][key]
    }
    items.push(item)
  }
  if (unknown.length) {
    log('parseFinanceCashflowCN', JSON.stringify(unknown.filter((item, index)=> index == unknown.findIndex(o=> o[1] == item[1])), null, 1))
  }
  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceCashflowHK(cacheKey: string, code: string, callback: (code: string) => void): void {
  let dates = genReportDates(11)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    params: {
      reportName: 'RPT_HKF10_FN_CASHFLOW_PC',
      columns: 'SECUCODE,REPORT_DATE,STD_ITEM_CODE,STD_ITEM_NAME,AMOUNT',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")(REPORT_DATE in ('${dates.join("','")}'))'`,
      pageNumber: 1,
      pageSize: '',
      sortTypes: '-1,1',
      sortColumns: 'REPORT_DATE,STD_ITEM_CODE',
      source: 'F10',
      client: 'PC'
    }}, (data: any, cacheMiss: boolean) => {
      if (cacheMiss)
        parseFinanceCashflowHK(cacheKey, cashflowKeys, 3, data.result.data, callback)
      else
        callback(code)
    })
}

function parseFinanceCashflowHK(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let dataMap: any = {}
  let items: any = []
  let all: string[][] = []
  for (let item of list) {
    if (ignoreKeys.includes(item.STD_ITEM_NAME)) {
      continue
    }
    let reportDate = item.REPORT_DATE.substring(0, 10)
    if (!dataMap[reportDate]) {
      dataMap[reportDate] = {reportDate}
    }
    let name = item.STD_ITEM_NAME
    let key = keys.find((item)=> item[col] == name)
    if (key) {
      name = key[0]
    } else if (item.AMOUNT && reportDate > '2018') {
      all.push([name, item.STD_ITEM_NAME, reportDate, financeFormat(item.AMOUNT)])
    }
    if (dataMap[reportDate][name]) {
      //name重复了
      log('parseFinanceCashflowHK,dup name', item.STD_ITEM_NAME, key)
    }
    dataMap[reportDate][name] = item.AMOUNT
  }
  if (all.length) {
    log('parseFinanceCashflowHK', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  let dates: string[] = Object.keys(dataMap).sort().reverse()
  for (let i=0;i<dates.length;i++) {
    if (!dataMap[dates[i]]) {
      continue
    }
    let month = dates[i].substring(5, 7)
    switch (month) {
      case '03':
        items.push(dataMap[dates[i]])
        break
      case '06':
      case '09':
      case '12':
        if (i < dates.length-1) {
          let item: any = {}
          for (let key in dataMap[dates[i]]) {
            if (key != 'reportDate' && dates[i].substring(0, 4) == dates[i+1].substring(0, 4)) {
              //算当季的
              item[key] = dataMap[dates[i]][key] - dataMap[dates[i+1]][key]
            } else {
              item[key] = dataMap[dates[i]][key]
            }
          }
          items.push(item)
        }
        break
      default:
        console.log('wrong report date', month)
        break
    }
  }

  cache[cacheKey] = items
  callback(code)
}

function fetchFinanceCashflowUS(cacheKey: string, code: string, callback: (code: string) => void): void {
  let dates = genReportDatesUS(11, true)
  fetchRequest({
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    params: {
      reportName: 'RPT_USSK_FN_CASHFLOW',
      columns: 'SECUCODE,REPORT,REPORT_DATE,STD_ITEM_CODE,ITEM_NAME,AMOUNT',
      quoteColumns: '',
      filter: `(SECUCODE="${usCodeMap[code]}")(REPORT in ("${dates.join('","')}"))'`,
      //filter: `(SECUCODE="BABA.N")(REPORT in ("2023/Q1","2022/Q4","2022/Q3","2022/Q2","2022/Q1","2021/Q4"))`,
      pageNumber: '',
      pageSize: '',
      sortTypes: '1,-1',
      sortColumns: 'STD_ITEM_CODE,REPORT_DATE',
      source: 'SECURITIES',
      client: 'PC'
    }}, (data: any, cacheMiss: boolean) => {
      if (cacheMiss)
        parseFinanceCashflowUS(cacheKey, cashflowKeys, 4, data.result.data, callback)
      else
        callback(code)
    })
}

function parseFinanceCashflowUS(cacheKey: string, keys: string[][], col: number, list: any, callback: (code: string) => void) {
  let dataMap: any = {}
  let items: any = []

  let all: string[][] = []
  for (let item of list) {
    if (ignoreKeys.includes(item.ITEM_NAME)) {
      continue
    }
    let reportDate = item.REPORT_DATE.substring(0, 10)
    if (!dataMap[reportDate]) {
      dataMap[reportDate] = {reportDate}
    }

    let name = item.ITEM_NAME
    let key = keys.find((item)=> item[col] == name)
    if (key) {
      name = key[0]
    } else if (item.AMOUNT && reportDate > '2018') {
      all.push([name, item.ITEM_NAME, reportDate, financeFormat(item.AMOUNT)])
    }
    if (dataMap[reportDate][name]) {
      //name重复了
      log('parseFinanceCashflowUS,dup name', item.ITEM_NAME, key)
    }
    dataMap[reportDate][name] = item.AMOUNT
  }
  if (all.length) {
    log('parseFinanceCashflowUS', JSON.stringify(all.filter((item, index)=> index == all.findIndex(o=> o[1] == item[1])), null, 1))
  }
  let dates: string[] = Object.keys(dataMap).sort().reverse()
  for (let i=0;i<dates.length;i++) {
    if (!dataMap[dates[i]]) {
      continue
    }
    items.push(dataMap[dates[i]])
  }

  cache[cacheKey] = items
  callback(code)
}

function fetchCurrency(code: string, callback: (data: any) => void) {
  code = code.toUpperCase()
  const keyMap: any = {
    'HKD': 'HKDCNYC',
    'USD': 'USDCNYC'
  }

  if (!keyMap[code]) {
    log('fetchCurrency,wrong code', code)
    return
  }

  if (code == 'CNY') {
    callback(1)
    return
  }

  let cacheKey = `${code}-c`
  let ts = Date.now()
  fetchRequest({
    url: 'https://97.push2.eastmoney.com/api/qt/clist/get',
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 3600,
    params: {
      cb: `jQuery112407377991348119062_${ts}`,
      pn: 1,
      pz: 100,
      po: 0,
      np: 1,
      ut: 'bd1d9ddb04089700cf9c27f6f7426281',
      fltt: 2,
      invt: 2,
      wbp2u: '|0|0|0|web',
      fid: 'f2',
      fs: 'm:120 t:!2,m:133',
      fields: 'f1,f2,f3,f4,f5,f6,f7,f8,f9,f10,f12,f13,f14,f15,f16,f17,f18,f20,f21,f23,f24,f25,f22,f11,f62,f128,f136,f115,f152',
      _: ts
    }
  }, (data: any, cacheMiss: boolean)=> {
    if (cacheMiss) {
      parseCurrencyData(cacheKey, keyMap[code], data, callback)
    } else {
      callback(data)
    }
  })
}

function parseCurrencyData(cacheKey: string, key: string, json: any, callback: (data: any)=>void) {
  for (let item of json.data.diff) {
    if (item.f12 == key) {
      cache[cacheKey] = item.f2
      callback(item.f2)
      return
    }
  }

  log('parseCurrencyData,not found', key)
}

function fetchKline(code: string, fq: string, success: Callback) {
  const cacheKey = `${code}${fq}`
  if (cache[cacheKey]) {
    success(cache[cacheKey])
    return
  }

  const callback = (data: any)=>{
    cache[cacheKey] = data
    success(data)
  }

  if (!fq) {
    fq = 'before'
  }

  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')  || code.endsWith('.SF')  || code.endsWith('.ZF') || code.endsWith('.HK') || code.endsWith('.US') || code.endsWith('.O') || code.endsWith('.N') || code.endsWith('.AF')) {
    fetchStockKlineXueqiu(code, fq, callback)
  } else if (code.endsWith('.ZI') || code.endsWith('.SI') || code.endsWith('.HI') || code.endsWith('.SO') || code.endsWith('.ZO')) {
    fetchStockKline(code, fq, callback)
  } else if (code.endsWith('.OF')) {
    beginFetchFundValueList(code, fq, callback)
  } else if (code.endsWith('.DC')) {
    fetchYahooSymbolKline(code, callback)
  } else {
    log('kline wrong code', code)
  }
}

//获取雅虎kline
function fetchYahooSymbolKline(code: string, callback: Callback): void {
  const cacheKey = `fetchYahooSymbolKline-${code}`
  //https://cointelegraph.com/bitcoin-price
  let endTs = Math.floor(Date.now()/1000) + 86400
  let symbol = code.split('.')[0]
  fetchRequest({
    url: `https://query1.finance.yahoo.com/v8/finance/chart/${symbol}`,
    proxy: true,
    relay: true,
    cacheKey: cacheKey,
    cacheTtl: 600,
    headers: {
      referer: "https://finance.yahoo.com/"
    },
    params: {
      symbol: symbol,
      period1: 908726400,
      period2: endTs,
      useYfid: true,
      interval: '1d',
      includePrePost: true,
      events: 'div|split|earn',
      lang: 'en-US',
      region: 'US',
      crumb: 'XlIaT1JQbvv.',
      corsDomain: 'finance.yahoo.com'
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      data = data.chart.result[0]
      let quote = data.indicators.quote[0]
      let result: any = []
      for (let i=0; i< data.timestamp.length;i++) {
        //时间戳、收盘、开盘、最低、最高、数量
        result.push([Math.floor(data.timestamp[i]/(3600*24))*3600*24*1000,
          quote.close[i]?quote.close[i]: quote.close[i-1],
          quote.open[i]?quote.open[i]: quote.open[i-1],
          quote.low[i]?quote.low[i]: quote.low[i-1],
          quote.high[i]?quote.high[i]: quote.high[i-1],
          quote.volume[i]?quote.volume[i]: 0,
        ])
      }
      cache[cacheKey] = result
      callback(result)
    } else {
      callback(data)
    }
  })
}

function fetchYahooOption(code: string, date: string, callback: Callback): void {
  const cacheKey = `fetchYahooOption-${code}-${date}`
  //https://cointelegraph.com/bitcoin-price
  let endTs = Math.floor(Date.now()/1000) + 86400
  let symbol = code.split('.')[0]
  fetchRequest({
    url: `https://query1.finance.yahoo.com/v7/finance/options/${symbol}`,
    proxy: true,
    relay: true,
    cacheKey: cacheKey,
    cacheTtl: 600,
    headers: {
      referer: `https://finance.yahoo.com/quote/${symbol}/options?p=${symbol}`,
      cookie: 'A1=d=AQABBNDetmUCEByCahzDZxh91dcXoqP7cQMFEgEBAQEwuGXAZWChyyMA_eMAAA&S=AQAAApmZVJo3Sw5TCYmNc6lPLak; A3=d=AQABBNDetmUCEByCahzDZxh91dcXoqP7cQMFEgEBAQEwuGXAZWChyyMA_eMAAA&S=AQAAApmZVJo3Sw5TCYmNc6lPLak; A1S=d=AQABBNDetmUCEByCahzDZxh91dcXoqP7cQMFEgEBAQEwuGXAZWChyyMA_eMAAA&S=AQAAApmZVJo3Sw5TCYmNc6lPLak; cmp=t=1706483412&j=0&u=1---; gpp=DBAA; gpp_sid=-1; PRF=t%3DTSLA%26newChartbetateaser%3D0%252C1707693011966; gam_id=y-VVtnS09E2uIslnMc_7GUwLR_KTyymHSB~A; axids=gam=y-VVtnS09E2uIslnMc_7GUwLR_KTyymHSB~A&dv360=eS16Sng1Mmo1RTJ1RnBqWVo1MldBSVYuSHVQRnZBTVp6dH5B&ydsp=y-Fh4jsoFE2uLMhXU5NELfcJLJ3iu5mLSn~A; tbla_id=19a1189c-78dc-42c0-94c2-be3ce78757cd-tuctcb06455; __eoi=ID=8e9e513a11c5bdeb:T=1706483681:RT=1706483681:S=AA-Afja8PaPI4Y9C9pu2z1ESyGz8; __gpi=UID=00000cf2f5f21745:T=1706483413:RT=1706483713:S=ALNI_MbdBRvfBv8m4zU1R_3QqYOMlTjE3Q'
    },
    params: {
      formatted: true,
      crumb: 'RRUKdvYpCJf',
      lang: 'en-US',
      region: 'US',
      date: date,
      corsDomain: 'finance.yahoo.com'
    }
  }, (data: any, cacheMiss: boolean)=>{
    callback(data.optionChain.result[0])
  })
}

function fetchBitcoinKline(code: string, callback: Callback): void {
  const cacheKey = `fetchBitcoinKline-${code}`
  //https://www.coindesk.com/price/bitcoin/
  let ts = Math.floor(Date.now()/1000) + 86400
  let symbol = code.split('.')[0]
  fetchRequest({
    url: `ttps://production.api.coindesk.com/v2/tb/price/values/${code}`,
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 600,
    params: {
      start_date: '2023-02-25T23:50',
      end_date: '2023-12-24T00:00',
      interval: '1d',
      ohlc: true
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      let result: any = []
      for (let item of data.data[symbol].USD.INDEX) {
        //原始：时间戳、开盘、最高、最低、收盘
        //转换成：时间戳、价格、开盘、最高、最低、数量
        result.push([item[0], item[4], item[1], item[2], item[3], 1])
      }
      cache[cacheKey] = result
      callback(result)
    } else {
      callback(data)
    }
  })
}

function fetchStockKlineXueqiu(code: string, fq: string, callback: Callback): void {
  let ts = alignTs(Date.now()) + 86400000
  let symbol = genXueqiuSymbol(code)
  fetchRequest({
    url: 'https://stock.xueqiu.com/v5/stock/chart/kline.json',
    proxy: true,
    cookie: 'xueqiu',
    cacheKey: `fetchStockKlineXueqiu-${code}-${fq}`,
    cacheTtl: calcTradeCacheTtl(code),
    params: {
      symbol: symbol,
      begin: ts,
      period: "day",
      type: fq,
      count: -7500,
      indicator: 'kline,pe,pb,ps,pcf,market_capital,agt,ggt,balance'
    }
  }, (data: any)=>{
    parseStockKlineXueqiu(code, data, callback)
  })
}

function parseStockKlineXueqiu(code: string, bodyj: any, callback: Callback) {
  let data: number[][] = []
  for (let item of bodyj.data.item) {
    // "timestamp","volume","open","high","low","close","chg","percent","turnoverrate","amount","volume_post","amount_post","pe","pb","ps","pcf","market_capital","balance","hold_volume_cn","hold_ratio_cn","net_volume_cn","hold_volume_hk","hold_ratio_hk","net_volume_hk"
    //时间戳对齐到天
    let ts = Math.floor((item[0]+ 3600000*8)/(3600*1000*24))*3600*24*1000    
    //时间戳、收盘，开盘、最低、最高、成交量、成交额、市值、pe、pb、ps、pcf
    data.push([ts, item[5], item[2], item[4], item[3], item[1], item[9], item[16], item[12], item[13], item[14], item[15]])
  }

  callback(data)
}

function fetchStockKline(code: string, fq: string, callback: Callback): void {
  let fqt = 1; //前复权
  if (fq == "normal") {
    fqt = 0; //不复权
  } else if (fq == "after") {
    fqt = 2; //后复权
  }
  let begin = 19700101
  let ts = Date.now()
  let secid = trans2SecurityId(code)
  fetchRequest({
    url: 'http://6.push2his.eastmoney.com/api/qt/stock/kline/get',
    proxy: true,
    cacheKey: `fetchStockKline-${code}-${fq}`,
    cacheTtl: calcTradeCacheTtl(code),
    params: {
      cb: "jQuery3310013860444046051468_" + ts,
      secid: secid,
      ut: "fa5fd1943c7b386f172d6893dbfba10b",
      fields1: "f1,f2,f3,f4,f5",
      fields2: "f51,f52,f53,f54,f55,f56,f57,f58",
      klt: 101,
      fqt: fqt,
      beg: begin,
      end: 20500101,
      lmt: 10000,
      _: ts
    }
  }, (data: any)=>{
    parseStockKline(code, data, callback)
  })
}

function parseStockKline(code: string, bodyj: any, callback: Callback) {
  let data = []
  for (let item of bodyj.data.klines) {
    //日期，开盘、收盘、最高、最低、成交量、成交额
    let strs = item.split(",")
    //转换成：时间戳、收盘、开盘、最低、最高、成交量、成交额
    data.push([toTimestamp(strs[0]), parseFloat(strs[2]), parseFloat(strs[1]), parseFloat(strs[4]), parseFloat(strs[3]), parseInt(strs[5]), parseInt(strs[6])])
  }
  callback(data)
}


function beginFetchFundValueList(code: string, fq: string, callback: Callback) {
  let pageSize = 2000
  let result: any[] = []
  fetchFundValueList(code, fq, 1, pageSize, 0, result, callback)
}

function fetchFundValueList(code: string, fq: string, pageid: number, pageSize: number, preLjjz: number, result: any[], callback: Callback) {
  let dt = new Date().getTime()
  let fundCode = code.split('.')[0]
  fetchRequest({
    url: 'http://api.fund.eastmoney.com/f10/lsjz',
    proxy: true,
    cacheKey: `fetchFundValueList-${code}-${fq}-${pageid}`,
    cacheTtl: calcTradeCacheTtl(code),
    headers: {
      Referer: 'https://fundf10.eastmoney.com/'
    },
    params: {
      callback: 'jQuery18308955019608524131_' + dt,
      fundCode: fundCode,
      pageIndex: pageid,
      pageSize: pageSize,
      startDate: "",
      endDate: "",
      _: dt
    }
  }, (data: any)=>{
    parseFundValuelist(code, data, fq, pageid, pageSize, preLjjz, result, callback)
  })
}

function parseFundValuelist(code: string, bodyj: any, fq: string, pageid: number, pageSize: number, preLjjz: number, result: any[], callback: Callback) {
  if (bodyj.Data == null || bodyj.Data.LSJZList == null) {
    log("parseFundValuelist,err,", code, bodyj)
    return
  }
  
  for (let item of bodyj.Data.LSJZList) {
    let ts = toTimestamp(item.FSRQ) //日期
    let dwjz = parseFloat(item.DWJZ) //单位净值
    let ljjz = parseFloat(item.LJJZ) //累计净值
    let value = dwjz
    if (fq == 'normal') {
      //不复权，直接取单位净值
      value = dwjz
    } else if (fq == '') {
      //前复权,按照分红再投资
      if (result.length > 0) {
        value = result[result.length - 1][1] * value / (value + preLjjz - ljjz)
      }
    } else {
      //后复权，取累计净值
      value = ljjz
    }
    result.push([ts, value])
    preLjjz = ljjz
  }

  let totalPages = Math.floor((bodyj.TotalCount + pageSize - 1) / pageSize)
  if (pageid < totalPages) {
    fetchFundValueList(code, fq, pageid + 1, pageSize, preLjjz, result, callback)
    return
  }

  callback(result.reverse())
}

function fetchKlines(codes: string[], fq: string, callback: CodesCallback) {
  let idx = codes.indexOf('HSTECH.HK')
  if (idx > -1) {
    codes.splice(idx, 1)
  }
  let should = codes.length
  let done = 0
  let success = function () {
    done++
    if (done == should) {
      callback(codes)
    }
  }

  for (let code of codes) {
    fetchKline(code, fq, success)
  }
}

function fetchIndexWeight(code: string, callback: Callback): void {
  fetchRequest({
    url: `${server}/index/weight?code=${code}`,
  }, (data: any)=>{
    callback(data)
  })
}

function fetchIndexPositionDates(code: string, callback: (code: string, dates: number[]) => void) {
  fetchRequest({
    url: `${server}/index/positionDates?code=${code}`,
  }, (data: any)=>{
    callback(code, data)
  })
}

function fetchIndexPosition(code: string, date: number, callback: (code: string, date: number) => void) {
  let cachekey = `${code}-ip-${date}`
  fetchRequest({
    url: `${server}/index/position?code=${code}&date=${date}`,
    cachekey: cachekey,
    cacheTtl: 360000
  }, (data: any)=>{
    callback(code, date)
  })
}

function fetchIndexPositions(code: string, dates: number[], callback: (error: Error | null, code: string, dates: number[]) => void) {
  let should = dates.length
  let done = 0
  let success = function (code: string, date: number) {
    done++
    if (done == should) {
      callback(null, code, dates)
    }
  }

  for (let date of dates) {
    fetchIndexPosition(code, date, success)
  }
}

function fetchFundPosition(code: string, num: number, callback: (code: string) => void) {
  let cacheKey = `${code}-fp`
  fetchRequest({
    url: `${server}/fund/position?code=${code}&num=${num}`,
    cacheKey: cacheKey,
    cacheTtl: 360000
  }, (data: any)=>{
    callback(code)
  })
}

// 基金重仓股分析
function fetchFundPositions(codes: string[], num: number, callback: (error: Error | null, codes: string[]) => void) {
  let should = codes.length
  let done = 0
  let success = function (code: string) {
    done++
    if (done == should) {
      callback(null, codes)
    }
  }

  for (let code of codes) {
    fetchFundPosition(code, num, success)
  }
}

function fetchShareChanges(codes: string[], callback: CodesCallback) {
  let should = codes.length
  let done = 0
  let success = function (data: any) {
    done++
    if (done == should) {
      callback(codes)
    }
  }

  for (let code of codes) {
    fetchShareChange(code, success)
  }
}

function fetchShareChange(code: string, callback: Callback) {
  let cacheKey = `${code}-sc`
  if (code.endsWith('.SZ') || code.endsWith('.SH')|| code.endsWith('.BJ')) {
    fetchShareChangeCN(cacheKey, code, callback)
  } else if (code.endsWith('.HK')) {
    fetchShareChangeHK(cacheKey, code, callback)
  } else if (code.endsWith('.US')) {
    if (usCodeMap[code]) {
      fetchShareChangeUS(cacheKey, code, callback)
    } else {
      fetchCompanyInfo(code, (data: any)=>{
        fetchShareChangeUS(cacheKey, code, callback)
      })
    }
  }  else {
    log('wrong code', code)
  }
}

function fetchShareChangeCN(cacheKey: string, code: string, callback: Callback) {
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 360000,
    params: {
      reportName: 'RPT_F10_EH_EQUITY',
      columns: 'SECUCODE,SECURITY_CODE,END_DATE,TOTAL_SHARES,LIMITED_SHARES,LIMITED_OTHARS,LIMITED_DOMESTIC_NATURAL,LIMITED_STATE_LEGAL,LIMITED_OVERSEAS_NOSTATE,LIMITED_OVERSEAS_NATURAL,UNLIMITED_SHARES,LISTED_A_SHARES,B_FREE_SHARE,H_FREE_SHARE,FREE_SHARES,LIMITED_A_SHARES,NON_FREE_SHARES,LIMITED_B_SHARES,OTHER_FREE_SHARES,LIMITED_STATE_SHARES,LIMITED_DOMESTIC_NOSTATE,LOCK_SHARES,LIMITED_FOREIGN_SHARES,LIMITED_H_SHARES,SPONSOR_SHARES,STATE_SPONSOR_SHARES,SPONSOR_SOCIAL_SHARES,RAISE_SHARES,RAISE_STATE_SHARES,RAISE_DOMESTIC_SHARES,RAISE_OVERSEAS_SHARES,CHANGE_REASON',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")`,
      pageNumber: 1,
      pageSize: 5000,
      sortTypes: -1,
      sortColumns: 'END_DATE',
      source: 'HSF10',
      client: 'PC'
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      parseShareChangeCN(cacheKey, code, data, callback)
    } else {
      callback(data)
    }
  })
}

function parseShareChangeCN(cacheKey: string, code: string, bodyj: any, callback: Callback) {
  //历年股本变动
  let items: any = []
  let data = bodyj.result.data
  let changeRatio: number|string = 0
  for (let i=0;i < data.length;i++) {
    if (i < data.length-1) {
      changeRatio = (100*data[i].TOTAL_SHARES/data[i+1].TOTAL_SHARES - 100).toFixed(4)
    } else {
      changeRatio = 0
    }
    items.push({
      totalShares: data[i].TOTAL_SHARES,
      changeDate: data[i].END_DATE.substring(0, 10),
      changeTs: toTimestamp(data[i].END_DATE),
      changeReason: data[i].CHANGE_REASON,
      changeRatio: changeRatio,
      freeShares: data[i].FREE_SHARES,
      limitedAShares: data[i].LIMITED_A_SHARES,
      limitedDomesticNatural: data[i].LIMITED_DOMESTIC_NATURAL,
      limitedDomesticNostate: data[i].LIMITED_DOMESTIC_NOSTATE,
      limitedForeignShares: data[i].LIMITED_FOREIGN_SHARES,
      limitedOthers: data[i].LIMITED_OTHARS,
      limitedOverseasNatural: data[i].LIMITED_OVERSEAS_NATURAL,
      limitedOverseasNostate: data[i].LIMITED_OVERSEAS_NOSTATE,
      limitedShares: data[i].LIMITED_SHARES,
      limitedStateLegal: data[i].LIMITED_STATE_LEGAL,
      limitedStateShares: data[i].LIMITED_STATE_SHARES,
      listedAShares: data[i].LISTED_A_SHARES,
      nonFreeShares: data[i].NON_FREE_SHARES,
      otherFreeShares: data[i].OTHER_FREE_SHARES,
      raiseDomesticShares: data[i].RAISE_DOMESTIC_SHARES,
      raiseShares: data[i].RAISE_SHARES,
      raiseStateShares: data[i].RAISE_STATE_SHARES,
      sponsor: data[i].SPONSOR_SHARES,
      unlimitedShares: data[i].UNLIMITED_SHARES
    })
  }
  cache[cacheKey] = items
  callback(items)
}

function fetchShareChangeHK(cacheKey: string, code: string, callback: Callback) {
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 360000,
    params: {
      reportName: 'RPT_HKF10_INFO_EQUITY',
      columns: 'SECUCODE,CHANGE_DATE,HK_SHARES,CHANGE_REASON,NOTICE_DATE',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")`,
      pageNumber: 1,
      pageSize: 200,
      sortTypes: -1,
      sortColumns: 'CHANGE_DATE',
      source: 'F10',
      client: 'PC'
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      parseShareChangeHK(cacheKey, code, data, callback)
    } else {
      callback(data)
    }
  })
}

function parseShareChangeHK(cacheKey: string, code: string, bodyj: any, callback: Callback) {
  //历年股本变动
  let items: any = []
  let data = bodyj.result.data
  let changeRatio: number|string = 0
  for (let i=0;i < data.length;i++) {
    if (i < data.length-1) {
      changeRatio = (100*data[i].HK_SHARES/data[i+1].HK_SHARES - 100).toFixed(4)
    } else {
      changeRatio = 0
    }
    items.push({
      totalShares: data[i].HK_SHARES,
      changeDate: data[i].CHANGE_DATE.substring(0, 10),
      changeTs: toTimestamp(data[i].CHANGE_DATE),
      noticeDate: data[i].NOTICE_DATE.substring(0, 10),
      changeReason: data[i].CHANGE_REASON,
      changeRatio: changeRatio
    })
  }

  cache[cacheKey] = items
  callback(items)
}

function fetchShareChangeUS(cacheKey: string, code: string, callback: Callback) {
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 360000,
    params: {
      reportName: 'RPT_US10_INFO_EQUITY',
      columns: 'SECUCODE,SECURITY_CODE,SECURITY_NAME_ABBR,SECURITY_INNER_CODE,ORG_CODE,CHANGE_DATE,ISSUED_COMMON_SHARES,ISSUED_PREFERRED_SHARES,CHANGE_REASON',
      quoteColumns: '',
      filter: `(SECUCODE="${usCodeMap[code]}")`,
      pageNumber: 1,
      pageSize: 200,
      sortTypes: -1,
      sortColumns: 'CHANGE_DATE',
      source: 'SECURITIES',
      client: 'PC'
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      parseShareChangeUS(cacheKey, code, data, callback)
    } else {
      callback(data)
    }
  })
}

function parseShareChangeUS(cacheKey: string, code: string, bodyj: any, callback: Callback) {
  //历年股本变动
  let items: any = []
  let data = bodyj.result.data
  let changeRatio: number|string = 0
  for (let i=0;i < data.length;i++) {
    if (i < data.length-1) {
      changeRatio = (100*data[i].ISSUED_COMMON_SHARES/data[i+1].ISSUED_COMMON_SHARES - 100).toFixed(4)
    } else {
      changeRatio = 0
    }
    items.push({
      totalShares: data[i].ISSUED_COMMON_SHARES,
      changeDate: data[i].CHANGE_DATE.substring(0, 10),
      changeTs: toTimestamp(data[i].CHANGE_DATE),
      changeReason: data[i].CHANGE_REASON,
      changeRatio: changeRatio
    })
  }

  cache[cacheKey] = items
  callback(items)
}

function fetchSharesBonus(codes: string[], callback: CodesCallback) {
  let should = codes.length
  let done = 0
  let success = function () {
    done++
    if (done == should) {
      callback(codes)
    }
  }

  for (let code of codes) {
    fetchShareBonus(code, success)
  }
}

//股票增发
function fetchShareAdditional(code: string, callback: Callback) {
  let cacheKey = `${code}-sa`
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    accept: 'json',
    params: {
      reportName: 'RPT_F10_DIVIDEND_SEO',
      columns: 'SECUCODE,SECURITY_CODE,SECURITY_NAME_ABBR,NOTICE_DATE,ISSUE_NUM,NET_RAISE_FUNDS,ISSUE_PRICE,ISSUE_WAY_EXPLAIN,REG_DATE,LISTING_DATE,RECEIVE_DATE',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")`,
      pageNumber: 1,
      pageSize: 100,
      sortTypes: -1,
      sortColumns: 'NOTICE_DATE',
      source: 'HSF10',
      client: 'PC'
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (data.result) {
      callback(data.result.data)
    } else {
      callback([])
    }
  })
}

function fetchShareBonus(code: string, callback: Callback) {
  let cacheKey = `${code}-bs`
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheKey: cacheKey,
    cacheTtl: calcFinanceCacheTtl(code),
    accept: 'json',
    params: {
      reportName: 'RPT_F10_DIVIDEND_MAIN',
      columns: 'SECUCODE,SECURITY_CODE,SECURITY_NAME_ABBR,NOTICE_DATE,IMPL_PLAN_PROFILE,ASSIGN_PROGRESS,EQUITY_RECORD_DATE,EX_DIVIDEND_DATE,PAY_CASH_DATE',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")`,
      pageNumber: 1,
      pageSize: 100,
      sortTypes: -1,
      sortColumns: 'NOTICE_DATE',
      source: 'HSF10',
      client: 'PC'
    }
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      parseShareBonus(cacheKey, code, data, callback)
    } else {
      callback(data)
    }
  })
}

function parseShareBonus(cacheKey: string, code: string, json: any, callback: Callback) {
  let items: any[] = []
  if (!json.result) {
    cache[cacheKey] = []
    callback(code)
    return
  }

  let arr = json.result.data
  for (let i = 0; i < arr.length; i++) {
    let give = 0, trans = 0, bonus = 0
    //10送3.50转5.00派3.00元(含税,扣税后2.40元)
    let text: string = arr[i].IMPL_PLAN_PROFILE
    if (!text || text == '不分配不转增') {
      //有可能为空，就是不分红
      continue
    }
    text = text.replace('元', '')
    if (text.indexOf('(') > -1) {
      let parts = text.split('(')
      text = parts[0]
    }
    if (text.indexOf('派') > -1) {
      let parts = text.split('派')
      bonus = Math.round(1000*parseFloat(parts[1]))/10000
    }
    if (text.indexOf('转') > -1) {
      let parts = text.split('转')
      trans = Math.round(10*parseFloat(parts[1]))/100
    }
    if (text.indexOf('送') > -1) {
      let parts = text.split('送')
      give = Math.round(10*parseFloat(parts[1]))/100
    }

    items.push({
      noticeDate: arr[i].NOTICE_DATE.substring(0, 10),  //最新公告日
      progress: arr[i].ASSIGN_PROGRESS,
      divDate: arr[i].EX_DIVIDEND_DATE?.substring(0, 10), //除权日
      recordDate:  arr[i].EQUITY_RECORD_DATE?.substring(0, 10),  //登记日
      //payDate:  arr[i].PAY_CASH_DATE.substring(0, 10),  //派息日
      plan: arr[i].IMPL_PLAN_PROFILE, //分红方案
      give: give, //送股
      trans: trans, //转股
      bonus: bonus //现金分红
    })
  }

  cache[cacheKey] = items
  callback(items)
}

function fetchEastmoneyOption(code: string, callback: (code: string) => void) {
  let cacheKey = `${code}-so`
  fetchRequest({
    url: `${server}/options/eastmoney?code=${code}`,
    cacheKey: cacheKey,
    cacheTtl: 360000
  }, (data: any)=>{
    callback(code)
  })
}

function fetchCompanyReports(code: string, page: number, callback: (code: string, page: number) => void) {
  let cacheKey = `${code}-${page}-cr`
  fetchRequest({
    url: `${server}/company/reports?token=${localStorage.getItem('token')}&code=${code}&page=${page}`,
    cacheKey: cacheKey,
    cacheTtl: 3600
  }, (data: any)=>{
    callback(code, page)
  })
}

function fetchReportUrl(qtype: string, code: number, callback: (url: string|null) => void) {
  let cacheKey = `${qtype}-${code}-ru`
  fetchRequest({
    url: `${server}/report/url?qtype=${qtype}&code=${code}`,
    cacheKey: cacheKey,
    cacheTtl: 360000
  }, (data: any)=>{
    callback(data)
  })
}

function fetchCompanyInfo(code: string, callback: Callback) {
  const cacheKey = `${code}-ci`
  const cb = (data: any)=>{
    if (!codeNameMap[code]) {
      codeNameMap[code] = data.shortName
      localStorage.setItem('codeNameMap', JSON.stringify(codeNameMap))
    }
    callback(data)
  }
  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    fetchkCompanyInfoCN(cacheKey, code, cb)
  } else if (code.endsWith('.HK')) {
    fetchCompanyInfoHK(cacheKey, code, cb)
  } else if (code.endsWith('.US')) {
    fetchCompanyInfoUS(cacheKey, code, cb)
  } else {
    log( `错误的公司代码:${code}`)
  }
}

function fetchkCompanyInfoCN(cacheKey: string, code: string,  callback: Callback) {
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheKey: cacheKey,
    cacheTtl: 3600*24,
    accept: 'json',
    params: {
      reportName: 'RPT_F10_BASIC_ORGINFO',
      columns: 'SECUCODE,SECURITY_CODE,SECURITY_NAME_ABBR,ORG_CODE,ORG_NAME,FOUND_DATE,ORG_NAME_EN,FORMERNAME,STR_CODEA,STR_NAMEA,STR_CODEB,STR_NAMEB,STR_CODEH,STR_NAMEH,SECURITY_TYPE,EM2016,TRADE_MARKET,INDUSTRYCSRC1,PRESIDENT,LEGAL_PERSON,SECRETARY,CHAIRMAN,SECPRESENT,INDEDIRECTORS,ORG_TEL,ORG_EMAIL,ORG_FAX,ORG_WEB,ADDRESS,REG_ADDRESS,PROVINCE,ADDRESS_POSTCODE,REG_CAPITAL,REG_NUM,EMP_NUM,TATOLNUMBER,LAW_FIRM,ACCOUNTFIRM_NAME,ORG_PROFILE,BUSINESS_SCOPE,TRADE_MARKETT,TRADE_MARKET_CODE,SECURITY_TYPEE,SECURITY_TYPE_CODE,EXPAND_NAME_ABBR,EXPAND_NAME_PINYIN',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")`,
      pageNumber: 1,
      pageSize: 1,
      sortTypes: '',
      sortColumns: '',
      source: 'HSF10',
      client: 'PC'
    }
  }, (data: any, cacheMiss)=>{
    if (cacheMiss) {
      data = data.result.data[0]
      let info = {
        name: data.ORG_NAME,//名称
        shortName: data.SECURITY_NAME_ABBR,//证券简称
        brief: data.ORG_PROFILE,//公司介绍
        establishDate: data.FOUND_DATE,//成立日期
        //ipoDate: data.ssrq,//上市日期
        //market: data.TRADE_MARKET,//交易所
        industry: data.EM2016 //	所属东财行业
      }
      cache[cacheKey] = info
      callback(info)
    } else {
      callback(data)
    }
  })
}

function fetchCompanyInfoHK(cacheKey: string, code: string,  callback: Callback) {
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheKey: cacheKey,
    cacheTtl: 3600*24,
    accept: 'json',
    params: {
      reportName: 'RPT_HKF10_INFO_ORGPROFILE',
      columns: 'SECUCODE,SECURITY_CODE,ORG_NAME,SECURITY_NAME_ABBR,ORG_EN_ABBR,BELONG_INDUSTRY,FOUND_DATE,CHAIRMAN,SECRETARY,ACCOUNT_FIRM,REG_ADDRESS,ADDRESS,YEAR_SETTLE_DAY,EMP_NUM,ORG_TEL,ORG_FAX,ORG_EMAIL,ORG_WEB,ORG_PROFILE,REG_PLACE',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")`,
      pageNumber: 1,
      pageSize: 1,
      sortTypes: '',
      sortColumns: '',
      source: 'F10',
      client: 'PC'
    }
  }, (data: any, cacheMiss)=>{
    if (cacheMiss) {
      data = data.result.data[0]
      let info = {
        name: data.ORG_NAME,//名称
        shortName: data.SECURITY_NAME_ABBR,//证券简称
        brief: data.ORG_PROFILE,//公司介绍
        establishDate: data.FOUND_DATE,//成立日期
        //ipoDate: data.ssrq,//上市日期
        //market: data.TRADE_MARKET,//交易所
        industry: data.BELONG_INDUSTRY //	所属行业
      }
      cache[cacheKey] = info
      callback(info)
    } else {
      callback(data)
    }
  })
}

function fetchCompanyInfoUS(cacheKey: string, code: string, callback: Callback): void {
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheTtl: 3600*24,
    cacheKey: cacheKey,
    accept: 'json',
    params: {
      reportName: 'RPT_USF10_INFO_ORGPROFILE',
      columns: 'SECUCODE,SECURITY_CODE,ORG_CODE,SECURITY_INNER_CODE,SECURITY_NAME_ABBR,ORG_NAME,ORG_EN_ABBR,BELONG_INDUSTRY,FOUND_DATE,CHAIRMAN,REG_PLACE,ADDRESS,EMP_NUM,ORG_TEL,ORG_FAX,ORG_EMAIL,ORG_WEB,ORG_PROFILE',
      quoteColumns: '',
      filter: `(SECURITY_CODE="${code.split('.')[0]}")`,
      pageNumber: 1,
      pageSize: 200,
      sortTypes: '',
      sortColumns: '',
      source: 'SECURITIES',
      client: 'PC'
    }
  }, (data: any, cacheMiss)=>{
    if (cacheMiss) {
      data = data.result.data[0]
      let info = {
        name: data.ORG_NAME,//名称
        shortName: data.SECURITY_NAME_ABBR,//证券简称
        brief: data.ORG_PROFILE,//公司介绍
        establishDate: data.FOUND_DATE,//成立日期
        ipoDate: data.LISTING_DATE,//上市日期
        market: data.TRADE_MARKET,//交易所
        secCode: data.SECUCODE //证券代码
      }
      cache[cacheKey] = info
      if (!usCodeMap[code]) {
        usCodeMap[code] = info.secCode
      }
      callback(info)
    } else {
      callback(data)
    }
  })
}

//十大流通股东
function fetchCompanyFreeHolders(code: string, callback: (code: string) => void) {
  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    fetchFreeHoldersCN(code, callback)
  } else if (code.endsWith('.HK')) {
    fetchFreeHoldersCN(code, callback)
  } else if (code.endsWith('.US')) {
    fetchFreeHoldersCN(code, callback)
  } else {
    log('错误的公司', code)
    return
  }
}

function fetchFreeHoldersCN(code: string, callback: (code: string) => void): void {
  let cacheKey = `${code}-cfh`
  let dates = genReportDates(2)
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheTtl: calcFinanceCacheTtl(code),
    cacheKey: cacheKey,
    accept: 'json',
    params: {
      reportName: 'RPT_F10_EH_FREEHOLDERS',
      columns: 'SECUCODE,SECURITY_CODE,END_DATE,HOLDER_RANK,HOLDER_NAME,HOLDER_TYPE,SHARES_TYPE,HOLD_NUM,FREE_HOLDNUM_RATIO,HOLD_NUM_CHANGE,CHANGE_RATIO',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")(END_DATE in ('${dates.join("','")}'))'`,
      pageNumber: 1,
      pageSize: '',
      sortTypes: '-1,1',
      sortColumns: 'END_DATE,HOLDER_RANK',
      source: 'HSF10',
      client: 'PC'
    }
  }, (data: any, cacheMiss)=>{
    if (cacheMiss) {
      cache[cacheKey] = data.result.data
    }
    
    callback(code)
  })
}

function fetchCompanyOrgHolders(code: string, reportDate: string, callback: (code: string, reportDate: string) => void) {
  if (code.endsWith('.SZ') || code.endsWith('.SH') || code.endsWith('.BJ')) {
    fetchOrgHoldersCN(code, reportDate, callback)
  } else if (code.endsWith('.HK')) {
    fetchOrgHoldersCN(code, reportDate, callback)
  } else if (code.endsWith('.US')) {
    fetchOrgHoldersCN(code, reportDate, callback)
  } else {
    log('错误的公司', code)
    return
  }
}

function fetchOrgHoldersCN(code: string, reportDate: string, callback: (code: string, reportDate: string) => void): void {
  let cacheKey = `${code}-${reportDate}-coh`
  fetchRequest({
    url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
    cacheTtl: calcFinanceCacheTtl(code),
    cacheKey: cacheKey,
    accept: 'json',
    params: {
      reportName: 'RPT_MAIN_ORGHOLDDETAIL',
      columns: 'ORG_TYPE,SECUCODE,REPORT_DATE,HOLDER_CODE,HOLDER_NAME,TOTAL_SHARES,HOLD_VALUE,TOTALSHARES_RATIO,FREESHARES_RATIO,FREE_MARKET_CAP,FREE_SHARES,SECURITY_CODE,FUND_CODE,FUND_DERIVECODE,NETVALUE_RATIO',
      quoteColumns: '',
      filter: `(SECUCODE="${code}")(ORG_TYPE="01")(REPORT_DATE='${reportDate}')`,
      pageNumber: 1,
      pageSize: 500,
      sortTypes: '-1',
      sortColumns: 'TOTAL_SHARES',
      source: 'HSF10',
      client: 'PC'
    }
  }, (data: any, cacheMiss)=>{
    if (cacheMiss) {
      cache[cacheKey] = data.result.data
    }
    callback(code, reportDate)
  })
}

function fetchIndexInfo(code: string, callback: (code: string) => void) {
  let cacheKey = `${code}-ii`
  fetchRequest(`${server}/index/info?code=${code}`, (data: any)=>{
    cache[cacheKey] = data
    callback(code)
  })
}

function fetchFundInfo(code: string, callback: (code: string) => void) {
  let cacheKey = `${code}-fi`
  fetchRequest({
    url: `${server}/fund/info?code=${code}`,
    cacheKey: cacheKey,
    cacheTtl: 360000
  }, (data: any)=>{
    callback(code)
  })
}

function getUrlParam(sParam: string) {
  let sPageURL = window.location.search.substring(1),
    sURLVariables = sPageURL.split('&'),
    sParameterName,
    i

  for (let i = 0; i < sURLVariables.length; i++) {
    sParameterName = sURLVariables[i].split('=')

    if (sParameterName[0] === sParam) {
      return typeof sParameterName[1] === undefined ? true : decodeURIComponent(sParameterName[1])
    }
  }
  return undefined
}

function coinSelectOnChange() {
  selectedCodes = $('#codes').val()
  if (selectedCodes.length == 0) {
    log('code none')
    return
  }

  for (let i=0;i< klineCodes.length;i++) {
    if (!selectedCodes.includes(klineCodes[i])) {
      klineCodes.splice(i)
    }
  }

  fetchKlines(selectedCodes, '', function (codes) {
    rerenderMyChart()
  })
}

//绘制财报柱状对比图
function genFinanceChart(id: string, codes: string[], yKeys: string[], yKeyNames: string[]) {
  // @ts-ignore
  let  seasons = parseInt(document.getElementById('seasons').value)
  const xKey = 'reportDate'
  let yUnit = '(亿元)'
  let unit = 1e8
  if (reportsMap[codes[0]][0][yKeys[0]] < 1e8) {
    unit = 1e6
    yUnit = '(百万)'
  }
  let data: any = {}
  for (let code of codes) {
    data[code] = []
    for (let i=0;i< reportsMap[code].length;i++) {
      data[code][i] = {}
      for (let key of [xKey, ...yKeys]) {
        if (key == 'reportDate') {
          data[code][i][key] = reportsMap[code][i][key]
        } else {
          data[code][i][key] = reportsMap[code][i][key]/unit
        }
      }
    }
  }
  genBarLineCompareChart(id, codes, data, codeNameMap, yKeys, yKeyNames, xKey, yUnit, seasons)
}

function getOffsetAndCompareText(data: any) {
  let offsetMap: any = {}
  let compareType: string = $('#compareType').dataset.id
  let compareText = '同比'
  //由于港股等有些研报是半年一发，所以同比数量也不一定是4，可能是2
  switch (compareType) {
    case 'yoy':
      compareText = '同比'
      break
    case 'qoq':
      compareText = '环比'
      break
    case 'yearly':
      compareText = '年比'
      break
    default:
      log('wrong compareType', compareType)
      break
  }

  for (let code in data) {
    if (compareType == 'yoy') {
      let months = parseInt(data[code][0].reportDate.substring(5, 7)) - parseInt(data[code][1].reportDate.substring(5, 7))
      if (months < 0) {
        months += 12
      }
      if (months == 3) {
        offsetMap[code] = 4
      } else if (months == 6) {
        offsetMap[code] = 2
      } else {
        log('wrong months', code, months)
        offsetMap[code] = 1
      }
    } else {
      offsetMap[code] = 1
    }
  }
  return {offsetMap, compareText}
}

//生成柱状图和折线图，柱状表示数量，折线表示同环比
function genBarLineCompareChart(id: string, codes: string[], data: any, codeNameMap: any, yKeys: string[], yKeyNames: string[], xKey: string, yUnit: string, seasons: number) {
  let {offsetMap, compareText} = getOffsetAndCompareText(data)
  let maxDate = ''
  let maxCode = ''
  let seasonsMap: any = {} //财报数
  for (let code of codes) {
    if (!maxDate || data[code][0][xKey] > maxDate) {
      maxDate = data[code][0][xKey]
      maxCode = code
    }
  }

  //计算每个code的数据季度数及offset
  for (let code of codes) {
    let codeSeasons = data[code].length
    if (codeSeasons > seasons) {
      codeSeasons = seasons
    }
    if (data[code][0][xKey] < maxDate) {
      seasonsMap[code] = codeSeasons - 1
    } else {
      seasonsMap[code] = codeSeasons
    }
  }

  let xAxisData: string[] = []
  for (let i = 0; i < seasonsMap[maxCode]; i++) {
    if (i >= data[maxCode].length) {
      //越界
      break
    }
    xAxisData.unshift(data[maxCode][i][xKey])
  }

  let legendData: string[] = []
  let series: any[] = []
  let seriesUnit: string[] = []
  for (let code of codes) {
    let num = yKeys.length
    let items: any[][] = []
    for (let j=0;j< num;j++) {
      items[j*2] = []
      items[j*2+1] = []
    }
    for (let i = 0; i < seasonsMap[code]; i++) {
      if (i >= data[maxCode].length) {
        //越界
        break
      }

      for (let j=0;j< num;j++) {
        let current = data[code][i][yKeys[j]]
        let pre = i+offsetMap[code] < data[code].length? data[code][i+offsetMap[code]][yKeys[j]]: 0
        let ratio = pre > 0 ? 100*current/pre - 100: 0
        items[j*2].unshift(current)
        items[j*2+1].unshift(ratio)
      }
    }

    for (let i = 0; i < num; i++) {
      let codeName = codeNameMap[code] ? codeNameMap[code]: code
      let name1 = `${codeName}-${yKeyNames[i]}`
      let name2 = `${codeName}-${yKeyNames[i]}${compareText}`
      legendData.push(name1, name2)
      seriesUnit.push(`${yUnit}`, '%')
      //seriesUnit.push('%')
      series.push({
        name: name1,
        type: 'bar',
        data: items[i*2]
      })
      series.push({
        name: name2,
        type: 'line',
        yAxisIndex: 1,
        data: items[i*2+1]
      })
    }
  }

  let yAxis: any[] = [
    {
      type: 'value',
      name: yUnit,
      axisLabel: {
        formatter: '{value}'
      }
    },
    {
      type: 'value',
      name: '百分比',
      axisLabel: {
        formatter: '{value}%'
      }
    }
  ]

  renderBarLineCombo(id, legendData, xAxisData, yAxis, series, seriesUnit)
}

function renderBarLineCombo(id: string, legendData: string[], xAxisData: string[], yAxis: any[], series: any[], seriesUnit: string[]) {
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom);
  myChart.setOption({
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross',
        crossStyle: {
          color: '#999'
        }
      },
      formatter: function (params: any, ticket: string, callback: (ticket: string, html: string) => void): string | HTMLElement | HTMLElement[] {
        let str = params[0].axisValue + '</br>'
        for (let i = 0; i < params.length; i++) {
          str += `${params[i].marker}${params[i].seriesName}: ${params[i].value.toFixed(2)}${seriesUnit[i]}</br>`
        }
        return str
      }
    },
    legend: {
      data: legendData
    },
    xAxis: [
      {
        type: 'category',
        data: xAxisData,
        axisPointer: {
          type: 'shadow'
        }
      }
    ],
    yAxis: yAxis,
    series: series
  })
}

function generateMarketTable(codes: string[]) {
  let rows: any[] = []
  for (let code of codes) {
    let kline = cache[code]
    let row = [`<a href="company.html?code=${code}" target="_blank">${code}</a>`, codeNameMap[code]]
    let high: any = {}
    let low: any = {}
    let lastDayTs = kline[kline.length - 1][0]
    for (let j = kline.length - 1; j >= 0; j--) {
      if (j == kline.length - 1) {
        row[2] = kline[j][1];
      } else if (j == kline.length - 2) {
        row[3] = (row[2] - kline[j][1]) * 100 / kline[j][1]
      }

      for (let k = 0; k < days.length; k++) {
        if (days[k] in high) { } else {
          high[days[k]] = 0;
        }
        if (days[k] in low) { } else {
          low[days[k]] = 9999999;
        }

        let ts = lastDayTs - 24 * 3600 * 1000 * days[k]
        if (ts < kline[j][0]) {
          if (kline[j][1] > high[days[k]]) {
            high[days[k]] = kline[j][1];
          }
          if (kline[j][1] < low[days[k]]) {
            low[days[k]] = kline[j][1];
          }
        } else if (row.length <= 4 + k * 2) {
          row.push((row[2] - high[days[k]]) * 100 / high[days[k]])
          row.push((row[2] - low[days[k]]) * 100 / low[days[k]])
        }
      }
    }

    for (let k = 2; k < row.length; k++) {
      if (k == 2 && row[k] < 10) {
        row[k] = parseFloat(row[k].toFixed(3))
      } else {
        row[k] = parseFloat(row[k].toFixed(2))
      }
    }

    //有些股票上市时间不够长，补齐
    for (let k = row.length; k < 4 + days.length * 2; k++) {
      row.push('-')
    }
    rows.push(row)
  }

  generateMarketDataTable(rows)
}

function generateMarketDataTable(rows: any[][]) {
  let html = `<thead class="table-info theadFix">
        <tr>
            <th scope="col">市场表现</th>
            <th scope="col">名称简称</th>
            <th scope="col">最新价</th>
            <th scope="col" class="sortable">最新涨幅(%)</th>`

  for (let i = 0; i < days.length; i++) {
    html += `<th scope="col" class="sortable ${i==2? 'asc': ''}">比${days[i]}日高(%)</th>
            <th scope="col" class="sortable">比${days[i]}日低(%)</th>`
  }

  html += `</tr>
    </thead>${genColorTbody(rows, (idx)=> idx > 2)}`
  bsTable('market', {data: html})
}

function codeInit() {
  let value = localStorage.getItem('codeNameMap')
  if (value != undefined) {
    //以代码变量定义为准
    codeNameMap = {...JSON.parse(value), ...codeNameMap}
  }

  // @ts-ignore
  let codeStr: string = getUrlParam('code')
  if (codeStr) {
    selectedCodes = codeStr.toUpperCase().split(',')
    code = selectedCodes[0]
    if (!code.endsWith('.US')) {
      removeOptionLink()
    }
    changeCodeSpecHref()

    let elem = document.getElementById('codeName')
    let callback = function (data: any) {
      //修改标题
      $('#title').textContent = codeNameMap[code]
      if (elem) {
        elem.textContent = `${codeNameMap[code]}(${code})`
      }
    }
  
    if (codeNameMap[code]) {
      //修改标题
      $('#title').textContent = codeNameMap[code]
      if (elem)
        elem.textContent = `${codeNameMap[code]}(${code})`
    } else {
      fetchCodeNames([code], callback)
    }

    if ($('#currentPrice')) {
      fetchKline(code, '', (data: any)=>{
        let currentPrice = data[data.length-1][1]
        $('#currentPrice').textContent = currentPrice
        let thisYear = new Date().getFullYear()
        let arr: any = []
        let em = $('#priceChange')
        let idx = data.length-2
        arr.push([em, idx])

        em = $('#ytdPriceChange')
        let ts = new Date(thisYear, 0, 1).getTime()
        idx = findTsIndex(data, ts)
        if (idx < 0) {
          idx = 0
        }
        arr.push([em, idx])

        em = $('#last2NowPriceChange')
        ts = new Date(thisYear-1, 0, 1).getTime()
        idx = findTsIndex(data, ts)
        if (idx < 0) {
          idx = 0
        }
        arr.push([em, idx])

        arr.map((item: any)=>{
          let changeRatio = 100*currentPrice/data[item[1]][1] - 100
          item[0].textContent = changeRatio.toFixed(2) + '%'
          if (changeRatio > 0) {
            item[0].classList.add('text-danger')
          } else if (changeRatio < 0) {
            item[0].classList.add('text-success')
          }
        })
        
        $('#marketCap').textContent = (data[data.length-1][7]/1e8).toFixed(2)
        let stockValuation = ''
        if (data[data.length-1][8]) {
          stockValuation += `<span class="px-1">PE: ${data[data.length-1][8].toFixed(2)}</span>`
        }
        if (data[data.length-1][9]) {
          stockValuation += `<span class="px-1">PB: ${data[data.length-1][9].toFixed(2)}</span>`
        }
        if (data[data.length-1][10]) {
          stockValuation += `<span class="px-1">PS: ${data[data.length-1][10].toFixed(2)}</span>`
        }
        if (data[data.length-1][11]) {
          stockValuation += `<span class="px-1">PCF: ${data[data.length-1][11].toFixed(2)}</span>`
        }
        if (data[data.length-1][8] && data[data.length-1][9]) {
          stockValuation += `<span class="px-1">ROE: ${(data[data.length-1][9]*100/data[data.length-1][8]).toFixed(2)}%</span>`
        }
        $('#stockValuation').innerHTML = stockValuation
      })
    }
  }
}

function removeOptionLink() {
  document.getElementsByName('codeSpec').forEach((elem)=>{
    if (elem.getAttribute('href')?.startsWith('company-option.html')){
      elem.classList.add('d-none')
    }
  })
}

function changeCodeSpecHref() {
  // @ts-ignore
  let codeStr: string = getUrlParam('code')
  let elements = document.getElementsByName('codeSpec')
  if (!codeStr || !elements || elements.length == 0) {
    return
  }
  for (let i = 0; i < elements.length; i++) {
    //@ts-ignore
    let href: string = elements[i].getAttribute("href")
    if (href.indexOf('?') > -1) {
      href = href.split("?")[0]
    }
    elements[i].setAttribute("href", `${href}?code=${codeStr}`)
  }
}

function updateIndexInfo(code: string) {
  let callback = function (code: string) {
    let cacheKey = `${code}-ii`
    if (codeNameMap[code] == undefined) {
      codeNameMap[code] = cache[cacheKey].name
      localStorage.setItem('codeNameMap', JSON.stringify(codeNameMap))
    }
    $('#codeName').text(`${codeNameMap[code]}(${code})`)
  }

  fetchIndexInfo(code, callback)
}

function renderFundInfoTable(code: string) {
  let cachekey = `${code}-fi`
  let info = cache[cachekey]
  codeNameMap[code] = info.name
  let html = '<tbody>'
  html += `<tr><td class="table-secondary">基金代码</td><td>${code}</td><td class="table-secondary">基金名称</td><td>${info.name}</td></tr>`
  html += `<tr><td class="table-secondary">基金经理</td><td>${info.manager}</td><td class="table-secondary">基金公司</td><td>${info.company}</td></tr>`
  html += `<tr><td class="table-secondary">基金成立日期</td><td>${info.beginDate}</td><td class="table-secondary">基金更新日期</td><td>${info.updateDate}</td></tr>`
  html += `<tr><td class="table-secondary">基金类型</td><td>${info.style}</td><td class="table-secondary">基金规模</td><td>${info.scale}</td></tr>`
  html == '</tbody>'
  $('#fundInfo').innerHTML = html
}

function fillIndexSelectPositionUpdateDates(dates: number[], selectIdx: number, tagid: string) {
  let html = ''
  let seleted = ' selected="selected"'
  let tag = ''
  for (let i = 0; i < dates.length; i++) {
    if (i == selectIdx) {
      tag = seleted
    } else {
      tag = ''
    }
    html += `<option value="${dates[i]}"${tag}>${date2String(dates[i])}</option>`
  }
  $(tagid).innerHTML = html
}

function fillSelectOptions(options: any[], selectValue: number, tagid: string) {
  let html = ''
  let seleted = ' selected'
  let tag = ''
  for (let i = 0; i < options.length; i++) {
    if (options[i].value == selectValue) {
      tag = seleted
    } else {
      tag = ''
    }
    html += `<option value="${options[i].value}"${tag}>${options[i].text}</option>`
  }
  //@ts-ignore
  document.getElementById(tagid).innerHTML = html
}

//基金持仓公司饼图
function genFundPositionPie(code: string, idx: number) {
  let pie: any[] = []
  let data = cache[`${code}-fp`]
  let other = 100
  let codes: string[] = []
  let percentMap: any = {}
  for (let position of data[idx].data) {
    let comapanyCode = genFullCode(position[0])
    codes.push(comapanyCode)
    let name = position[1]
    let value = position[2]
    percentMap[comapanyCode] = value
    pie.push({ name: name, value: value })
    other -= value
  }
  pie.push({ name: '其它', value: other.toFixed(2) })
  pie.sort((a, b) => b.value - a.value)
  echartsPie('positionPie', `${codeNameMap[code]}(${code})`, '占比', pie)

  fetchCodesData(codes, fetchCompanyInfo, (codes: string[]) => {
    let catPercentMap: any = {}
    for (let code of codes) {
      let cachekey = `${code}-ci`
      let industry = cache[cachekey].industry ? cache[cachekey].industry.split('-')[0]: '未知'
      if (catPercentMap[industry]) {
        catPercentMap[industry] += percentMap[code]
      } else {
        catPercentMap[industry] = percentMap[code]
      }
    }
    
    let pie: any[] = []
    let other = 100
    for (let cat in catPercentMap) {
      pie.push({ name: cat, value: catPercentMap[cat].toFixed(2) })
      other -= catPercentMap[cat]
    }
    pie.push({ name: '其它', value: other.toFixed(2) })
    pie.sort((a, b) => b.value - a.value)
    echartsPie('positionIndustryPie', `行业持仓占比`, '占比', pie)
  })
}

//指数持仓饼图
function genIndexPositionPie(positions: any[]) {
  let pie: any[] = []
  let other = 100
  let percentMap: any = {}
  //倒序
  positions.sort((a, b) => b.weight - a.weight)
  //持仓公司饼图
  for (let position of positions) {
    percentMap[position.code] = position.weight
    if (pie.length < 20) {
      //太多了图放不下
      pie.push({ name: position.name, value: position.weight.toFixed(2) })
      other -= position.weight
    }
  }
  if (other > 0.009) {
    pie.push({ name: '其它', value: other.toFixed(2) })
  }
  pie.sort((a, b) => b.value - a.value)
  echartsPie('positionPie', `${codeNameMap[code]}(${code})`, '占比', pie)

  //持仓行业饼图
  let catPercentMap: any = {}
  for (let position of positions) {
    if (catPercentMap[position.cat1] == undefined) {
      catPercentMap[position.cat1] = percentMap[position.code]
    } else {
      catPercentMap[position.cat1] += percentMap[position.code]
    }
  }

  pie = []
  other = 100
  for (let cat in catPercentMap) {
    pie.push({ name: cat, value: catPercentMap[cat].toFixed(2) })
    other -= catPercentMap[cat]
  }
  if (other > 0.009) {
    pie.push({ name: '其它', value: other.toFixed(2) })
  }
  pie.sort((a, b) => b.value - a.value)
  echartsPie('positionCatPie', `行业持仓占比`, '占比', pie)
}

function indexPositionCompare(code: string) {
  if (code == undefined) {
    log('indexPositionCompare,code == undefined')
    return
  }

  //@ts-ignore
  let date1: number = parseInt($('#reportDate1').value)
  //@ts-ignore
  let date2: number = parseInt($('#reportDate2').value)
  fetchIndexPositions(code, [date1, date2], function (err, code, dates) {
    let positionMap: any = {}
    let cacheKey = `${code}-ip-${date1}`
    genIndexPositionPie(cache[cacheKey])
    for (let position of cache[cacheKey]) {
      let code = position.code
      //代码、名称、本期权重、上期权重、权重变化
      positionMap[code] = []
      positionMap[code][0] = code
      positionMap[code][1] = position.name
      positionMap[code][2] = position.weight
      positionMap[code][3] = 0
      positionMap[code][4] = '新增'
    }

    cacheKey = `${code}-ip-${date2}`
    for (let position of cache[cacheKey]) {
      let code = position.code
      //代码、名称、本期权重、上期权重、权重变化
      if (code in positionMap) {
        //新持仓也有
        positionMap[code][3] = position.weight
        positionMap[code][4] = (positionMap[code][2] - positionMap[code][3]).toFixed(2)
      } else {
        positionMap[code] = []
        positionMap[code][0] = code
        positionMap[code][1] = position.name
        positionMap[code][2] = 0
        positionMap[code][3] = position.weight
        positionMap[code][4] = '剔除'
      }
    }
    generateIndexPositionCompareTable(positionMap, date1, date2)
  })
}

function fundPositionCompare() {
  //@ts-ignore
  let p1 = parseInt(document.getElementById('reportDate1').value)
  if (!p1) {
    p1 = 0
  }
  //@ts-ignore
  let p2 = parseInt(document.getElementById('reportDate2').value)
  if (!p2) {
    p2 = 1
  }

  fetchFundPosition(code, 12, function (code) {
    genFundPositionPie(code, p1)
    let data = cache[`${code}-fp`]
    
    //@ts-ignore
    if (!document.getElementById('reportDate1').value) {
      //下拉填充
      let options: any[] = []
      for (let i=0;i<data.length;i++) {
        options.push({value: i, text: data[i].updateDate})
      }
      fillSelectOptions(options, p1, 'reportDate1')
      fillSelectOptions(options, p2, 'reportDate2')
    }
    
    let positionMap: any = {}
    for (let position of data[p1].data) {
      let code = position[0]
      positionMap[code] = []
      positionMap[code][0] = position[0]
      positionMap[code][1] = position[1]
      positionMap[code][2] = position[2]
      positionMap[code][3] = 0
      positionMap[code][4] = position[2]
      positionMap[code][5] = position[3]
      positionMap[code][6] = 0
      positionMap[code][7] = '新进'
    }

    for (let position of data[p2].data) {
      let code = position[0]
      if (code in positionMap) {
        //新季度报有，净值占比
      } else {
        positionMap[code] = []
        positionMap[code][0] = position[0]
        positionMap[code][1] = position[1]
        positionMap[code][2] = 0
        positionMap[code][5] = 0
      }
      //上期权重
      positionMap[code][3] = position[2]
      //上期的股数
      positionMap[code][6] = position[3]
      //净值变化
      positionMap[code][4] = (positionMap[code][2] - positionMap[code][3]).toFixed(2)
      //股数变动率
      if (positionMap[code][5] == 0) {
        //清仓
        positionMap[code][7] = '退出'
      } else {
        positionMap[code][7] = ((positionMap[code][5] - positionMap[
          position[
          0]][6]) * 100 / positionMap[code][6]).toFixed(2)
      }
    }

    for (let key in positionMap) {
      for (let i = positionMap[key].length; i < 8; i++) {
        positionMap[key][i] = '-';
      }
    }

    generateFundPositionCompareTable(positionMap, data[p1].updateDate, data[p2].updateDate)
  })
}

//比较公司财报股东
function companyHoldersCompare() {
  //@ts-ignore
  let r1 = document.getElementById('reportDate1').value
  //@ts-ignore
  let r2 = document.getElementById('reportDate2').value
  document.querySelectorAll('[name="reportDate1"]').forEach((elem)=> elem.textContent =  r1)
  document.querySelectorAll('[name="reportDate2"]').forEach((elem)=> elem.textContent =  r2)
  companyFreeHoldersTable(r1, r2)
  companyOrgoldersTable(r1, r2)
}

function companyFreeHoldersTable(r1: string, r2: string) {
  fetchCompanyFreeHolders(code, function (code) {
    let data = cache[`${code}-cfh`]
    let html = '';
    let holderMap: any = {}
    let total = ['总计', '', '', 0, 0, 0, 0, 0, 0]
    for (let item of data) {
      let rd = item.END_DATE.substring(0, 10)
      let key = item.HOLDER_NAME
      if (rd == r1) {
        if (!holderMap[key]) {
          holderMap[key] = []
          holderMap[key][0] = item.HOLDER_NAME
          holderMap[key][1] = item.HOLDER_TYPE
          holderMap[key][2] = item.SHARES_TYPE
        }
        
        holderMap[key][3] = item.FREE_HOLDNUM_RATIO
        holderMap[key][6] = item.HOLD_NUM
        total[3] += holderMap[key][3]
        total[6] += holderMap[key][6]
      }

      if (rd == r2) {
        if (!holderMap[key]) {
          holderMap[key] = []
          holderMap[key][0] = item.HOLDER_NAME
          holderMap[key][1] = item.HOLDER_TYPE
          holderMap[key][2] = item.SHARES_TYPE
        }
        holderMap[key][4] = item.FREE_HOLDNUM_RATIO
        holderMap[key][7] = item.HOLD_NUM
        total[4] += holderMap[key][4]
        total[7] += holderMap[key][7]
      }
    }

    holderMap['total'] = total
    let idx = 0
    for (let name in holderMap) {
      idx++
      let holder = holderMap[name]
      for (let i of [3, 4, 6, 7]) {
        if (!holder[i]) 
        holder[i] = 0
      }
      //占比变化处理
      holder[5] = holder[3] - holder[4]
      //持股数变化处理%
      if (holder[7] > 0) {
        holder[8] = holder[6]*100/holder[7] - 100
      } else {
        holder[8] = 100
      }
      
      html += `<tr><td>${idx}</td>`
      for (let i = 0; i < holder.length; i++) {
        if (i == 5 || i == 8) {
          html += `<td class="${cellColor(holder[i])}">${holder[i].toFixed(2)}</td>`
        } else if (i == 3 || i == 4) {
          html += `<td>${holder[i].toFixed(2)}</td>`
        } else {
          html += `<td>${holder[i]}</td>`
        }
      }
      html += '</tr>'
    }
    html += ''
    bsTable('freeHoldersTable', {data: html})
  })
}


function companyOrgoldersTable(r1: string, r2: string) {
  let should = 2
  let done = 0
  const success = function (code: string, reportDate: string) {
    done++
    if (done != should) {
      return
    }

    let holderMap: any = {}
    for (let rd of [r1, r2]) {
      let data = cache[`${code}-${rd}-coh`]
      for (let item of data) {
        let key = item.FUND_DERIVECODE
        if (rd == r1) {
          if (!holderMap[key]) {
            holderMap[key] = []
            holderMap[key][0] = item.FUND_DERIVECODE
            holderMap[key][1] = item.HOLDER_NAME
          }
          holderMap[key][2] = item.TOTAL_SHARES
          holderMap[key][5] = item.TOTALSHARES_RATIO
          holderMap[key][8] = item.NETVALUE_RATIO
        }

        if (rd == r2) {
          if (!holderMap[key]) {
            holderMap[key] = []
            holderMap[key][0] = item.FUND_DERIVECODE
            holderMap[key][1] = item.HOLDER_NAME
          }
          holderMap[key][3] = item.TOTAL_SHARES
          holderMap[key][6] = item.TOTALSHARES_RATIO
          holderMap[key][9] = item.NETVALUE_RATIO
        }
      }
    }

    let html = ''
    let idx = 0
    for (let name in holderMap) {
      idx++
      let holder = holderMap[name]
      for (let i of [2, 3, 5, 6, 8, 9]) {
        if (!holder[i]) 
        holder[i] = 0
      }
      //持股数变化处理%
      if (holder[3] > 0) {
        holder[4] = holder[2]*100/holder[3] - 100
      } else {
        holder[4] = 100
      }
      //占比变化处理
      holder[7] = holder[5] - holder[6]
      holder[10] = holder[8] - holder[9]
      
      html += `<tr><td>${idx}</td>`
      for (let i = 0; i < holder.length; i++) {
        if (i == 0) {
          if (holder[i]) {
            if (holder[i].endsWith('.SZ')) {
              holder[i] = holder[i].split('.')[0] + '.ZF'
            } else if (holder[i].endsWith('.SH')) {
              holder[i] = holder[i].split('.')[0] + '.SF'
            }
            html += `<td><a href="fund.html?code=${holder[i]}" target="_blank">${holder[i]}</a></td>`
          } else {
            html += `<td></td>`
          }
        } else if ([4, 7, 10].includes(i)) {
          html += `<td class="${cellColor(holder[i])}">${holder[i].toFixed(2)}</td>`
        } else if ([5, 6, 8, 9].includes(i)) {
          html += `<td>${holder[i].toFixed(2)}</td>`
        } else {
          html += `<td>${holder[i]}</td>`
        }
      }
      html += '</tr>'
    }
    
    html += ''
    bsTable('orgHoldersTable', {data: html})
  }

  fetchCompanyOrgHolders(code, r1, success)
  fetchCompanyOrgHolders(code, r2, success)
}

function cellColor(value: any) {
  let color = ''
  if (isNaN(value) || value == 0) {
    return ''
  } else if (value > 0) {
    return 'text-danger'
  } else {
    return 'text-success'
  }
}

function generateFundPositionCompareTable(positionMap: any, currentDate: string, previousDate: string) {
  let html = `<thead class="table-info">
    <tr>
        <th>序号</th>
        <th>成分股代码</th>
        <th>股票简称</a></th>
        <th class="sortable desc">${currentDate}净值占比(%)</th>
        <th class="sortable">${previousDate}净值占比(%)</th>
        <th class="sortable">净值占比变化(%)</th>
        <th class="sortable">${currentDate}持股数(万股)</th>
        <th class="sortable">${previousDate}持股数(万股)</th>
        <th class="sortable">持股数变化(%)</th>
    </tr>`;

  html += `</thead><tbody>`;
  let k = 0;
  for (let i in positionMap) {
    k++
    html += '<tr>'
    html += `<td>${k}</td>`
    for (let j = 0; j < positionMap[i].length; j++) {
      if (j == 0) {
        let code: string = positionMap[i][0]
        code = genFullCode(code)
        html += `<td><a href='company.html?code=${code}' target='_blank'>${positionMap[i][j]}</a></td>`
      } else if (j == 4 || j == 7) {
        html += `<td class="${cellColor(positionMap[i][j])}">${positionMap[i][j]}</td>`
      } else {
        html += `<td>${positionMap[i][j]}</td>`
      }
    }
    html += '</tr>'
  }
  html += '</tbody>'
  bsTable('positionTable', {data: html})
}

interface Value {
  clss: string|string[]
  value: any
}

interface SortableTableConfig {
  //服务端数据获取url拼装,page从1开始
  request?: (sortBy: string, asc: boolean, page: string) => any
  //转换服务端返回数据
  transResults?: (data: any) => string| any
  afterRender?: ()=> void
  //本地数据
  data?: string| any[][]
  //td单元格class处理
  cell?: (cell: any, columnIdx: number) => any
  //tr单元格class处理
  row?: (row: any, rowIdx: number) => any
  //某列首次点击排序是否升序(默认降序)
  firstAsc?: boolean
  //分页依据，0表示不分页
  pageSize?: number
}

function bsTable(tableid: string, config: SortableTableConfig) {
  //https://stackoverflow.com/questions/14267781/sorting-html-table-with-javascript
  const getCellValue = (tr: any, idx: number) => tr.children[idx] ? tr.children[idx].innerText || tr.children[idx].textContent : ''
  const comparer = (idx: number, asc: boolean) => (a: any, b: any) => ((v1, v2) => 
      v1 !== '' && v2 !== '' && !isNaN(v1) && !isNaN(v2) ? v1 - v2 : v1.toString().localeCompare(v2)
      )(getCellValue(asc ? a : b, idx), getCellValue(asc ? b : a, idx))
  const clientColumnSort = (table: HTMLTableElement, th: HTMLTableCellElement, asc: boolean) => {
    table.dataset.page = '1'
    //@ts-ignore
    const tbody: HTMLTableSectionElement = table.querySelector('tbody');
    Array.from(tbody.querySelectorAll('tr'))
      //@ts-ignore
      .sort(comparer(Array.from(th.parentNode.children).indexOf(th), asc))
      .forEach(tr => tbody.appendChild(tr) )
    refreshSortFlag(table, th, asc)
  }

  const serverColumnSort = (table: HTMLTableElement, th: HTMLTableCellElement, asc: boolean) => {
    if (!config.request) {
      return
    }
    table.dataset.st = th.dataset.st
    table.dataset.page = '1'
    fetchRequest(config.request(th.dataset.st? th.dataset.st: '', asc, '1'), (data: any)=>{
      renderTable(table, config.transResults ? config.transResults(data):  data, config)
    })
    refreshSortFlag(table, th, asc)
  }

  const columnSort = config.request ? serverColumnSort: clientColumnSort

  const refreshSortFlag = (table: HTMLTableElement, th: HTMLTableCellElement, asc: boolean)=> {
    //@ts-ignore
    table.asc = asc
    //@ts-ignore
    th.asc = asc
    table.querySelectorAll('th.sortable').forEach(elem =>{
      elem.classList.remove('asc')
      elem.classList.remove('desc')
      if (elem == th) {
        if (asc) {
          elem.classList.add('asc')
        } else {
          elem.classList.add('desc')
        }
      } else {
        //@ts-ignore
        elem.asc = undefined
      }
    })
  }

  const renderTable = (table: HTMLTableElement, rows: any, config: SortableTableConfig): void => {
    let html = ''
    if (typeof rows == 'string') {
      html = rows
    } else {
      for (let i = 0; i < rows.length; i++) {
        let row = rows[i]
        if (config.row) {
          let data = config.row(rows[i], i)
          if (data) {
            if (!data.class) {
              html += '<tr>'
            } else if (typeof data.class == 'string') {
              html += `<tr class="${data.class}">`
            } else {
              html += `<tr class="${data.class.join(' ')}">`
            }
  
            if (data.row) {
              row = data.row
            }
          } else {
            html += '<tr>'
          }
        } else {
          html += '<tr>'
        }
        
        for (let j = 0; j < row.length; j++) {
          let cell = row[j]
          if (config.cell) {
            let data = config.cell(row[j], j)
            if (data) {
              if (data.cell) {
                cell = data.cell
              }
              if (!data.class) {
                html += `<td>${cell}</td>`
              } else if (typeof data.class == 'string') {
                html += `<td class="${data.class}">${cell}</td>`
              } else {
                html += `<td class="${data.class.join(' ')}">${cell}</td>`
              }
            } else {
              html += `<td>${cell}</td>`
            }
          } else {
            html += `<td>${cell}</td>`
          }
        }
        html += '</tr>'
      }
    }

    if (html.indexOf('<thead') > -1) {
      table.innerHTML = html
    } else {
      let withTbody = false
      if (html.indexOf('<tbody') > -1) {
        withTbody = true
      }
  
      let tbody = table.querySelector('tbody')
      if (!tbody) {
        tbody = document.createElement('tbody')
        table.append(tbody)
      }
      if (withTbody) {
        tbody.outerHTML = html
      } else {
        tbody.innerHTML = html
      }
    }

    //增加翻页功能
    if (config.pageSize) {
      pagination(tableid, table.dataset.page, config.pageSize, rows, (page)=>{
        if (config.request) {
          table.dataset.page = page
          //@ts-ignore
          fetchRequest(config.request(table.dataset.st, table.asc, page), (data: any)=>{
            renderTable(table, config.transResults ? config.transResults(data):  data, config)
          })
        }
      })
    }

    if (config.afterRender) {
      config.afterRender()
    }
  }

  //@ts-ignore
  let table: HTMLTableElement = document.getElementById(tableid)
  table.dataset.page = '1'
  if (config.request) {
    let dt: string| undefined
    let asc = true
    if (table.querySelector('th.sortable.asc')) {
      //@ts-ignore
      let th: HTMLTableCellElement = table.querySelector('th.sortable.asc')
      dt = th.dataset.st
      asc = true
    } else if (table.querySelector('th.sortable.desc')) {
      //@ts-ignore
      let th: HTMLTableCellElement = table.querySelector('th.sortable.desc')
      dt = th.dataset.st
      asc = false
    }
    table.dataset.st = dt
    fetchRequest(config.request(dt?dt:'', asc, '1'), (data: any)=>{
      renderTable(table, config.transResults ? config.transResults(data):  data, config)
    })
  } else {
    renderTable(table, config.data, config)
    if (table.querySelector('th.sortable.asc')) {
      //@ts-ignore
      columnSort(table, table.querySelector('th.sortable.asc'), true)
    } else if (table.querySelector('th.sortable.desc')) {
      //@ts-ignore
      columnSort(table, table.querySelector('th.sortable.desc'), false)
    }
  }

  //表头点击排序
  table.querySelectorAll('th.sortable').forEach((th) => th.addEventListener('click', ()=>{
    //@ts-ignore
    if (th.asc == undefined) {
      //@ts-ignore
      th.asc = !config.firstAsc
    }
    //@ts-ignore
    columnSort(table, th, !th.asc)
  }))
}

function pagination(refId: string, page: string|undefined, pageSize: number, data: any, pageClick: (page: string)=>void) {
  let curPage = page? parseInt(page): 1
  let hasNext = data && data.length >= pageSize
  let html = `<ul class="pagination justify-content-center">
  <li class="page-item${curPage<11?' disabled':''}">
  <a class="page-link" data-page="${curPage < 11 ? 1: curPage-10}" href="#"><<</a>
</li>`
  let thisPage = curPage
  let text: any = thisPage
  for (let i=1;i< 11;i++) {
    if (curPage < 9) {
      thisPage = i
      text = thisPage
    } else if (i < 4) {
      thisPage = i
      text = thisPage
    } else if (i == 4) {
      thisPage = Math.floor(curPage/2)
      text = '...'
    } else {
      thisPage = curPage + i - 8
      text = thisPage
    }
    let disabled = thisPage > curPage && !hasNext
    html += `<li class="page-item${curPage==thisPage?' active"':''}${disabled?' disabled':''}">
    <a class="page-link" data-page="${thisPage}" href="#">${text}</a>
  </li>`
  }
  html += `<li class="page-item${hasNext?'':' disabled'}">
  <a class="page-link" data-page="${curPage+10}" href="#">>></a>
</li></ul>`

  if (curPage == 1 && (!data || !data.length)) {
    //第一页都没有数据
    html = ''
  }

  let navid = `${refId}-nav`
  let nav = document.getElementById(navid)
  if (nav) {
    nav.innerHTML = html
  } else {
    nav = document.createElement('nav')
    nav.id = navid
    nav.innerHTML = html
    //@ts-ignore
    const ref: HTMLElement = document.getElementById(refId)
    //@ts-ignore
    ref.parentNode.insertBefore(nav, ref.nextSibling)
  }

  //翻页事件
  nav.querySelectorAll('a').forEach((elem) => elem.addEventListener('click', ()=>{
    //@ts-ignore
    pageClick(elem.dataset.page)
  }))
}

interface SelectConfig {
  //下拉初始显示
  placeholder?: string;
  //下拉是否支持搜索
  noSearch?: boolean;
  //url参数替换
  urlParam?: string;
  //值和text的映射,在urlParam存在时有效
  valueTextMap?: (value: string) => string;
  //搜索反应延时
  delay?: number;
  //是否缓存搜索结果
  cache?: boolean;
  //远端数据获取请求拼装
  request?: (term: string) => any;
  //转换服务端返回数据
  transResults?: (data: any, term: string) => any;
}

/**
 * 实现思路是原有的select隐藏，option只有选中的
 */
function bsSelect(id: string, config: SelectConfig) {
  if (!config.placeholder) {
    config.placeholder = ''
  }
  if (!config.delay) {
    config.delay = 200
  }
  let cache: any = {}
  //@ts-ignore
  const select: HTMLSelectElement = document.getElementById(id)
  const multiple = select.hasAttribute('multiple')
  select.classList.add('d-none')
  const dropdownId = `${id}-dropdown`
  let dropdown = document.getElementById(dropdownId)
  if (dropdown) {
    //@ts-ignore
    dropdown.parentNode.removeChild(dropdown)
  }
  dropdown = document.createElement('div')
  dropdown.id = dropdownId
  dropdown.classList.add('dropdown')
  let html = `<a class="btn btn-sm border border-info dropdown-toggle text-start text-wrap" href="#" role="button">
  ${config.placeholder}
</a>
<ul class="dropdown-menu">
` 
  select.querySelectorAll('option').forEach((option)=>{
    html += `<li><a class="dropdown-item" data-id="${option.value}" href="#">${option.textContent}</a></li>`
  })

  html + '</ul>'
  dropdown.innerHTML = html
  //@ts-ignore
  select.parentNode.insertBefore(dropdown, select.nextSibling)
  //select.innerHTML = ''
  //@ts-ignore
  const btn: HTMLButtonElement = dropdown.querySelector('.dropdown-toggle')
  //@ts-ignore
  const ul: HTMLUListElement = dropdown.querySelector('ul')
  const input = document.createElement('input')
  input.setAttribute('type', 'text')
  input.classList.add('form-control', 'form-control-sm', 'mx-auto', 'w-90')
  if (config.noSearch) {
    input.classList.add('d-none')
  }
  ul.insertBefore(input, ul.firstChild)
  //下拉是否展示了
  let shown = false
  const showDropdown = ()=>{
    ul.classList.add('show')
    shown = true
    input.focus()
  }

  //select变化了
  const selectChanged = () => {
    if (config.urlParam) {
      //@ts-ignore
      replaceUrlParam(config.urlParam, select.val().join(','))
    }
    select.dispatchEvent(new Event('change'))
  }

  const appendSelectedItem = (option: HTMLOptionElement)=>{
    let closeBtn = document.createElement('button')
    closeBtn.type = 'button'
    closeBtn.classList.add('btn-close')
    //closeBtn.appendChild(document.createTextNode('X'))
    let span = document.createElement('span')
    span.classList.add('select-item', 'text-nowrap')
    span.textContent = option.textContent
    span.dataset.id = option.value
    span.appendChild(closeBtn)
    btn.appendChild(span)
    //添加close点击事件
    closeBtn.addEventListener('click', (e)=>{
      shown = false
      //去除选择
      btn.removeChild(span)
      if (!btn.firstChild) {
        //没有孩子节点了
        btn.textContent = config.placeholder || ''
      }
      //从select的option中去掉selected标记
      option.removeAttribute('selected')
      selectChanged()
    })
  }

  //某个选项被选中
  const itemSelect = (id: string, text: string|null)=>{
    let option: HTMLOptionElement|undefined
    select.querySelectorAll('option').forEach((elem)=> {
      if (elem.value == id) {
        option = elem
        text = elem.textContent
      }
    })
    
    if (option && option.hasAttribute('selected')) {
      //已经选中了
      return
    }
    if (!text) {
      if (config.valueTextMap) {
        text = config.valueTextMap(id)
      } else {
        text = id
      }
    }

    if (!btn.querySelector('span')) {
      btn.textContent = ''
    }
    //没找到，需要增加处理
    if (multiple) {
      if (option) {
        option.setAttribute('selected', '')
      } else {
        //添加原有select选项
        option = document.createElement('option')
        //@ts-ignore
        option.value = id
        option.textContent = text
        option.setAttribute('selected', '')
        select.appendChild(option)
      }
      
      //界面处理
      appendSelectedItem(option)
    } else {
      btn.textContent = text
      select.innerHTML = `<option value=${id} selected>${text}</option>`
    }
    selectChanged()
  }

  //添加下拉选择条目点击事件
  const addDropdownItemClickEvent = ()=> {
    ul.querySelectorAll('a').forEach((a)=>{
      a.addEventListener('click', ()=> {
        //@ts-ignore
        itemSelect(a.dataset.id, a.textContent)
      })
    })
  }

  //搜索相应结果处理
  const response = (key: string, result: any)=> {
    if (config.cache) {
      cache[key] = result
    }
    let value = input.value
    let selectedOptions: string[] = []
    select.querySelectorAll('option').forEach((option)=>{
      if (option.hasAttribute('selected')) {
        selectedOptions.push(option.value)
      }
    })
    ul.querySelectorAll('li').forEach((li)=>{
      ul.removeChild(li)
    })
    for (let item of result) {
      if (value && item.name.indexOf(value) < 0) {
        continue
      }
      let a = document.createElement('a')
      a.classList.add('dropdown-item')
      if (selectedOptions.includes(item.id + '')) {
        //之前选中的
        a.classList.add('active')
      } else {
        a.classList.remove('active')
      }
      a.setAttribute('href', '#')
      a.dataset.id = item.id
      a.appendChild(document.createTextNode(item.name))
      let li = document.createElement('li')
      li.appendChild(a)
      ul.appendChild(li)
    }
    addDropdownItemClickEvent()
    showDropdown()
  }

  //下拉点击事件
  btn.addEventListener('click', (e)=>{
    e.stopPropagation()
    if (shown) {
      //关闭下拉
      ul.classList.remove('show')
      input.value = ''
      shown = false
    } else {
      //打开下拉
      if (config.request) {
        let key = `${id}-`
        if (config.cache && cache[key]) {
          response(key, cache[key])
        } else {
          fetchRequest(config.request(''), (data: any)=>{
            response(key, config.transResults? config.transResults(data, ''): data)
          })
        }
      } else {
        let selectedOptions: string[] = []
        select.querySelectorAll('option').forEach((option)=>{
          if (option.hasAttribute('selected')) {
            selectedOptions.push(option.value)
          }
        })
        ul.querySelectorAll('a').forEach((a: any)=>{
          a.classList.remove('d-none')
          if (selectedOptions.includes(a.dataset.id)) {
            //之前选中的
            a.classList.add('active')
          } else {
            a.classList.remove('active')
          }
        })
        showDropdown()
      }
    }
  })

  //点击了其它地方，关闭下拉
  document.addEventListener('click', ()=>{
    //关闭下拉
    ul.classList.remove('show')
    input.value = ''
    shown = false
  })

  let tid: number
  //输入搜索
  input.addEventListener('input', (elem)=>{
    if (tid) {
      clearTimeout(tid)
    }

    tid = setTimeout(()=>{
      let value = input.value.trim()
      //log('input', value)
      if (config.request) {
        let key = `${id}-${value}`
        if (cache[key]) {
          response(key, cache[key])
        } else {
          fetchRequest(config.request(value), (data: any)=>{
            response(key, config.transResults? config.transResults(data, value): data)
          })
        }
      } else {
        ul.querySelectorAll('a').forEach((a)=>{
          //@ts-ignore
          if (value && a.textContent.indexOf(value) < 0) {
            a.classList.add('d-none')
          } else {
            a.classList.remove('d-none')
          }
        })
      }
    }, config.delay)
  })

  //外部变更选项
  select.addEventListener('bs.change', ()=>{
    btn.textContent = ''
    select.querySelectorAll('option').forEach((option)=>{
      if (option.hasAttribute('selected')) {
        if (multiple) {
          let found = false
          btn.querySelectorAll('span').forEach((span)=>{
            if (span.dataset.id == option.value) {
              found = true
            }
          })
          if (!found) {
            appendSelectedItem(option)
          }
        } else {
          btn.textContent = option.textContent
        }
      }
    })
    if (!btn.firstChild) {
      //没有孩子节点了
      btn.textContent = config.placeholder || ''
    }
    selectChanged()
  })

  addDropdownItemClickEvent()
  //处理初始化时的url参数
  if (config.urlParam) {
    //@ts-ignore
    let paramStr: string|null = getUrlParam(config.urlParam)
    if (paramStr) {
      let ids = paramStr.split(',')
      for (let id of ids) {
        itemSelect(id, null)
      }
    }
  }
}

//单选按钮组
function bsRadioButtons(id: string) {
  const parent = $('#'+id)
  parent.dataset.id = parent.querySelector('.active').dataset.id
  parent.querySelectorAll('.btn').forEach((btn: any)=>{
    btn.addEventListener('click', ()=>{
      parent.querySelectorAll('.btn').forEach((theBtn: any)=>{
        if (btn == theBtn) {
          btn.classList.add('active')
          parent.dataset.id = btn.dataset.id
          parent.dispatchEvent(new Event('bs.change'))
        } else {
          theBtn.classList.remove('active')
        }
      })
    })
  })
}

interface CardsConfig {
  //本地数据
  data?: string| any[][];
  //服务端数据获取url拼装,page从1开始
  request?: (page: string) => any;
  //转换服务端返回数据
  transResults?: (data: any) => any[][];
  afterRender?: ()=> void;
  //分页依据，0表示不分页
  pageSize?: number
}

function bsCards(id: string, config: CardsConfig) {
  //@ts-ignore
  const list: HTMLElement = document.getElementById(id)
  const renderCards = (list: HTMLElement, rows: any, config: CardsConfig): void => {
    let html = ''
    for (let item of rows) {
      html += `<a href="${item.url}" class="list-group-item list-group-item-action" target="_blank">
      <div class="d-flex w-100 justify-content-between">
        <h5 class="mb-1">${item.title}</h5>
        <small>${item.date}</small>
      </div>
      <p class="mb-1">${item.content}</p>
      </a><hr>`
    }

    list.innerHTML = html

    //增加翻页功能
    if (config.pageSize) {
      pagination(id, list.dataset.page, config.pageSize, rows, (page)=>{
        if (config.request) {
          list.dataset.page = page
          //@ts-ignore
          fetchRequest(config.request(list.dataset.page), (data: any)=>{
            renderCards(list, config.transResults ? config.transResults(data):  data, config)
          })
        }
      })
    }

    if (config.afterRender) {
      config.afterRender()
    }
  }

  list.dataset.page = '1'
  if (config.request) {
    fetchRequest(config.request('1'), (data: any)=>{
      renderCards(list, config.transResults ? config.transResults(data):  data, config)
    })
  } else {
    renderCards(list, config.data, config)
  }
}

function generateIndexPositionCompareTable(positionMap: any, currentDate: number, previousDate: number) {
  let html = `<thead class="table-danger">
    <tr>
                    <th>成分股代码</th>
                    <th>股票简称</a></th>
                    <th class="sortable desc">${date2String(currentDate)}权重(%)</a></th>
                    <th class="sortable">${date2String(previousDate)}权重(%)</a></th>
                    <th class="sortable">权重变化(%)</a></th>
                </tr>`;

  html += `</thead>
    <tbody>`;

  for (let i in positionMap) {
    html += `<tr>`;
    for (let j = 0; j < positionMap[i].length; j++) {
      let color = 'black';
      if (j == 0) {
        let code: string = positionMap[i][0]
        html +=
          `<td><a href='company.html?code=${code}' target='_blank'>${positionMap[i][j]}</a></td>`;
      } else if (j == 4) {
        if (positionMap[i][j] > 0) {
          color = 'red';
        } else if (positionMap[i][j] < 0) {
          color = 'blue';
        }
        html += `<td style='color:${color}'>${positionMap[i][j]}</td>`;
      } else {
        html += `<td>${positionMap[i][j]}</td>`;
      }
    }
    html += `</tr>`;
  }
  html += `</tbody`;

  bsTable('position', {data: html})
}

function genIndexFundsTable(code: string, data: any) {
  let html = `<thead class="table-primary">
  <tr>
    <th>编号</th>
    <th>指数代码</th>
    <th>基金名称</th>
    <th>基金代码</th>
    <th>基金经理</th>
    <th>基金规模(亿)</th>
    <th>是否场内</th>
  </tr>
</thead>
<tbody id="indexFundsBody">`
  let fundCode = ''
  for (let i = 0; i < data.length; i++) {
    if (data[i].etf) {
      if (data[i].fund_code.startsWith('1')) {
        fundCode = data[i].fund_code + '.ZF'
      } else {
        fundCode = data[i].fund_code + '.SF'
      }
    } else {
      fundCode = data[i].fund_code + '.OF'
    }

    html += `<tr>`
    html += `<td>${i + 1}</td>`
    html += `<td>${data[i].index_code}</td>`
    html += `<td><a href="fund.html?code=${fundCode}">${data[i].name}</a></td>`
    html += `<td><a href="fund.html?code=${fundCode}">${data[i].fund_code}</a></td>`
    html += `<td>${data[i].manager.substring(1, data[i].manager.length - 1)}</td>`
    html += `<td>${data[i].scale}</td>`
    html += data[i].etf == 1 ? '<td>是</td>' : '<td>否</td>'
    html += `</tr>`
  }
  html += '</tbody>'
  bsTable('indexFundsTable', {data: html})
}

function generateIndexesTable(rows: any[]) {
  let html = ''
  let css = ''
  for (let i = 0; i < rows.length; i++) {
    let code = ''
    if (rows[i].index_code.startsWith('0')) {
      code = rows[i].index_code + '.SI'
    } else if (rows[i].index_code.startsWith('3')) {
      code = rows[i].index_code + '.ZI'
    } else {
      code = rows[i].index_code + '.HI'
    }
    html += `<tr>`
    html += `<td>${i + 1}</td>`
    html += `<td><a href="index.html?code=${code}">${rows[i].index_code}</a></td>`
    html += `<td>${rows[i].name}</td>`
    if (rows[i].ppe < 0.3) {
      css = ' class="table-primary"'
    } else if (rows[i].ppe > 0.7) {
      css = ' class="table-danger"'
    } else {
      css = ' class="table-warning"'
    }
    html += `<td${css}>${rows[i].pe.toFixed(2)}</td>`
    html += `<td${css}>${(100 * rows[i].ppe).toFixed(2)}%</td>`
    if (rows[i].ppb < 0.3) {
      css = ' class="table-primary"'
    } else if (rows[i].ppb > 0.7) {
      css = ' class="table-danger"'
    } else {
      css = ' class="table-warning"'
    }
    html += `<td${css}>${rows[i].pb.toFixed(2)}</td>`
    html += `<td${css}>${(100 * rows[i].ppb).toFixed(2)}%</td>`
    if (rows[i].pps < 0.3) {
      css = ' class="table-primary"'
    } else if (rows[i].pps > 0.7) {
      css = ' class="table-danger"'
    } else {
      css = ' class="table-warning"'
    }
    html += `<td${css}>${rows[i].ps.toFixed(2)}</td>`
    html += `<td${css}>${(100 * rows[i].pps).toFixed(2)}%</td>`
    html += `<td>${(100 * rows[i].pb / rows[i].pe).toFixed(2)}%</td>`
    html += `<td>${rows[i].yield.toFixed(2)}%</td>`
    html += `<td>${rows[i].date}</td>`
    html += `</tr>`
  }
  
  bsTable('indexListTable', {data: html})
}

function listIndexes() {
  fetchRequest(`${server}/index/list`, generateIndexesTable)
}

function refreshCompaniesHoldingRankTable() {
  //@ts-ignore
  let date = document.getElementById('date').value
  //@ts-ignore
  let rank: string = query.rank?query.rank: 'HOULD_NUM'
  const pageSize = 50
  bsTable('companiesHoldingRank', {
    request: (sortBy: string , asc: boolean, page: string): any => {
      return {
        proxy: true,
        url: 'http://data.eastmoney.com/dataapi/zlsj/list',
        cacheKey: `fetchCompaniesHoldingRank-${date}-${rank}-${page}`,
        cacheTtl: 360000,
        params: {
          date: date,
          type: 1,
          zjc: 0,
          sortField: rank,
          sortDirec: 1,
          pageNum: page,
          pageSize: pageSize,
          p: page,
          pageNo: page,
          pageNumber: page
        }
      }
    },
    pageSize: pageSize,
    cell: (cell: any, i: number): any =>{
      let data: any = {}
      if ([6, 7].includes(i)) {
        if (cell > 0) {
          data.class = 'text-danger'
        } else if ( cell < 0) {
          data.class = 'text-success'
        } else {
        }
      }
      return data
    },
    transResults: (data: any): any => {
      data = data.data
      let rows: any = []
      for (let i = 0; i < data.length; i++) {
        let row: any = []
        row.push(i + 1)
        row.push(`<a href="company.html?code=${data[i].SECUCODE}">${data[i].SECUCODE}</a>`)
        row.push(`<a href="company.html?code=${data[i].SECUCODE}">${data[i].SECURITY_NAME_ABBR}</a>`)
        row.push(data[i].HOULD_NUM)
        row.push((data[i].FREE_SHARES / 1e4).toFixed(2))
        row.push((data[i].FREE_MARKET_CAP / 1e8).toFixed(2))
        row.push((data[i].HOLDCHA_NUM / 1e4).toFixed(2))
        row.push(data[i].HOLDCHA_RATIO)
        rows.push(row)
      }
      return rows
    }
  })
}

function refreshFundRankTable() {
  const pageSize = 50
  bsTable('fundRankTable', {
    request: (sortBy: string , asc: boolean, page: string): any => {
      return {
        proxy: true,
        url: 'https://fund.eastmoney.com/data/rankhandler.aspx',
        cacheKey: `fundRankTable-${sortBy}-${asc}-${page}`,
        cacheTtl: 360000,
        headers: {
          referer: 'https://fund.eastmoney.com/data/fbsfundranking.html'
        },
        params: {
          op: 'ph',
          dt: 'fb',
          ft: 'ct',
          rs: '',
          gs: 0,
          sc: sortBy,
          st: asc? 'asc': 'desc',
          pi: page,
          pn: pageSize,
          v: 0.15434619077866363
        }
      }
    },
    pageSize: pageSize,
    cell: (cell: any, i: number): any =>{
      let data: any = {}
      if ([6, 7].includes(i)) {
        if (cell > 0) {
          data.class = 'text-danger'
        } else if ( cell < 0) {
          data.class = 'text-success'
        } else {
        }
      }
      return data
    },
    transResults: (data: any): any => {
      log('data', data)
    }
  })
}

//市场近期表现
function marketProcess() {
  let codes: string[] = []
  if (selectedCodes.length == 0) {
    for (let item of securities) {
      cacheCodeName(item[0], item[1], true)
      if (item[2] == '0') {
        continue
      }
      codes.push(item[0])
    }
  } else {
    codes = selectedCodes
  }
  
  fetchKlines(codes, '', function () {
    generateMarketTable(codes)
  })
}

//基础公共初始化，所有页面都调用
function commonInit() {
  codeInit()
  codeSearchInit()
}

interface DateRangeConfig {
  ranges?: string[][]
}

//修改开始日期
function bsDateRangeChangeStart(id: string, sd: string| number) {
  const startInput: HTMLInputElement = $(`#${id}-start`)
  startInput.value = typeof sd == 'string'? sd: toDateString(sd)
  startInput.dispatchEvent(new Event('change'))
}

function bsDateRange(id: string, callback: (startTs: number, endTs: number)=>void, config?: DateRangeConfig) {
  //@ts-ignore
  const dateRange: HTMLElement = document.getElementById(id)
  dateRange.classList.add('input-group', 'input-group-sm')
  let html = `<span class="input-group-text">从</span>
    <input id="${id}-start" class="form-control form-control-sm" type="text" placeholder=".form-control-sm" readonly>
    <div id="${id}-start-calender" class="calender text-center d-none"></div>
    <span class="input-group-text">到</span>
    <input id="${id}-end" class="form-control form-control-sm" type="text" placeholder=".form-control-sm" readonly>
    <div id="${id}-end-calender" class="calender text-center d-none"></div>
    <div class="dropdown">
      <button id="${id}-range" class="btn btn-sm btn-outline-info dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
        日期范围
      </button>
      <ul class="dropdown-menu">`

  if (!config) {
    config = {}
  }
  if (!config.ranges || config.ranges.length == 0) {
    config.ranges = [
      ['year2Now', '年初至今'],
      ['lastYear2Now', '去年至今'],
      ['d-7', '最近7天'],
      ['m-1', '最近1月'],
      ['m-3', '最近3月'],
      ['m-6', '最近6月'],
      ['y-1', '最近1年'],
      ['y-2', '最近2年'],
      ['y-3', '最近3年'],
      ['y-5', '最近5年'],
      ['y-7', '最近7年'],
      ['y-10', '最近10年'],
      ['y-15', '最近15年'],
      ['y-20', '最近20年'],
      ['y-25', '最近25年'],
      ['y-30', '最近30年'],
    ]
  }

  for (let range of config.ranges) {
    html += `<li><a class="range-item dropdown-item" data-range="${range[0]}" href="#">${range[1]}</a></li>`
  }
  html += '</ul></div>'
  dateRange.innerHTML = html
  //@ts-ignore
  const startInput: HTMLInputElement = document.getElementById(`${id}-start`)
  //@ts-ignore
  const endInput: HTMLInputElement = document.getElementById(`${id}-end`)
  //@ts-ignore
  const range: HTMLInputElement = document.getElementById(`${id}-range`)
  dateRange.querySelectorAll('.range-item').forEach((elem: any)=>{
    elem.addEventListener('click', ()=>{
      range.textContent = elem.textContent
      rangeSelectChange(elem.dataset.range, elem.textContent)
    })
  })

  let rangeSelected = false
  const rangeSelectChange = (dv: string, text: string)=>{
    let ed = new Date()
    let sd: Date
    //@ts-ignore
    //let dv: string = dates.value
    if (dv == 'year2Now') {
      sd = new Date(ed.getFullYear(), 0, 1)
    } else if (dv == 'lastYear2Now') {
      sd = new Date(ed.getFullYear()-1, 0, 1)
    } else if (dv == 'custom') {
      return
    } else {
      let arr = dv.split('-')
      let offset = parseInt(arr[1])
      switch (arr[0]) {
        case 'd':
          sd = new Date(ed.getFullYear(), ed.getMonth(), ed.getDate()-offset)
          break
        case 'm':
          sd = new Date(ed.getFullYear(), ed.getMonth()-offset, ed.getDate())
          break
        case 'y':
          sd = new Date(ed.getFullYear()-offset, ed.getMonth(), ed.getDate())
          break
        default:
          log('wrong date format', arr[0])
          return
      }
    }

    startInput.value = toDateString(sd)
    endInput.value = toDateString(ed)
    range.textContent = text
    rangeSelected = true
    startInput.dispatchEvent(new Event('change'))
  }

  [startInput, endInput].forEach((input)=>{
    bsInputCalenderInit(input)
    input.addEventListener('change', ()=>{
      let urlKey = 'from'
      if (input == endInput) {
        urlKey = 'to'
      }
      replaceUrlParam(urlKey, new Date(input.value).getTime().toString())
      if (!rangeSelected) {
        range.textContent = '自定义'
      } else {
        rangeSelected = false
      }
      callback(new Date(startInput.value).getTime(), new Date(endInput.value).getTime())
    })
  })
  
  let rangeIdx = new Date().getMonth() < 4 ? 1: 0
  rangeSelectChange(config.ranges[rangeIdx][0], config.ranges[rangeIdx][1])
}

function bsInputCalenderInit(input: HTMLInputElement) {
  //@ts-ignore
  const calender: HTMLElement = input.nextElementSibling
  const weekDays = ['一', '二', '三', '四', '五', '六', '日']
  let showCalender = false
  const renderCalender = (d: Date)=> {
    let html = `<div>
    <a class="btn btn-sm nav-month mx-1">&lt;</a>
    <span class="dropdown">
      <button class="btn btn-sm btn-outline-info dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      ${d.getFullYear()}年
      </button>
      <ul class="dropdown-menu">`
     
    for (let k=new Date().getFullYear(); k> 2004; k--) {
      html += `<li><button class="dropdown-item year-item" type="button" data-year="${k}">${k}年</button></li>`
    }
    html += ` </ul>
    </span>
    <span class="dropdown">
      <button class="btn btn-sm btn-outline-info dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
      ${d.getMonth()+1}月
      </button>
      <ul class="dropdown-menu">`
    for (let k=0;k <12;k++) {
      html += `<li><button class="dropdown-item month-item" type="button" data-month="${k}">${k+1}月</button></li>`
    }
    html += `</ul>
    </span>
    <a class="btn btn-sm nav-month mx-1">&gt;</a>
    </div>`

    let days = new Date(d.getFullYear(), d.getMonth()+1, 0).getDate()
    let start = new Date(d.getFullYear(), d.getMonth(), 1).getDay()
    if (start == 0) {
      start = 7
    }
    html += '<table class="table table-sm table-borderless"><thead>'
    for (let i=0;i< 7; i++) {
      let finish = false
      if (i == 1) {
        html += '<tbody>'
      }
      html += '<tr>'
      for (let j=0;j< 7;j++) {
        let day = (i-1)*7+j - start + 2
        if (i == 0) {
          html += `<th class="p-1 m-auto"><button type="button" class="btn btn-light btn-weekday" disabled>${weekDays[j]}</button></th>`
        } else if (day > 0 && day <= days) {
          html += `<td class="p-1 m-auto"><button type="button" class="btn btn-day ${day == d.getDate() ? 'btn-primary': 'btn-light'}" data-date="${d.getFullYear()}-${d.getMonth()+1}-${day}">${day}</button></td>`
        } else {
          html += `<td class="p-1 m-auto"><button type="button" class="btn btn-light btn-weekday" disabled></button></td>`
        }
        if (day == days) {
          finish = true
        }
      }
      html += '</tr>'
      if (i == 0) {
        html += '</thead>'
      }
      if (finish) {
        break
      }
    }
    html += '</tbody></table>'
    calender.style.left = `${input.offsetLeft}px`
    calender.innerHTML = html
    calender.classList.remove('d-none')
    calender.querySelectorAll('.nav-month').forEach((elem: any)=>{
      elem.addEventListener('click', (e: Event)=>{
        e.stopPropagation()
        if (elem.textContent == '<') {
          renderCalender(new Date(d.getFullYear(), d.getMonth()-1, d.getDate()))
        } else {
          renderCalender(new Date(d.getFullYear(), d.getMonth()+1, d.getDate()))
        }
      })
    })

    //年点击
    calender.querySelectorAll('.year-item').forEach((elem: any)=>{
      elem.addEventListener('click', (e: Event)=>{
        e.stopPropagation()
        renderCalender(new Date(elem.dataset.year, d.getMonth(), d.getDate()))
      })
    })

    //月份点击
    calender.querySelectorAll('.month-item').forEach((elem: any)=>{
      elem.addEventListener('click', (e: Event)=>{
        e.stopPropagation()
        renderCalender(new Date(d.getFullYear(), elem.dataset.month, d.getDate()))
      })
    })
    //天点击
    calender.querySelectorAll('.btn-day').forEach((elem: any)=>{
      elem.addEventListener('click', (e: Event)=>{
        e.stopPropagation()
        input.value = elem.dataset.date
        input.dispatchEvent(new Event('change'))
        calender.classList.add('d-none')
        calender.innerHTML = ''
        showCalender = false
      })
    })
  }

  if (!input.value) {
    input.value = toDateString(Date.now())
  }

  input.addEventListener('click', (e: Event)=>{
    showCalender = true
    renderCalender(new Date(input.value))
  })

  //防止日志内部的点击时间被捕获
  calender.addEventListener('click', (e)=> {
    e.stopPropagation()
  })
  //其它地方点击,隐藏日历
  document.addEventListener('click', ()=>{
    if (showCalender) {
      showCalender = false
      return
    }
    calender.classList.add('d-none')
    calender.innerHTML = ''
  })
}

function dateRangeInit() {
  bsDateRange('dateRange', renderLineChart)
}

function klinePriceChange() {
  let fq = $('#klinePrice').value
  fetchKlines(selectedCodes, fq, (codes)=> {
    klineCodes = []
    codes.map(code => {
      klineCodes.push(code + fq)
      codeNameMap[code + fq] = codeNameMap[code]
    })
    rerenderMyChart()
    genrateRegressTable()
    genratePerformanceTable()
  })
}

function reportDate2Quarter(str: string) {
  switch (str.substring(5, 10)) {
    case '03-31':
      return str.substring(0,4) + 'Q1'
    case '06-30':
      return str.substring(0,4) + 'Q2'
    case '09-30':
      return str.substring(0,4) + 'Q3'
    case '12-31':
      return str.substring(0,4) + 'Q4'
    default:
      return str
  }
}

//标记财报日期
function marklineFinanceReportDate() {
  if ($('#marklineFinanceReportDate').checked) {
    let success = function (codes: string[]) {
      let colors = ['blue', 'red', 'black']
      let colorIdx = 0
      for (let code of codes) {
        let reports = cache[`${code}-fsb`]
        for (let i = 0; i < reports.length; i++) {
          markPoints.push({
            name: `财报公告: ${codeNameMap[code]}-${reportDate2Quarter(reports[i].reportDate)}`,
            x: toTimestamp(reports[i].noticeDate),
            color: colors[colorIdx]
          })
        }
        colorIdx = (colorIdx + 1)%colors.length
      }
      rerenderMyChart()
    }
    fetchCodesData(selectedCodes, fetchFinanceBalance, success)
  } else {
    //删除
    for (let code of selectedCodes) {
      let toDeleteIds = []
      for (let i=0;i < markPoints.length;i++) {
        if (markPoints[i].name.startsWith(`财报公告: ${codeNameMap[code]}`)) {
          toDeleteIds.push(i)
        }
      }
      toDeleteIds.reverse().map(i => markPoints.splice(i, 1))
    }
    rerenderMyChart()
  }
}

function initCompany() {
  if (code == undefined) {
    log('initCompany,code == undefined')
    return
  }
  dateRangeInit()
  codeSelectInit([], 'codes', '对比...', false)
  klineOptionsInit()
  marketProcess()
  $("#codes").addEventListener("change", onKlineCodeSelectChange)
  $('#klinePrice').addEventListener('change', klinePriceChange)
  $('#candlestick').addEventListener('change', rerenderMyChart)
  $('#marklineFinanceReportDate').addEventListener('change', marklineFinanceReportDate)
  onKlineCodeSelectChange()

  $("#ratio").addEventListener("change",  (event: any)=> {
    onRatioCheckChange(event.target.checked)
  })

  $("#alignStart").addEventListener("change", (event: any)=> {
    onAlignStartCheckChange(event.target.checked)
  })
}

function positionCheckOnChange() {
  fetchFundPosition(code, 1, function (code) {
    let data = cache[`${code}-fp`]
    let codes: string[] = []
    for (let position of data[0].data) {
      codeNameMap[position[0]] = position[1]
      codes.push(position[0])
    }

    fetchKlines(codes, '', function () {
      klineCodes.push(...codes)
      rerenderMyChart()
    })
  })
}

//单个基金页面
function initFund() {
  if (code == undefined) {
    log('initFund,code == undefined')
    return
  }
  dateRangeInit()
  fetchFundInfo(code, renderFundInfoTable)
  $('#klinePrice').addEventListener('change', klinePriceChange)
  klinePriceChange()

  $("#positionCheck").addEventListener("change", positionCheckOnChange)
}

function initFundPosition() {
  if (!code) {
    log('initFundPosition,code == undefined')
    return
  }
  fundPositionCompare()

  bsTable('fundShareChangeTable', {
    request: (sortBy: string, asc: boolean, page: string): any => {
      return {
        url: 'https://fundf10.eastmoney.com/FundArchivesDatas.aspx',
        proxy: true,
        accept: 'json',
        cacheKey: `fundShareChange-${code}`,
        cacheTtl: 360000,
        params: {
          type: 'gmbd',
          mode: 0,
          code: code.split('.')[0],
          rt: 0.7132252771122838
        }
      }
    },
    transResults: (data: any) : any => {
      data = data.data
      let rows: any = []
      for (let i=0;i<data.length;i++) {
        let shareChange: number|string = 0
        if (data[i+1] && data[i].QMZFE && data[i+1].QMZFE) {
          shareChange = (100*data[i].QMZFE/data[i+1].QMZFE - 100).toFixed(2)
        }
        rows.push([data[i].FSRQ, (data[i].QJSG/1e8).toFixed(2), (data[i].QJSH/1e8).toFixed(2), (data[i].QMZFE/1e8).toFixed(2), (data[i].NETNAV/1e8).toFixed(2), shareChange, data[i].CHANGE])
      }
      return rows
    }
  })

  document.querySelectorAll("select[name='reportDate']").forEach((elem) => {
    elem.addEventListener('change', fundPositionCompare)
  })
}

function initCompanyHolders() {
  //填充下拉日期
  fetchCompanyFreeHolders(code, function (code) {
    let data = cache[`${code}-cfh`]
    //下拉填充
    let options: any[] = []
    let reportDateMap: any = {}
    for (let i=0;i < data.length; i++) {
      let reportDate = data[i].END_DATE.substring(0, 10)
      if (reportDateMap[reportDate]) {
        continue
      }
      reportDateMap[reportDate] = 1
      options.push({value: reportDate, text: reportDate})
    }
    fillSelectOptions(options, options[0].value, 'reportDate1')
    fillSelectOptions(options, options[1].value, 'reportDate2')
    //处理业务
    companyHoldersCompare()
  })

  document.querySelectorAll("select[name='reportDate']").forEach((elem) => {
    elem.addEventListener('change', companyHoldersCompare)
  })
}

function companyBonusTable() {
  let kline: any
  let dividend: any
  let should = 2
  let done = 0
  const oneYearMin = 3600000*24*270
  const oneYearMax = 3600000*24*390
  const success = ()=>{
    done++
    if (done < should) {
      return
    }

    if (!dividend || dividend.length < 1) {
      //没有分红
      return
    }

    //最近一年的分红
    let inYearBonus = 0
    const currentTs = Date.now()
    let rows: any = []
    for (let item of dividend) {
      //还没确定分红日期的，用公告日+90天代替
      let ts = item.recordDate? toTimestamp(item.recordDate): toTimestamp(item.noticeDate) + 3600*1000*24*90
      //查找分红登记日时的股份总数，计算分红总额
      let k = findTsIndex(kline, ts)
      //总份额=市值/股价
      let totalShares = kline[k][7]/kline[k][1]
      if ((inYearBonus == 0 && ts + oneYearMax > currentTs) || (inYearBonus != 0 && ts + oneYearMin > currentTs)) {
        inYearBonus += item.bonus*totalShares
      }
      let idx = findTsIndex(kline, ts)
      let bonusRatio = (100*item.bonus/kline[idx][1]).toFixed(2)
      rows.push([item.noticeDate, item.plan, item.progress, item.recordDate||'', item.divDate||'', kline[idx][1], bonusRatio, (item.bonus*totalShares/1e8).toFixed(2)])
    }

    let totalShares = kline[kline.length-1][7]/kline[kline.length-1][1]
    //$('#currentBonusRatio').textContent = `(当前股息率:${(100*inYearBonus/totalShares/kline[kline.length-1][1]).toFixed(2)}%)`
    if (inYearBonus > 0) {
      $('#yield').textContent = '股息率: ' + (100*inYearBonus/totalShares/kline[kline.length-1][1]).toFixed(2) + '%'
    }
    bsTable('bonusTable', {data: rows})
  }

  fetchShareBonus(code, (data: any)=>{
    dividend = data
    success()
  })

  fetchKline(code, 'normal', (data: any)=>{
    kline = data
    success()
  })
}

function companyShareAdditionalTable() {
  let kline: any
  let shareAdditional: any
  let should = 2
  let done = 0
  const success = ()=>{
    done++
    if (done < should) {
      return
    }
    if (!shareAdditional || shareAdditional.length < 1) {
      //没有增发
      return
    }
    let rows: any = []
    for (let item of shareAdditional) {
      let idx1 = findTsIndex(kline, toTimestamp(item.NOTICE_DATE))
      let idx2 = findTsIndex(kline, toTimestamp(item.REG_DATE))
      rows.push([item.NOTICE_DATE.substring(0, 10), (item.ISSUE_NUM/1e8).toFixed(4), (item.NET_RAISE_FUNDS/1e8).toFixed(2), item.ISSUE_PRICE, item.ISSUE_WAY_EXPLAIN, item.REG_DATE.substring(0, 10), kline[idx1][1], kline[idx2][1]])
    }
    bsTable('shareAdditionalTable', {data: rows})
  }

  fetchShareAdditional(code, (data: any)=>{
    shareAdditional = data
    success()
  })

  fetchKline(code, 'normal', (data: any)=>{
    kline = data
    success()
  })
}

function initCompanyDividend() {
  companyBonusTable()
  companyShareAdditionalTable()
}

//限售解禁
function companyRestriction() {
  bsTable('restrictionTable', {
    request: (sortBy: string , asc: boolean, page: string): any => {
      return {
        proxy: true,
        url: 'https://datacenter.eastmoney.com/securities/api/data/v1/get',
        cacheKey: `companyRestriction-${code}`,
        cacheTtl: 360000,
        params: {
          reportName: 'RPTA_APP_LIFTFUTURE',
          columns: 'SECUCODE,SECURITY_CODE,LIFT_DATE,LIFT_NUM,TOTAL_SHARES_RATIO,UNLIMITED_A_SHARES_RATIO,LIFT_TYPE',
          quoteColumns: '',
          filter: `(SECUCODE="${code}")`,
          pageNumber: 1,
          pageSize: 200,
          sortTypes: 1,
          sortColumns: 'LIFT_DATE',
          source: 'HSF10',
          client: 'PC'
        }
      }
    },
    transResults: (data: any): any => {
      let rows: any = []
      if (!data.result) {
        return rows
      }
      for (let item of data.result.data) {
        rows.push([item.LIFT_DATE.substring(0, 10), (item.LIFT_NUM/1e4).toFixed(2), item.TOTAL_SHARES_RATIO, item.UNLIMITED_A_SHARES_RATIO, item.LIFT_TYPE])
      }
      return rows
    }
  })
}

//股本结构
function companyShareStructure() {
  fetchShareChange(code, (data: any)=> {
    let rows: any = []
    let kline: any = []
    for (let item of data) {
      ['freeShares', 'limitedAShares', 'limitedDomesticNatural', 'limitedDomesticNostate', 'limitedOthers', 'limitedShares', 'limitedStateLegal', 'listedAShares', 'totalShares', 'unlimitedShares'].map((key)=> {
        if (item[key]) {
          item[key] = (item[key]/1e4).toFixed(2)
        } else {
          item[key] = '--'
        }
      })
      rows.push([item.changeDate, item.totalShares, item.changeRatio, item.changeReason, item.limitedShares, item.limitedStateLegal, item.limitedOthers, item.limitedDomesticNostate, item.limitedDomesticNatural, item.unlimitedShares, item.listedAShares])
      kline.push([toTimestamp(item.changeDate), item.totalShares])
    }
    
    let klineCode = 'shares'
    codeNameMap[klineCode] = `${codeNameMap[code]}(万股)`
    cache[klineCode] = kline.reverse()
    klineCodes.push(klineCode)
    rerenderMyChart()
    bsTable('shareTable', {
      data: rows
    })
  })
}

function initCompanyShares() {
  dateRangeInit()
  companyRestriction()
  companyShareStructure()
}

function initIndexPosition() {
  fetchIndexPositionDates(code, function (code, dates) {
    fillIndexSelectPositionUpdateDates(dates, 0, '#reportDate1')
    fillIndexSelectPositionUpdateDates(dates, 1, '#reportDate2')
    indexPositionCompare(code)
  })

  $("#reportDate1").addEventListener("change", function () {
    // @ts-ignore
    indexPositionCompare(code)
  })
  $("#reportDate2").addEventListener("change", function () {
    // @ts-ignore
    indexPositionCompare(code)
  })
}

function klineOptionsInit() {
  $('#klineOptions').addEventListener('change', klineOptionsChange)
  bsSelect('klineOptions', {
    placeholder: '财务指标...',
    noSearch: true,
    urlParam: 'klineOptions'
  })
}

//对齐开始日期
function onAlignStartCheckChange(checked: boolean) {
  let startTs = 0
  if (checked) {
    startTs = new Date($('#dateRange-start').value).getTime()
    $("#alignStart").dataset.ts = startTs
    for (let code of klineCodes) {
      if (cache[code] && cache[code].length > 0 && cache[code][0][0] > startTs) {
        startTs = cache[code][0][0]
      }
    }
  } else {
    startTs = parseInt($("#alignStart").dataset.ts)
  }
  bsDateRangeChangeStart('dateRange', startTs)
}

function fetch2FormatFinanceData(codes: string[], callback: (codes: string[])=>void) {
  let should = 3
  let done = 0
  let success = function (codes: string[]) {
    done++
    if (done == should) {
      reportsMap = formatFinanceData(codes)
      callback(codes)
    }
  }

  fetchCodesData(selectedCodes, fetchFinanceIncome, success)
  fetchCodesData(selectedCodes, fetchFinanceBalance, success)
  fetchCodesData(selectedCodes, fetchFinanceCashflow, success)
}

function financeCharTableOnChange() {
  fetch2FormatFinanceData(selectedCodes, genFinanceChartTable)
}

//将财报数据按照年或者季度进行格式化
function formatFinanceData(codes: string[]) {
  const compareType: string = $('#compareType').dataset.id
  let reportsMap: any = {}
  //利润表要考虑累加、资产负债表只取对应的报告就行了
  for (let code of codes) {
    let reports: any = []
    //利润表
    let cacheKey = `${code}-fsi`
    let reportYear = ''
    for (let report of cache[cacheKey]) {
      if (compareType =='yearly' && report.reportDate.substring(0, 4) == reportYear && reports.length > 0) {
        //累加年度的
        let i = reports.length - 1
        for (let key in report) {
          if (['reportDate', 'noticeDate'].includes(key)) {
            continue
          }
          if (reports[i][key]) {
            reports[i][key] += report[key]
          } else {
            reports[i][key] = report[key]
          }
        }
      } else {
        reports.push({ ...report })
        reportYear = report.reportDate.substring(0, 4)
      }
    }

    //资产负债表balance
    cacheKey = `${code}-fsb`
    let idx = 0
    for (let i = 0; i < reports.length; i++) {
      for (let j= idx; j < cache[cacheKey].length; j++) {
        if (reports[i].reportDate == cache[cacheKey][j].reportDate) {
          //同日期的找到了
          for (let key in cache[cacheKey][j]) {
            reports[i][key] = cache[cacheKey][j][key]
          }
        }
      }
    }

    //现金流量表
    cacheKey = `${code}-fsc`
    idx = 0
    for (let i = 0; i < reports.length; i++) {
      for (let j= idx; j < cache[cacheKey].length; j++) {
        //日期相同，或者年报年相同
        if (reports[i].reportDate == cache[cacheKey][j].reportDate || (compareType == 'yearly' && reports[i].reportDate.substring(0,4) == cache[cacheKey][j].reportDate.substring(0,4))) {
          //同时间的找到了
          for (let key in cache[cacheKey][j]) {
            if (['reportDate', 'noticeDate'].includes(key)) {
              continue
            }
            //存在就累加，不存在就赋值
            if (reports[i][key]) {
              reports[i][key] += cache[cacheKey][j][key]
            } else {
              reports[i][key] = cache[cacheKey][j][key]
            }
          }
        }
      }
    }

    //额外计算几个指标
    for (let i=0;i< reports.length;i++) {
      //银行可能只有operateIncome没有totalOperateIncome
      if (!reports[i].totalOperateIncome && reports[i].operateIncome) {
        reports[i].totalOperateIncome = reports[i].operateIncome
      }
      //总资产收益率
      reports[i].roa = 100*reports[i].netProfit/reports[i].totaAssets
      //净资产收益率
      reports[i].roe = 100*reports[i].netProfit/reports[i].totalEquity
      //毛利润率
      reports[i].grossProfitRatio = 100*reports[i].grossProfit/reports[i].totalOperateIncome
      //营业净利润率
      reports[i].netProfitRatio = 100*reports[i].netProfit/reports[i].totalOperateIncome
      //总资产周转率
      reports[i].totalAssetsTurnover = reports[i].totalOperateIncome/reports[i].totaAssets
      //资产负债率
      reports[i].assetLiabRatio = 100*reports[i].totalLiabilities/reports[i].totaAssets
      //权益乘数
      reports[i].equityMultiplier = 100/(100-reports[i].assetLiabRatio)
    }

    reportsMap[code] = reports
  }
  return reportsMap
}

function genFinanceChartTable(codes: string[]) {
  genFinanceChart('financeChart', codes, ['totalOperateIncome', 'parentNetprofit'], ['总营收', '归母净利润'])
  genFinanceCoreTable(codes)
  genFinanceIncomeTable(codes)
  genFinanceBalanceTable(codes)
  genFinanceCashflowTable(codes)
}

function genFinanceCoreTable(codes: string[]) {
  genFinanceTable(codes, '核心指标', coreKeys, null, 'coreTable')
}

function genFinanceIncomeTable(codes: string[]) {
  genFinanceTable(codes, '利润表', incomeKeys, 'totalOperateIncome', 'incomeTable')
}

function genFinanceBalanceTable(codes: string[]) {
  genFinanceTable(codes, '资产负债表', balanceKeys, 'totaAssets', 'balanceTable')
}


function genFinanceCashflowTable(codes: string[]) {
  genFinanceTable(codes, '现金流量表', cashflowKeys, 'endCce', 'cashflowTable')
}

function genFinanceTable(codes: string[], tableName: string, keys: string[][], ratioByKey: string|null, tableId: string) {
  let {offsetMap, compareText} = getOffsetAndCompareText(reportsMap)
  // @ts-ignore
  let  seasons = parseInt(document.getElementById('seasons').value)
  //@ts-ignore
  let yoyRatio = parseInt(document.getElementById('yoyRatio').value)
  //@ts-ignore
  let displayEmpty: boolean = document.getElementById('displayEmpty').checked
  let html = `<thead class="theadFix">
        <tr>
          <th rowspan="2" scope="col" class="table-warning text-end">${tableName}${ratioByKey?'(占比%)': ''}<br>增长率%</th>
          `
  let maxReportDate = ''
  for (let code of codes) {
    if (!maxReportDate || reportsMap[code][0].reportDate > maxReportDate) {
      maxReportDate = reportsMap[code][0].reportDate
    }
  }

  let rows: any = []
  let reportDates: string[] = []
  const tableColors = ['dark', 'success', 'danger', 'warning']
  for (let kr of keys) {
    let key = kr[0]
    let row: any = []
    for (let i=0;i < seasons; i++) {
      for (let code of codes) {
        //指标、同比、占比
        let item: any[] = ['-', '-', '-']
        if (i >= reportsMap[code].length || (i==0 && reportsMap[code][0].reportDate < maxReportDate)) {
          //占位
          row.push(item)
          continue
        }

        if (!reportDates[i]) {
          reportDates[i] = reportsMap[code][i].reportDate
          html += `<th colspan="${codes.length}" scope="col" class="table-${tableColors[i%4]}">${reportDates[i]}</th>`
        }

        item[0] = reportsMap[code][i][key]
        if (i+offsetMap[code] < reportsMap[code].length) {
          item[1] = reportsMap[code][i][key]/reportsMap[code][i+offsetMap[code]][key] - 1
        }
        if (ratioByKey) {
          item[2] = reportsMap[code][i][key]/reportsMap[code][i][ratioByKey]
        }
        row.push(item)
      }
    }
    rows.push(row)
  }

  const colors = ['primary', 'success', 'danger', 'warning', 'info']
  html += `<tr class="fs-6">`
  for (let i=0;i < seasons; i++) {
    for (let j=0;j< codes.length;j++) {
      html += `<th scope="col" class="table-${colors[j%colors.length]}">${codeNameMap[codes[j]]}</th>`
    }
  }
  
  html += `
        </tr>
    </thead>
    <tbody>`
  for (let i=0;i< rows.length;i++) {
    if (!displayEmpty) {
      //现判断本行有没有非0数据，有的话再显示
      let valid = false
      for (let item of rows[i]) {
        if (item[0] && item[0] != '-') {
          valid = true
          break
        }
      }

      if (!valid) {
        continue
      }
    }
    
    html += `</tr><tr><td class="text-end align-middle${keys[i][5]?' text-'+keys[i][5]:''}"><a data-bs-toggle="modal" data-bs-target="#chartModal" data-key="${keys[i][0]}">${keys[i][1]}</a></td>`
    for (let item of rows[i]) {
      if (item[0] && item[0] != '-') {
        let color = 'body'
        if (item[1] > yoyRatio/100) {
          color = 'danger'
        } else if (item[1] < 0) {
          color = 'success'
        }
        if (ratioByKey) {
          html += `<td>${formatReportNumber(item[0], false)}(${formatReportNumber(item[2], true)}%)<br><span class="text-${color}">${formatReportNumber(item[1], true)}%</span></td>`
        } else {
          html += `<td>${formatReportNumber(item[0], false)}<br><span class="text-${color}">${formatReportNumber(item[1], true)}%</span></td>`
        }
      } else {
        html += `<td class="align-middle">-</td>`
      }
      
    }
    html += '</tr>'
  }

  html += '</tbody>'
  //@ts-ignore
  document.getElementById(tableId).innerHTML = html
}

//弹窗显示图表
function chartModalShow(event: any) {
  const key = event.relatedTarget.dataset.key
  let it = [...coreKeys, ...incomeKeys, ...balanceKeys, ...cashflowKeys].find((item)=> item[0] == key)
  if (!it) {
    log('chartModalShow not found key', key)
    return
  }
  genFinanceChart('singleChart', selectedCodes, [key], [it[1]])
}

function initCompanyFinance() {
  financeCharTableOnChange()
  $('#codes').addEventListener('change', onFinanceCodeSelectChange)
  $('#seasons').addEventListener('change', financeCharTableOnChange)
  $('#yoyRatio').addEventListener('change', financeCharTableOnChange)
  $('#displayEmpty').addEventListener('change', financeCharTableOnChange)
  $('#compareType').addEventListener('bs.change', financeCharTableOnChange)
  $('#chartModal').addEventListener('shown.bs.modal', chartModalShow)
  codeSelectInit(['SH', 'SZ', 'HK', 'US'], 'codes', '股票对比', false)
  bsRadioButtons('compareType')
}

function initCoin() {
  if (code == undefined) {
    log('initCoin,code == undefined')
    return
  }
  dateRangeInit()
  codeSelectInit(['DC'], 'codes', '选择数字币', false)
  $("#codes").addEventListener("change", coinSelectOnChange)
  $("#ratio").addEventListener("change", (event: any)=> {
    // @ts-ignore
    onRatioCheckChange(event.target.checked)
  })
}

function initIndexes() {
  listIndexes()
}

function calcIndexPeKline(data: any) {
  let kline: any = []
  let should = data.dates.length
  let done = 0
  for (let i = 0; i < data.dates.length;i++) {
    let ts = data.dates[i]
    let nextTs = i+1 <  data.dates.length ? data.dates[i+1]: Date.now()
    let codes: string[] = []
    let weightMap: any = {}
    for (let item of data[ts]) {
      codes.push(item[0])
      weightMap[item[0]] = item[1]
    }

    fetchKlines(codes, 'normal', (codes: string[])=>{
      done++
      let shareMap: any = {}
      for (let thecode of codes) {
        //计算权重股股数
        let cacheKey = thecode + 'normal'
        let idx = findTsIndex(cache[cacheKey], ts)
        shareMap[thecode] = 1e8 * weightMap[thecode] / cache[cacheKey][idx][1]
      }
      
      let cacheKey = codes[0] + 'normal'
      let tss = cache[cacheKey]
      let idx = findTsIndex(cache[cacheKey], ts)
      //计算每交易日数据
      for (let j = idx; j < tss.length;j++) {
        if (tss[j][0] >= nextTs) {
          //该用新的权重数据了
          break
        }
        let p = 0,e = 0
        //计算每个成分股的
        for (let thecode of codes) {
          cacheKey = thecode + 'normal'
          let k = findTsIndex(cache[cacheKey], tss[j][0])
          p += cache[cacheKey][k][1] * shareMap[thecode]
          e += cache[cacheKey][k][1]/cache[cacheKey][k][8] * shareMap[thecode]
          /*if (ts == data.dates[0] && j == idx) {
            log('code pe', toDateString(ts), thecode, cache[cacheKey][k][8])
          }*/
        }
        kline.push([tss[j][0], p/e])
      }

      if (should == done) {
        let thecode = code + '-pe'
        cache[thecode] = kline
        klineCodes.push(thecode)
        rerenderMyChart()
      }
    })
  }
}

function onIndexKlineCodeSelectChange() {
  if ($('#codes').val().length == 0) {
    log('codes none')
    return
  }

  selectedCodes = $('#codes').val()
  klineCodes = [] //清空
  changeCodeSpecHref()
  fetchKlines(selectedCodes, '', function (codes) {
    klineCodes.push(...selectedCodes)
    klineOptionsChange()
    rerenderMyChart()
  })
}

function initIndex() {
  if (code == undefined) {
    log('initIndex,code == undefined')
    return
  }

  dateRangeInit()
  codeSelectInit([], 'codes', '对比...', false)
  klineOptionsInit()
  $("#codes").addEventListener("change", onIndexKlineCodeSelectChange)
  $('#klinePrice').addEventListener('change', klinePriceChange)
  onIndexKlineCodeSelectChange()

  $("#ratio").addEventListener("change",  (event: any)=> {
    onRatioCheckChange(event.target.checked)
  })

  $("#alignStart").addEventListener("change", (event: any)=> {
    onAlignStartCheckChange(event.target.checked)
  })

  fetchIndexWeight(code, (data)=>{
    calcIndexPeKline(data)
  })
}

function genrateEastMoneyBullPutSpreadRows(options: any[]): any[] {
  let rows: any[] = []
  for (let i = 0; i < options.length; i++) {
    let option: any = options[i]
    if (option == null) {
      continue
    }
    let strike = options[i].f14.substring(options[i].f14.length - 4, options[i].f14.length) / 1000.0
    rows.push([
      option["f14"],
      strike,
      option["f334"],
      option["f2"],
      option["f298"],
      option["f299"],
      option["f249"],
      option["f300"],
      option["f336"],
      option["f301"],
      option["f325"],
      option["f326"],
      option["f327"],
      option["f328"],
      option["f329"],
      0,
      0,
      0,
      0,
      0
    ])
    let k = rows.length - 1
    if (options[i].f14.indexOf("沽") > -1) {
      rows[k][15] = (100 * (option["f334"] - strike) / strike).toFixed(2)
      for (let j = 1; j <= 2; j++) {
        if (k >= j && options[i].f14.substring(0, options[i].f14.length - 4) == options[i - j].f14.substring(0, options[i].f14.length - 4)) {
          rows[k][14 + 2 * j] = (100 * (rows[k][4] - rows[k - j][4]) / (rows[k][1] - rows[k - j][1])).toFixed(2)
          rows[k][15 + 2 * j] = (100 * (rows[k][3] - rows[k - j][3]) / (rows[k][1] - rows[k - j][1])).toFixed(2)
        }
      }
    } else {
      //购
      //盈亏距离
      rows[k][15] = (100 * (strike + option["f2"] - option["f334"]) / option["f334"]).toFixed(2)
      //效率，如果正股涨1%，期权涨多少，假设期权时间没损耗
      rows[k][16] = (option["f334"] / option["f2"]).toFixed(2)
    }
  }
  return rows
}


function genrateEastMoneyBullPutSpreadTable(options: string[][]) {
  let rows = genrateEastMoneyBullPutSpreadRows(options)
  bsTable("put", {data: rows})
}

function eastmoneyBullPutTableRowStyle(row: any, index: number) {
  //高亮价格
  let stars = [
    '3200',
    '3300',
    '4800',
    '4900',
  ]
  let classes = ''
  let strike = (row[1] * 1000).toFixed(0)
  if (stars.includes(strike)) {
    classes = 'table-primary'
  } else {
    classes = ''
  }

  return {
    classes: classes
  }
}

function cellStyleColor(value: any, row: any, index: number) {
  let color = ''
  if (isNaN(value) || value == 0) {
    color = 'black'
  } else if (value > 0) {
    color = 'red'
  } else {
    color = 'blue'
  }

  return {
    css: {
      color: color
    }
  }
}

function initOptions() {
  code = '510300.SH'
  codeNameMap[code] = '沪深300ETF'
  dateRangeInit()
  astockOptionSelectInit('options', code)
  fetchEastmoneyOption(code, function (code: string) {
    genrateEastMoneyBullPutSpreadTable(cache[`${code}-so`])
  })

  $("#options").addEventListener("change", function () {
    //@ts-ignore
    selectedCodes = $('#options').val()
    selectedCodes.push(code)
    fetchKlines(selectedCodes, '', function () {
      if (selectedCodes.length < 3) {
        if (codeNameMap[selectedCodes[0]].indexOf('购') > 0 || codeNameMap[selectedCodes[0]].indexOf('C') > 0) {
          diffKline(selectedCodes[0], selectedCodes[1])
          selectedCodes.push('diff')
        } else {
          addKline(selectedCodes[0], selectedCodes[1])
          selectedCodes.push('add')
        }
      } else {
        diffKline(selectedCodes[0], selectedCodes[1])
        addKline('diff', selectedCodes[2])
        selectedCodes.unshift('diff')
        selectedCodes.push('add')
      }
      renderMultiChart(selectedCodes, 2)
    })
  })
}

function optionsSelectInit() {
  /*bsSelect(id, {
    placeholder: placeholder,
    cache: true,
    request: (term: string)=> {
      if (!term || cats.includes('DC')) {
        return securitiesFilter(cats, securities)
      } else {
        let ts = Date.now()
        return {
          url: 'https://searchapi.eastmoney.com/api/suggest/get',
          proxy: true,
          params: {
            cb: "jQuery3310013860444046051468_" + ts,
            input: term,
            type: 14,
            token: "D43BF722C8E33BDC906FB84D85E326E8",
            markettype: "",
            mktnum: "",
            jys: "",
            classify: "",
            securitytype: "",
            status: "",
            count: 50,
            _: ts
          }
        }
      }
    },
    transResults: (data: any)=>{
      let result: any = []
      if (data.QuotationCodeTable) {
        return transSearchResult(cats, data)
      } else {
        cacheCodeNameMap(data)
        for (let item of data) {
          result.push({id: item.id, name: getSearchResultCodeText(item.id, item.name)})
        }
      }
      return result
    }
  })*/
}

function updateOptionExpirationDates(data: any) {
  let items: any = []
  for (let ts of data) {
    items.push({id: ts, name: toDateString(ts)})
  }

  bsSelect('expireDate', {
    placeholder: '到期时间...',
    noSearch: true,
    request: (term: string) => items})
}

function genrateOptionTable(arr: any) {
  const currentPrice = parseFloat($('#currentPrice').textContent)
  const expireDate = $('#expireDate').value
  const stg = $('#optionStrategy').value
  if (stg == 'callSpread' || stg == 'putSpread') {
    $('#extraParam').classList.remove('d-none')
  } else {
    $('#extraParam').classList.add('d-none')
  }
  let vals: any = usOptionSecurities.find((item)=> item[0] == code)
  let html = ''
  let rows: any[][] = []
  if (arr.length > 1) {
    //多个期权对比
    html = `<thead class="table-info theadFix">
        <tr>
            <th  class="sortable">行权价</th>
            <th>行权距离(%)</th>`
    arr.sort((a: any, b: any)=> a.options[0].expirationDate - b.options[0].expirationDate)
    for (let item of arr) {
      html += `<th>均价(${toDateString(item.options[0].expirationDate)})</th>
      <th>时间价值</th>
      <th>时间价值%</th>
      <th>时间价值年化(%)</th>`
      if (stg == 'coveredCall') {
        html += `<th>整体价值%</th>
        <th>整体价值年化(%)</th>`
      }
    }
    rows = genrateOptionsRows(stg, vals? vals: ['', '', 0.4, 0.4], arr, currentPrice)
  } else {
    let options: any[]
    //单个期权处理
    html = `<thead class="table-info theadFix">
        <tr>`
    
    if (stg == 'call' || stg == 'put') {
      html += `<th class="sortable">行权价</th>
      <th>行权距离(%)</th>
      <th>均价</th>
      <th class="sortable">Volume</th>
      <th class="sortable">Open Interest</th>
      <th>Volatility</th>
      <th>时间价值</th>
      <th>时间价值(%)</th>
      <th>时间价值年化(%)</th>
      <th scope="col">盈亏平衡价</th>
      <th scope="col">盈亏平衡距离(%)</th>
      <th scope="col">杠杆倍数</th>`
    } else if (stg == 'coveredCall') {
      html += `<th class="sortable">行权价</th>
      <th>行权距离(%)</th>
      <th>均价</th>
      <th class="sortable">Volume</th>
      <th class="sortable">Open Interest</th>
      <th>Volatility</th>
      <th>时间价值</th>
      <th>时间价值(%)</th>
      <th>时间价值年化(%)</th>
      <th scope="col">整体收益率(%)</th>
      <th scope="col">整体收益率年化(%)</th>`
    } else if (stg == 'callSpread' || stg == 'putSpread') {
      html += `<th class="sortable">行权价1</th>
      <th>行权距离1(%)</th>
      <th>均价1</th>
      <th class="sortable">行权价2</th>
      <th>行权距离2(%)</th>
      <th>均价2</th>
      <th>价差</th>
      <th>收益率(%)</th>
      <th>收益率年化(%)</th>`
    }

    if (stg == 'call' || stg == 'coveredCall' || stg == 'callSpread') {
      options = arr[0].options[0].calls
    } else {
      options = arr[0].options[0].puts
    }
    rows = genrateOptionRows(stg, vals? vals: ['', '', 0.4, 0.4], options, expireDate*1000, currentPrice)
  }

  html += `</tr>
      </thead>`
  $('#optionTable').innerHTML = html
  let colored = false
  bsTable('optionTable', {data: rows,
    row: (row: any, rowIdx: number): any => {
      let data: any = {}
      let price = row[0]
      if (stg == 'putSpread') {
        price = row[3]
      }
      if (!colored && price > currentPrice) {
        colored = true
        data.class = 'table-primary'
      }
      return data
    }
  })
}

//多个过期时间的期权的处理
function genrateOptionsRows(stg: string, vals: any[], items: any[], currentPrice: number): any[][] {
  let rows: any[][] = []
  let row: any[]
  let maxCol = 2 //最大列数
  let expireTsArr = []
  let xAxisData: number[] = []
  let seriesData: number[][][] = []
  for (let item of items) {
    let options: any[]
    if (stg == 'call' || stg == 'coveredCall') {
      options = item.options[0].calls
    } else {
      options = item.options[0].puts
    }
    let expireTs = item.options[0].expirationDate*1000
    //计算时间年化的时候多考虑3天的买卖时间
    let years = (expireTs - (new Date()).getTime() + 3600*1000*24*3) / (1000 * 3600 * 24 * 365)
    expireTsArr.push(expireTs)
    let sdata: number[][] = []
    for (let option of options) {
      if (!option.ask || !option.bid) {
        continue
      }
      let askPrice = option.ask.raw
      let bidPrice = option.bid.raw
      let tradeTime = option.lastTradeDate.raw*1000
      // 过滤掉不活跃的,超过7天都没成交,或者没有出价的
      let d = new Date(tradeTime)
      if (d.getTime() + 3600 * 1000 * 24 * 7 < (new Date()).getTime() || bidPrice == undefined || askPrice == undefined) {
        continue
      }

      let strikePrice = option.strike.raw
      //最新价格按照报价算，不按照实际成交价格，因为很多情况下可能没有最新成交
      let latestPrice = (askPrice + bidPrice)/2
      if (latestPrice == 0) {
        latestPrice = option.lastPrice.raw
      }
      let timeValue = 0;
      if (stg == 'call' || stg == 'coveredCall') {
        if (currentPrice > strikePrice) {
          timeValue = latestPrice + strikePrice - currentPrice
        } else {
          timeValue = latestPrice
        }
      } else {
        if (currentPrice < strikePrice) {
          timeValue = latestPrice + currentPrice - strikePrice
        } else {
          timeValue = latestPrice
        }
      }

      //先找到对应行
      row = []
      for (let i=0; i < rows.length;i++) {
        if (strikePrice > rows[i][0]) {
          continue
        }
        if (strikePrice < rows[i][0]) {
          //i之前需要插入一个
          rows.splice(i, 0, row)
        } else if (strikePrice == rows[i][0]) {
          row = rows[i]
        }
        break
      }

      if (row.length == 0) {
        //新行
        row.push(strikePrice)
        row.push((100*strikePrice/currentPrice-100).toFixed(2)) 
      }
      
      //补齐空字段
      for (let i = row.length; i < maxCol;i++) {
        row.push('-')
      }
      
      xAxisData.push(strikePrice)
      //收益率
      let ratio = 100*timeValue/currentPrice
      sdata.push([strikePrice, timeValue, ratio, ratio/years])
      row.push(latestPrice.toFixed(2))
      // 时间价值
      row.push(timeValue.toFixed(2))

      // 时间价值%
      row.push(ratio.toFixed(2))
      //时间价值年化
      row.push((ratio/years).toFixed(2))
      if (stg == 'coveredCall') {
        //整体价值%
        ratio = 100*(latestPrice + strikePrice - currentPrice)/currentPrice
        row.push(ratio.toFixed(2))
        //整体价值年化%
        row.push((ratio/years).toFixed(2))
      }
     
      rows.push(row)
    }
    maxCol += 4
    seriesData.push(sdata)
  }

  for (let row of rows) {
    //补齐空字段
    for (let i = row.length; i < maxCol;i++) {
      row.push('-')
    }
  }
  optionsLineChart(currentPrice, expireTsArr, xAxisData, seriesData)
  return rows
}

//单个期权的处理
function genrateOptionRows(stg: string, vals: any[], options: any[], expireTs: number, currentPrice: number): any[][] {
  let rows: any[][] = []
  let row: any[]
  //计算年化多考虑3天的周转时间
  let years = (expireTs - (new Date()).getTime() + 3600*1000*24*3) / (1000 * 3600 * 24 * 365)
  let expireTsArr = [expireTs]
  let xAxisData: number[] = []
  let seriesData: number[][][] = [[]]
  for (let option of options) {
    if (!option.ask || !option.bid) {
      continue
    }
    row = []
    let askPrice = option.ask.raw
    let bidPrice = option.bid.raw
    let tradeTime = option.lastTradeDate.raw*1000
    let d = new Date(tradeTime)
    // 过滤掉不活跃的,超过7天都没成交,或者没有出价的
    if (d.getTime() + 3600 * 1000 * 24 * 7 < (new Date()).getTime() || bidPrice == undefined || askPrice == undefined) {
      continue
    }

    let strikePrice = option.strike.raw
    //最新价格按照报价中值算，不按照实际成交价格，因为很多情况下实际成交价格不能反应最新股价
    let latestPrice = (askPrice + bidPrice)/2
    if (latestPrice == 0) {
      latestPrice = option.lastPrice.raw
    }
    let timeValue = 0
    if (stg == 'call' || stg == 'callSpread' || stg == 'coveredCall') {
      if (currentPrice > strikePrice) {
        timeValue = latestPrice + strikePrice - currentPrice
      } else {
        timeValue = latestPrice
      }
    } else {
      if (currentPrice < strikePrice) {
        timeValue = latestPrice + currentPrice - strikePrice
      } else {
        timeValue = latestPrice
      }
    }
    
    xAxisData.push(strikePrice)
    let ratio = 100*timeValue/currentPrice
    seriesData[0].push([strikePrice, timeValue, ratio, ratio/years])
    row.push(strikePrice)
    //行权距离
    row.push((100*strikePrice/currentPrice-100).toFixed(2))
    //均价
    row.push(latestPrice.toFixed(2))
    if (stg == 'call' || stg == 'put'|| stg == 'coveredCall') {
      if (option.volume) {
        row.push(option.volume.raw)
      } else {
        row.push(0)
      }
      
      if (option.openInterest) {
        row.push(option.openInterest.raw)
      } else {
        row.push(0)
      }
      row.push((100*option.impliedVolatility.raw).toFixed(2))
      // 时间价值
      row.push(timeValue.toFixed(2))
      // 时间价值%
      let ratio: any = 100*timeValue/strikePrice
      row.push(ratio.toFixed(2))
      // 时间价值年化%
      row.push((ratio/years).toFixed(2))
      //不同策略不同部分
      let balancePrice = 0
      if (stg == 'call') {
        balancePrice = strikePrice + latestPrice
      } else {
        balancePrice = strikePrice - latestPrice
      }
      if (stg == 'call' || stg == 'put') {
        row.push(balancePrice.toFixed(2))
        row.push((100*balancePrice/currentPrice - 100).toFixed(2))
        row.push((currentPrice/strikePrice).toFixed(2))
      } else if (stg == 'coveredCall') {
        //整体收益率
        ratio = (100 * (strikePrice - currentPrice + latestPrice)/currentPrice)
        row.push(ratio.toFixed(2))
        //整体收益率年化
        row.push((ratio/years).toFixed(2))
      }
    } else if (stg == 'callSpread' || stg == 'putSpread') {
      let diffPrice = $('#diffPrice').value
      let price2 = (strikePrice - parseFloat(diffPrice)).toFixed(2)
      let row2: any
      for (let i=0; i < rows.length;i++) {
        if (price2 == rows[i][0]) {
          row2 = rows[i]
          break
        }
      }
      if (row2) {
        //找到了
        row2.push(strikePrice)
        //行权距离
        row2.push((100*strikePrice/currentPrice-100).toFixed(2))
        //均价
        row2.push(latestPrice)
        //价差
        let diffValue = latestPrice - row2[2]
        row2.push(diffValue.toFixed(2))
        let ratio = 100*diffValue/(strikePrice - row2[0])
        row2.push(ratio.toFixed(2))
        // 时间价值年化%
        row2.push((ratio/years).toFixed(2))
      }
    }

    rows.push(row)
  }

  optionsLineChart(currentPrice, expireTsArr, xAxisData, seriesData)
  return rows
}

function optionsLineChart(currentPrice: number, expireTsArr: number[], xAxisData: number[], seriesData: number[][][]) {
  xAxisData.sort()
  xAxisData = xAxisData.filter((a, idx)=> xAxisData.indexOf(a) == idx)
  let legendData: string[] = []
  let nowTs = Date.now()
  for (let expireTs of expireTsArr) {
    let leftWeeks = Math.round((expireTs - nowTs)/(1000*3600*24*7)*10)/10
    legendData.push(`${toDateString(expireTs)}(${leftWeeks}周)`)
  }

  let yAxis: any[] = [
    {
      type: 'value',
      scale: true,
      position: 'right',
      //boundaryGap: [0.1, 0.1],
      axisLabel: {
        formatter: '{value}'
      },
    }
  ]

  let series: any = []
  for (let i=0;i<legendData.length;i++) {
    series.push({
      name: legendData[i],
      type: 'line',
      emphasis: {
        scale: false,
      },
      data: seriesData[i]
    })
  }
 
  let id = 'optionsLineChart'
  //let html = `<div id="${id}" style="min-height: 600px; min-width: 300px;"></div>`
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)

  // @ts-ignore
  myChart.setOption({
    title: {
      text: ''
    },
    color: echartsColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params: any) {
        params.sort((a: any,b: any)=> b.value[1] - a.value[1])
        let str = `${params[0].value[0]}(${(100*params[0].value[0]/currentPrice -100).toFixed(2)}%)`
        for (let param of params) { // get data sorted
          let name = param.seriesName
          let value = param.value[1]
          str += `</br>${param.marker}${name}: ${formatKlineValue(value)}/${param.value[2].toFixed(2)}%/年化:${param.value[3].toFixed(2)}%`
        }
        return str
      }
    },
    legend: {
      middle: 10
    },
    xAxis: {
      type: 'value',
      splitLine: {
        show: false
      },
      data: xAxisData
    },
    yAxis: yAxis,
    series: series
  })
}

function onUsOptionChange() {
  let should = 0
  let done = 0
  let arr: any = []
  let dates: string[] = $('#expireDate').val()
  const success = ()=>{
    done++
    if (should == done) {
      genrateOptionTable(arr)
    }
  }

  dates.map((date)=>{
    should++
    fetchYahooOption(code, date, (data: any)=>{
      arr.push(data)
      success()
    })
  })  
}

function usOptionInit() {
  fetchYahooOption(code, '', (data: any)=> {
    updateOptionExpirationDates(data.expirationDates)
  })
}

function initCompanyOption() {
  usOptionInit()
  const ids: string[] = ['#optionStrategy', '#diffPrice']
  ids.map((id)=> {
    $(id).addEventListener("change", ()=>{
      onUsOptionChange()
    })
  })
  
  $("#expireDate").addEventListener("change", onUsOptionChange)
}

function initStg() {
  dateRangeInit()
  codeSelectInit([], 'codes', '选择代码', false)
  bsSelect('stg', {placeholder: '策略选择'})
  $("#codes").addEventListener("change", onStgCodeSelectChange)
  $("#stg").addEventListener("change", onStgSelectChange)
  $("#stgCompute").addEventListener("click", onStgComputeClick)
}

function fillReportDates(id: string, selectIdx: number) {
  let dates = genReportDates(3)
  let html = ''
  let seleted = ' selected'
  let tag = ''
  for (let i = 0; i < dates.length; i++) {
    if (i == selectIdx) {
      tag = seleted
    } else {
      tag = ''
    }
    html += `<option value="${dates[i]}"${tag}>${dates[i]}</option>`
  }
  //@ts-ignore
  document.getElementById(id).innerHTML = html
}

function initCompaniesHolding() {
  fillReportDates('date', 0)
  if (query.date) {
    selectChangeValue('date', query.date)
  }
  refreshCompaniesHoldingRankTable()
  //@ts-ignore
  document.getElementById('date').addEventListener('change', refreshCompaniesHoldingRankTable)
  document.querySelectorAll('a[name="rank"]').forEach((elem)=> {
    elem.addEventListener('click', ()=> {
      //@ts-ignore
      query.rank = elem.dataset.rank
      refreshCompaniesHoldingRankTable()
    })
  })
}

function refreshCompaniesFilter() {
  fetchRequest(`${server}/companies/report/cnt?days=30`, (data)=>{
    refreshCompaniesFilterTable(data)
  })
}

function follow(code: string) {
  let followArr: string[] = []
  let followStr = localStorage.getItem('follow')
    if (followStr) {
      followArr = followStr.split(',')
    }
    followArr.push(code)
    localStorage.setItem('follow', followArr.join(','))
}

function unFollow(code: string) {
  let followArr: string[] = []
  let followStr = localStorage.getItem('follow')
    if (followStr) {
      followArr = followStr.split(',')
    }
    let idx = followArr.indexOf(code)
    if (idx > -1) {
      followArr.splice(idx, 1)
    }

    localStorage.setItem('follow', followArr.join(','))
}

function refreshCompaniesFilterTable(cntMap: any) {
  let filter = ''
  let filterMap: any = {}
  //@ts-ignore
  let vals: string[] = $('#companiesFilter').val()
  if (vals.length > 0) {
    for (let val of vals) {
      let arr = val.split('|')
      filterMap[arr[0]] = arr[1]
    }
    for (let key in filterMap) {
      filter += filterMap[key].replace(/#/g, '"')
    }
  }

  let keys: string[] = []
  // = ['SECURITY_CODE','SECURITY_NAME_ABBR','NEW_PRICE','CHANGE_RATE','CHANGERATE_TY','VOLUME','DEAL_AMOUNT','TOTAL_MARKET_CAP','PE9']
  //@ts-ignore
  $('#companiesFilterTable').querySelectorAll('th').forEach((elem)=>{
    if (elem.dataset.st) {
      //@ts-ignore
      keys.push(elem.dataset.st)
    }
  })
  const pageSize = 50
  bsTable('companiesFilterTable', {
    request: (sortBy: string , asc: boolean, page: string): any => {
      let sr = asc? 1: -1
      let sty = keys.join(',')
      return {
        proxy: true,
        url: 'https://data.eastmoney.com/dataapi/xuangu/list',
        cacheKey: hash(`fetchCompaniesFilter-${sortBy}-${sr}-${sty}-${page}-${filter}`),
        cacheTtl: 3600,
        params: {
          st: sortBy,
          sr: sr,
          ps: pageSize,
          p: page,
          sty: sty,
          filter: filter,
          source: 'SELECT_SECURITIES',
          client: 'WEB'
        }
      }
    },
    pageSize: 1,
    cell: (cell: any, columnIdx: number): any => {
      let data: any = {}
      if (columnIdx == 1) {
        data.cell = `<a href="company.html?code=${cell}" target="_blank">${cell}</a>`
      } else if ([4, 5, 11].includes(columnIdx)) {
        if (cell > 0) {
          data.class = 'text-danger'
        } else if ( cell < 0) {
          data.class = 'text-success'
        }
      } else if (columnIdx == 13) {
        //净资产收益率
        if (cell > 15) {
          data.class = 'text-danger'
        }
      }

      return data
    },
    transResults: (data: any): any => {
      let followArr: any = []
      let followStr = localStorage.getItem('follow')
      if (followStr) {
        followArr = followStr.split(',')
      }
      let rows: any = []
      let rowIdx = (data.result.currentpage-1)*50+1
      for (let item of data.result.data) {
        cacheCodeName(item.SECUCODE, item.SECURITY_NAME_ABBR, true)
        let row: any[] = [rowIdx]
        keys.map((key)=> {
          switch (key) {
            case 'TOTAL_MARKET_CAP':
              row.push(Math.round(item[key]/1e7)/10)
              break
            case 'PE9':
            case 'PBNEWMRQ': 
            case 'JROA':
            case 'ZXGXL':
            case 'ROE_WEIGHT':
            case 'NETPROFIT_YOY_RATIO':
            case 'NETPROFIT_GROWTHRATE_3Y':
              if (typeof item[key] == 'number') {
                row.push(item[key].toFixed(2))
              } else {
                row.push('--')
              }
              break
            case 'CHANGERATE_TY':
              let color = ''
              if (item[key] > 0) {
                color = 'text-danger'
              } else if (item[key] < 0) {
                color = 'text-success'
              }
              row.push(`<a class="${color}" data-bs-toggle="modal" data-bs-target="#perfModal" data-key="${item.SECUCODE}">${item[key]}</a>`)
              break
            case 'SECURITY_NAME_ABBR':
              let star = 'star2'
              if (followArr.includes(item.SECUCODE)) {
                star = 'star'
              }
              row.push(`${item[key]}<a class="ps-1" href="#"><img name="star", data-code=${item.SECUCODE} src="images/${star}.png" /></a>`)
              break
            default:
              row.push(item[key])
              break
          }
        })
        //计算ROE
        let roe = (item['PBNEWMRQ']*100/item['PE9']).toFixed(2)
        let cnt = cntMap[item.SECUCODE] ? cntMap[item.SECUCODE]: 0
        //@ts-ignore
        if ($('#roe').checked && roe < 15) {
          continue
        }

        if ($('#researchReport').checked && cnt < 1) {
          continue
        }

        row.push(roe)
        row.push(cnt)
        rows.push(row)
        rowIdx++
      }
      return rows
    },
    afterRender: ()=>{
      document.querySelectorAll('img[name="star"]').forEach((elem: any)=>{
        elem.addEventListener('click', (e: any)=>{
          e.preventDefault();
          if (elem.getAttribute('src') == 'images/star.png') {
            elem.setAttribute('src', 'images/star2.png')
            unFollow(elem.dataset.code)
          } else {
            elem.setAttribute('src', 'images/star.png')
            follow(elem.dataset.code)
          }
        })
      })
    }
  })
}

function perfModalShow(event: any) {
  const key = event.relatedTarget.dataset.key
  $('#singleChart').innerHTML = '<table id="market" class="table table-sm table-bordered table-hover"></table>'
  fetchKline(key, '',  (data: any)=> {
    generateMarketTable([key])
  })
}

function initCompaniesFilter() {
  bsSelect('companiesFilter', {
    placeholder: '条件筛选...',
    urlParam: 'filter'
  })
  $("#companiesFilter").addEventListener("change", refreshCompaniesFilter)
  $("#researchReport").addEventListener("change", refreshCompaniesFilter)
  $("#roe").addEventListener("change", refreshCompaniesFilter)
  $('#perfModal').addEventListener('show.bs.modal', perfModalShow)
  refreshCompaniesFilter()
}

function initFunds() {
  gsSelectInit('gs', '下拉筛选基金公司')
  $("#fundStyle").addEventListener("change", refreshFundRankTable)
  $("#gs").addEventListener("change", refreshFundRankTable)
  refreshFundRankTable()
}

function initHome() {
  window.location.href = 'indexes.html'
}

function initLogin() {
}


function genPositionPie(rows: any[]) {
  let positionMap: any = {}
  let catMap: any = {}
  let pie1: any[] = []
  let pie2: any[] = []
  for (let row of rows) {
    let code = row[4]
    let currentValue = parseFloat(row[9])
    if (!positionMap[code]) {
      positionMap[code] = {
        name: row[5],
        cat: row[3],
        value: currentValue, //当前市值(CNY)
      }
    } else {
      positionMap[code].value += currentValue
    }
  }

  for (let code in positionMap) {
    if (positionMap[code].value < 1) {
      continue
    }

    let cat = positionMap[code].cat
    if (!catMap[cat]) {
      catMap[cat] = {
        name: cat,
        value: positionMap[code].value,
      }
    } else {
      catMap[cat].value += positionMap[code].value
    }

    pie1.push({
      name: positionMap[code].name,
      value: Math.floor(positionMap[code].value),
    })
  }

  log('catMap', catMap)
  for (let cat in catMap) {
    if (catMap[cat].value < 1) {
      log('catMap[cat].value < 1', cat, catMap[cat].value < 1)
      continue
    }
    pie2.push({
      name: catMap[cat].name,
      value: Math.floor(catMap[cat].value),
    })
  }

  pie1.sort((a, b) => b.value - a.value)
  log(pie1)
  echartsPie('positionPie', '持仓标的', '占比', pie1)

  pie2.sort((a, b) => b.value - a.value)
  log(pie2)
  echartsPie('catPie', '持仓分类', '占比', pie2)
}

function realUrlClick() {
  document.querySelectorAll('a[name="pdf"]').forEach((elem)=>{
    elem.addEventListener('click', ()=>{
      fetchRequest({
        url: 'https://np-cnotice-stock.eastmoney.com/api/content/ann',
        params: {
          cb: 'jQuery112309811366454452475_1699681216755',
          //@ts-ignore
          art_code: elem.dataset.code,
          client_source: 'web',
          page_index: 1,
          _: Date.now()
        }
      }, (data: any)=> {
        //log(data.data.attach_url)
        window.open(`${data.data.attach_url}#zoom=150`)
      })
    })
  })
}

function renderCompanyNoticeTable() {
  //@ts-ignore
  const v: string = document.getElementById('noticeType').value
  const arr = v.split('-')
  const as = code.split('.')
  const stock = as[0]
  const type = as[1]
  let annType = 'A'
  switch (type) {
    case 'SZ':
    case 'SH':
      annType = 'A'
      break
    case 'HK':
      annType = 'H'
      break
    case 'US':
      annType = 'U,U_Pink'
      break
    default:
      log('renderCompanyNoticeTable,wrong code', code)
      return
  }
  const pageSize = 50
  bsTable('companyNoticeTable', {
    request: (sortBy: string , asc: boolean, page: string): string => {
      let params = {
        cb: `jQuery${Date.now()}`,
        sr: -1,
        page_size: pageSize,
        page_index: page,
        ann_type: annType,
        client_source: 'web',
        stock_list: stock,
        f_node: arr[0],
        s_node: arr[1],
      }
      return `https://np-anotice-stock.eastmoney.com/api/security/ann?${queryString(params)}`
    },
    pageSize: pageSize,
    transResults: (data: any): any => {
      log(data)
      let rows: any = []
      for (let item of data.data.list) {
        rows.push([item.notice_date.substring(0, 10), item.columns[0].column_name, `<a href="#${item.art_code}" name="pdf" data-code="${item.art_code}">${item.title}</a>`])
      }
      return rows
    },
    afterRender: realUrlClick
  })
}

function initCompanyNotice() {
  renderCompanyNoticeTable()
  document.getElementById('noticeType')?.addEventListener('change', renderCompanyNoticeTable)
}

function genCompanyReportTable(code: string ,editable: boolean) {
  const pageSize = 50
  const qtype = 'dataeye'
  bsTable('companyReport', {
    request: (sortBy: string , asc: boolean, page: string): string => {
      return `${server}/company/reports?code=${code}&name=${codeNameMap[code]}&page=${page}`
    },
    pageSize: pageSize,
    transResults: (data: any): any => {
      let rows: any = []
      let items = data
      for (let i=0;i< items.length;i++) {
        let colorClss = ''
        let predict = '预估'
        if (items[i].detail) {
          predict = '修改'
          colorClss = ' class="link-secondary"'
        }
        let reportLink = ''
        if (items[i].url) {
          reportLink = `<a href="${items[i].url}#zoom=150" target="_blank">${items[i].title}</a>`
        } else if (items[i].code.endsWith('.HK') || items[i].code.endsWith('.US')) {
          reportLink = `<a href="#${items[i].infoCode}" name="infoCode" data-code="${items[i].infoCode}">${items[i].title}</a>`
        } else {
          reportLink = `<a href="https://pdf.dfcfw.com/pdf/H3_${items[i].infoCode}_1.pdf#zoom=150" target="_blank">${items[i].title}</a>`
        }
        let edit = editable? `<a name="predictLink"${colorClss} href="company-report-predict.html?code=${code}&id=${items[i].id}&info=${encodeURIComponent(JSON.stringify(items[i], null))}" target="_blank">${predict}</a>`: ''
        rows.push([i+1, toDateString(items[i].ts), reportLink, edit, items[i].rate, items[i].org, items[i].pages])
      }
      return rows
    },
    afterRender: ()=>{
      document.querySelectorAll("a[name='infoCode']").forEach((elem) => {
        elem.addEventListener("click", () => {
          //@ts-ignore
          fetchReportUrl(qtype, elem.dataset.code, (url: string|null)=> {
            if (url) {
              window.open(url, "_blank")
            }
          })
        })
      })
    }
  })
}

function initCompanyReport() {
  //@ts-ignore
  document.getElementById('token').value = localStorage.getItem('token')
  //@ts-ignore
  document.getElementById('saveToken').addEventListener('click', ()=> {
    //@ts-ignore
    localStorage.setItem('token', document.getElementById('token').value)
  })

  fetchRequest(`${server}/editable?token=${localStorage.getItem('token')}`, (data: any)=> {
    genCompanyReportTable(code, data.editable)
  })
}

function fetchCompanyNews() {
  const pageSize = 10
  let callback = function (data: any) {
    bsCards('news', {
      pageSize: pageSize,
      request: (page: string)=>{
        let ts = Date.now()
        let keyword = codeNameMap[code].split('.')[0]
        return {
          url: 'https://search-api-web.eastmoney.com/search/jsonp',
          params: {
            cb: `jQuery35105693249088895898_${ts}`,
            param: `{"uid":"","keyword":"${keyword}","type":["cmsArticleWebOld"],"client":"web","clientType":"web","clientVersion":"curr","param":{"cmsArticleWebOld":{"searchScope":"default","sort":"default","pageIndex":${page},"pageSize":${pageSize},"preTag":"<em>","postTag":"</em>"}}}`,
            _: ts
          }
        }
      },
      transResults: (data: any)=>{
        return data.result.cmsArticleWebOld
      }
    })
  }

  fetchCodeNames([code], callback)
}

function initCompanyNews() {
  fetchCompanyNews()
}

function renderReports() {
  let qtype = $('#qtype').value
  //@ts-ignore
  let industryCode = document.getElementById('industryCode').value
  const pageSize = 50
  bsTable('reports', {
    request: (sortBy: string , asc: boolean, page: string): any => {
      let url = ''
      switch (qtype) {
        case '0':
        case '1':
          url = 'https://reportapi.eastmoney.com/report/list'
          break
        case '2':
        case '3':
        case '4':
          url = 'https://reportapi.eastmoney.com/report/jg'
          break
        default:
          log('renderReports,wrong qtype', qtype)
          return ''
      }
      return {
        url: url,
        priority: 100,
        params: {
          cb: 'jQuery',
          industryCode: qtype == '1'? industryCode :'*',
          pageSize: pageSize,
          industry: '*',
          rating: '*',
          ratingChange: '*',
          beginTime: '2022-01-01',
          endTime: '2040-12-31',
          pageNo: page,
          fields: '',
          qType: qtype,
          orgCode: '',
          code: '*',
          rcode: '',
          _: Date.now()
        }
      }
    },
    pageSize: pageSize,
    transResults: (data: any): any => {
      let rows: any = []
      let name = ''
      let infoCode = ''
      for (let item of data.data) {
        let reportLink = ''
        switch (qtype) {
          case '0':
            let clss = ''
            if (cache['filterList'] && cache['filterList'].length > 0 && cache['filterList'].includes(genFullCode(item.stockCode))) {
              clss = 'text-danger'
            }
            name = `<a class="${clss}" href="company.html?code=${genFullCode(item.stockCode)}" target="_blank">${item.stockName}</a>`
            reportLink = `<a class="${clss}" href="https://pdf.dfcfw.com/pdf/H3_${item.infoCode}_1.pdf#zoom=150" target="_blank">${item.title}</a>`
            break
          case '1':
            name = item.industryName
            reportLink = `<a href="https://pdf.dfcfw.com/pdf/H3_${item.infoCode}_1.pdf#zoom=150" target="_blank">${item.title}</a>`
            break
          case '2':
          case '3':
          case '4':
            infoCode = item.encodeUrl
            break
          default:
            alert(`wrong qtype:${qtype}`)
            return
        }
        if (!reportLink) {
          reportLink = `<a href="#${infoCode}" name="infoCode" data-code="${infoCode}">${item.title}</a>`
        }
        rows.push([item.publishDate.substring(0, 10), item.orgSName, reportLink, name, item.sRatingName?item.sRatingName: '', item.attachPages?item.attachPages: ''])
      }
      return rows
    },
    afterRender: ()=>{
      document.querySelectorAll("a[name='infoCode']").forEach((elem) => {
        elem.addEventListener("click", () => {
          //@ts-ignore
          fetchReportUrl(qtype, elem.dataset.code, (url: string|null)=> {
            if (url) {
              window.open(`${url}#zoom=150`, "_blank")
            }
          })
        })
      })
    }
  })
}

//根据选股器功能选择股票代码和名字
function filterCompanies(sortBy: string, callback: Callback) {
  let filter = ''
  let filterMap: any = {}
  let vals: string[] = $('#companiesFilter').val()
  if (vals.length < 1) {
    callback([])
    return
  }

  for (let val of vals) {
    let arr = val.split('|')
    filterMap[arr[0]] = arr[1]
  }
  for (let key in filterMap) {
    filter += filterMap[key].replace(/#/g, '"')
  }

  let page = 1
  let sr = -1
  let sty = 'SECUCODE,SECURITY_NAME_ABBR'
  fetchRequest({
    proxy: true,
    url: 'https://data.eastmoney.com/dataapi/xuangu/list',
    cacheKey: hash(`fetchCompaniesFilter-${sortBy}-${sr}-${sty}-${page}-${filter}`),
    cacheTtl: 3600,
    params: {
      st: sortBy,
      sr: sr,
      ps: 500,
      p: page,
      sty: sty,
      filter: filter,
      source: 'SELECT_SECURITIES',
      client: 'WEB'
    }
  }, (data: any)=> {
    callback(data.result.data)
  })
}

function filterCompaniesReports() {
  filterCompanies('TOTAL_MARKET_CAP', (data: any)=>{
    let filterList: any = []
    for (let item of data) {
      filterList.push(item.SECUCODE)
    }
    cache['filterList'] = filterList
    renderReports()
  })
}

function reportTypeOnChange() {
  $('#industryCode-dropdown')?.classList.add('d-none')
  $('#companiesFilter-dropdown')?.classList.add('d-none')
  let qtype = $('#qtype').value
  if (qtype == '1') {
    //行业研报    
    bsSelect('industryCode', {
      placeholder: '行业选择...',
      cache: true,
      request: (term: string)=>{
        return {
          url: `https://reportapi.eastmoney.com/report/bk?bkCode=016&cb=jQuery&_=${Date.now()}`,
          cacheKey: 'industryCode',
          cacheTtl: 3600
        }
      },
      transResults: (data: any, term: string)=>{
        let result: any = []
        for (let item of data.data) {
          result.push({id: item.bkCode, name: item.bkName})
        }
        return result
      }
    })
    //@ts-ignore
    document.querySelector('#industryCode').addEventListener('change', renderReports)
  } else if (qtype == '0') {
    //公司研报
    bsSelect('companiesFilter', {
      placeholder: '条件筛选...',
      urlParam: 'filter'
    })
    $("#companiesFilter").addEventListener("change", filterCompaniesReports)
    filterCompaniesReports()
  }

  renderReports()
}

//select下拉选择值
function selectChangeValue(id: string, value: string) {
  //@ts-ignore
  let select: HTMLSelectElement = document.getElementById(id)
  select.value = value
  select.querySelectorAll('option').forEach((elem)=> {
    if (elem.value == value) {
      elem.setAttribute('selected', '')
    }
  })
}

function initReports() {
  if (query.qtype) {
    selectChangeValue('qtype', query.qtype)
  }

  if (query.code) {
    selectChangeValue('code', query.code)
  }
  
  //@ts-ignore
  document.getElementById('qtype').addEventListener('change', reportTypeOnChange)
  reportTypeOnChange()
}

function fortmatConpanyReportPredict() {
  //@ts-ignore
  let content = document.getElementById('content').value.trim()
  let lines: string[] = content.split('\n')
  let years: string[] = []
  let incomes: string[] = []
  let netProfits: string[] = []
  let unit = ''
  //先预处理一下数据
  let arr: string[][] = []
  for (let i=0;i<lines.length;i++) {
    let line = lines[i].trim()
    if (!unit) {
      if (line.includes('百万')) {
        unit = 'm'
      } else if (line.includes('十亿')) {
        unit = 'b'
      } else if (line.includes('亿元')) {
        unit = 'y'
      } else if (line.includes('千元')) {
        unit = 'k'
      }
    }

    if (line.includes('%')) {
      //不要增长率
      continue
    }

    if (line.length < 1) {
      continue
    } else if (line.length == 1) {
      //只有一个字符的，跳过不算
      continue
    }
    let item: string[] = line.replace(/,/g, '').split(/\s+/)
    if (i == 0) {
      item.map((year)=> {
        year = year.toUpperCase().replace(/[a-zA-Z-]*(20)?(2[0-9])+[a-zA-Z]*/, '20$2')
        if (year.startsWith('20')) {
          years.push(year)
        }
      })
      continue
    }

    //处理财务负数
    for (let i=0;i < item.length;i++) {
      if (/^[\(-]?[\d,\.]+\)?$/.test(item[i])) {
        let value = item[i]
        if (value && value.startsWith('(') && value.endsWith(')')) {
          item[i] = '-'+value.substring(1, value.length-1)
        }
      }
    }

    if (item.length < 2 && arr.length > 0) {
      //只有一个字段，合并到上一行
      arr[arr.length -1].push(item[0])
    } else {
      arr.push(item)
    }
  }

  if (!unit) {
    unit = 'm'
  }

  for (let item of arr) {
    let key = item[0]
    for (let i=1;i < item.length;i++) {
      if (/^[\(-]?[\d,\.]+\)?$/.test(item[i])) {
        //遇到数字停止
        key = key.toLowerCase()
        if (incomes.length == 0 && (key.includes('revenue') || key.includes('收入') || key.includes('总收入') || key.includes('营收') || key.includes('销售收入'))) {
          incomes = item.slice(i)
        } else if (key.includes('营业收入') || key.includes('营业总收入')) {
          incomes = item.slice(i)
        } else if (netProfits.length == 0 && (key.includes('净利润') || key.includes('net profit'))) {
          netProfits = item.slice(i)
        } else if ((key.includes('归母') || key.includes('归属母公司')) && key.includes('净利') && !key.includes('同比') && !key.includes('增长率')) {
          //优先归母，不要同比
          netProfits = item.slice(i)
        }
        break
      }
      key += ' ' + item[i]
    }
  }

  genCompanyReportPredictTable(incomes, netProfits, unit, years)
}

function genCompanyReportPredictTableByDetail(detail: any) {
  let detailMap = typeof detail == 'string'? JSON.parse(detail): detail
  let incomes: string[] = []
  let netProfits: string[] = []
  let unit = detailMap.u ? detailMap.u: 'm'
  let years: string[] = []
  //先把年弄出来
  for (let key in detailMap) {
    if (key.startsWith('ic-') || key.startsWith('np-')) {
      years.push(key.substring(3))
    }
  }
  years = years.filter((v,i)=> years.indexOf(v) == i)
  years.sort()
  for (let year of years) {
    let key1 = `ic-${year}`
    let key2 = `np-${year}`
    if (key1 in detailMap) {
      incomes.push(detailMap[key1])
    }
    if (key2 in detailMap) {
      netProfits.push(detailMap[key2])
    }
  }
  genCompanyReportPredictTable(incomes, netProfits, unit, years)
}

function genCompanyReportPredictTable(incomes: string[], netProfits: string[], unit: string, years: string[]) {
  let subjectMap: any = {
    'ic': '营收',
    'np': '净利',
  }
  let html = `<thead class="table-info">
        <tr>
          <th scope="col">项目</th>`
  for (let year of years) {
    html += `<th scope="col">${year}</th>`
  }
  html += `</tr>
  </thead><tbody>`
  for (let subject in subjectMap) {
    let arr = subject == 'ic'? incomes: netProfits
    if (arr.length < 1) {
      continue
    }
    html += '<tr>'
    //let row: string[] = [subjectMap[subject]]
    html += `<td>${subjectMap[subject]}</td>`
    for (let i=0;i < years.length; i++) {
      let value = arr[i]
      html += `<td><input id="${subject}-${years[i]}" value="${value}" name="subject" class="form-control form-control-sm" type="text"></td>`
    }
    html += '</tr>'
    //rows.push(row)
  }
  html += '</tbody>'
  bsTable('companyReportPredict', {data: html})
  selectChangeValue('unit', unit)
}

function saveCompanyReportPredict() {
  let detailMap: any = {}
  //@ts-ignore
  detailMap.u = document.getElementById('unit').value

  document.querySelectorAll("input[name='subject']").forEach((elem) => {
    //@ts-ignore
    if (elem.value) {
      //@ts-ignore
      detailMap[elem.id] = elem.value
    } else {
      delete detailMap[elem.id]
    }
  })

  let body = JSON.parse(query.info)
  //@ts-ignore
  body.url = document.getElementById('iframe').src.split('#')[0]
  body.detail = detailMap

  fetchRequest({
    url: `${server}/company/report/update`,
    headers: {
      'Authorization': localStorage.getItem('token')
    },
    data: body
  }, (data: any)=> {
    alert(JSON.stringify(data), 'success')
  })
}

//对公司研报进行预测总结
function initCompanyReportPredict() {
  let info = JSON.parse(query.info)
  //@ts-ignore
  document.getElementById('token').value = localStorage.getItem('token')
  //@ts-ignore
  document.getElementById('saveToken').addEventListener('click', ()=> {
    //@ts-ignore
    localStorage.setItem('token', document.getElementById('token').value)
  })

  let zoom = '#zoom=125'
  let qtype = '0'
  if (info.code.endsWith('.HK') || info.code.endsWith('.US')) {
    zoom = ''
    qtype = 'dataeye'
  }

  if (info.url) {
    //@ts-ignore
    document.getElementById('iframe').setAttribute('src', `${info.url}${zoom}`)
    document.getElementById('reportUrl')?.setAttribute('href', `${info.url}${zoom}`)
  } else {
    fetchReportUrl(qtype, info.infoCode, (url: string|null)=> {
      //@ts-ignore
      document.getElementById('iframe').setAttribute('src', `${url}${zoom}`)
      document.getElementById('reportUrl')?.setAttribute('href', `${url}${zoom}`)
    })
  }

  $('#reportDate').value = toDateString(info.ts*1000)
  $('#reportDate').addEventListener('change', (e: any)=> {
    let ts = e.target.value
    fetchRequest({
      url: `${server}/company/report-ts/update`,
      data: {
        id: info.id, 
        ts: new Date(e.target.value).getTime()
      }
    }, (data)=>{
      alert(data)
    })
  })

  if (info.detail) {
    genCompanyReportPredictTableByDetail(info.detail)
  }
  
  //@ts-ignore
  document.getElementById('format').addEventListener('click', fortmatConpanyReportPredict)
  //@ts-ignore
  document.getElementById('save').addEventListener('click', saveCompanyReportPredict)
}

function genCompanyValuationTable(code: string, predictParentNetProfit: number, year: number) {
  let reasonablePeCache = localStorage.getItem(`reasonablePe-${code}`)
  let html = `<thead>
        <tr>
          <th colspan="4" scope="col" class="table-primary">当前(${toDateString(Date.now())})</th>
          <th colspan="3" scope="col" class="table-info">${year}预估</th>
          <th colspan="3" scope="col" class="table-danger">估值</th>
        </tr>

        <tr>
          <th scope="col" class="table-primary">市值(亿元)</th>
          <th scope="col" class="table-primary">股价</th>
          <th scope="col" class="table-primary">近四个季度归母净利(亿元)</th>
          <th scope="col" class="table-primary">PE-TTM</th>

          <th scope="col" class="table-info">归母净利润(亿元)</th>
          <th scope="col" class="table-info">增长率</th>
          <th scope="col" class="table-info">PE-TTM</th>

          <th scope="col" class="table-danger">合理PE</th>
          <th scope="col" class="table-danger">对应股价</th>
          <th scope="col" class="table-danger">增长空间</th>
        </tr>
    </thead>`

  let should = 3
  let done = 0
  let codes = [code]
  let currency = 1
  let success = function (codes: string[]) {
    done++
    if (done != should) {
      return
    }

    let klineKey = `${code}normal`
    let shareKey = `${code}-sc`
    let reportKey = `${code}-fsi`
    let shareIdx = 0
    let reportIdx = 0
    let price = cache[klineKey][cache[klineKey].length-1][1]
    let totalShare = cache[shareKey][shareIdx].totalShares
    //市值、净利润单位都是亿元
    let marketValue = price * totalShare * currency/1e8
    let parentNetprofit = sum4SeasonData(cache[reportKey], reportIdx, 'parentNetprofit')/1e8
    let pettm = (marketValue / parentNetprofit).toFixed(2)
    let reasonablePe = reasonablePeCache ? parseFloat(reasonablePeCache): pettm
    //@ts-ignore
    let predictPrice = predictParentNetProfit*reasonablePe*1e8/ totalShare
    html += `<tr>
      <td>${marketValue.toFixed(2)}</td>
      <td>${price}</td>
      <td>${parentNetprofit.toFixed(2)}</td>
      <td>${pettm}</td>
      <td><input id="predictParentNetProfit" maxlength="9" size="2" value="${predictParentNetProfit}" name="variable" class="form-control form-control-sm text-center" type="text"></td>
      <td>${(predictParentNetProfit*100/parentNetprofit-100).toFixed(2)}%</td>
      <td>${(marketValue / predictParentNetProfit).toFixed(2)}</td>
      <td><input id="reasonablePe" maxlength="3" size="2" value="${reasonablePe}" name="variable" class="form-control form-control-sm text-center" type="text"></td>
      <td>${predictPrice.toFixed(2)}</td>
      <td>${(predictPrice*100 / price -100).toFixed(2)}%</td>
    </tr>`
    //@ts-ignore
    document.getElementById('companyValuation').innerHTML = html
    document.querySelectorAll("input[name='variable']").forEach((elem) => {
      elem.addEventListener("change", function(){
        //@ts-ignore
        let predictParentNetProfit = document.getElementById('predictParentNetProfit').value
        //@ts-ignore
        let reasonablePe = document.getElementById('reasonablePe').value
        log('change', predictParentNetProfit, reasonablePe)
        if (isNaN(predictParentNetProfit)|| isNaN(reasonablePe)) {
          return
        }
        localStorage.setItem(`reasonablePe-${code}`, reasonablePe)
        genCompanyValuationTable(code, predictParentNetProfit, year)
      })
    })
  }

  fetchCodesData(codes, fetchFinanceIncome, success)
  fetchKlines(codes, 'normal', success)
  fetchShareChanges(codes, success)

  const currencyData = (data: any)=>{
    currency = data
    success(codes)
  }
  if (code.endsWith('.HK')) {
    should++
    fetchCurrency('HKD', currencyData)
  } else if (code.endsWith('.US')) {
    should++
    fetchCurrency('USD', currencyData)
  }
}

//ts：报告时间戳(秒)
function generateCompanyResearchTable(code: string, page: number) {
  let cacheKey = `${code}-${page}-cr`
  //const years: number[] = [2021, 2022, 2023, 2024, 2025]
  let years: number[] = []
  const tps = [
    ['ic', '营收'], 
    ['np', '净利']
  ]
  //散点图数据map
  let scatterMap: any = {}
  let scatterXAxis: string[] = []
  //@ts-ignore
  let startTs = parseInt(document.getElementById('startDate').value)
  const thisYear = new Date().getFullYear()
  let reports: any = []
  for (let item of cache[cacheKey]) {
    if (!item.detail) {
      continue
    }

    let needUpdate =false
    let detailMap = JSON.parse(item.detail)
    if (typeof detailMap == 'string') {
      detailMap = JSON.parse(detailMap)
      needUpdate = true
    }

    //将单位转换到亿
    let mul = 1
    if (detailMap.u == 'y') {
      mul = 1
    } else if (detailMap.u == 'b') {
      mul = 10
    } else if (detailMap.u == 'm') {
      mul = 0.01
    } else if (detailMap.u == 'k') {
      mul = 0.00001
    } else {
      log('generateCompanyResearchTable, wrong unit', item)
      //默认按照百万元单位处理
      detailMap.u = 'm'
      mul = 0.01
      needUpdate = true
    }
    
    //处理年份后缀
    for (let key in detailMap) {
      if (key.endsWith('A') || key.endsWith('E')) {
        let key2 = key.substring(0, key.length-1)
        detailMap[key2] = detailMap[key]
        delete detailMap[key]
        needUpdate = true
      }
    }

    //填充年信息
    if (!years.length) {
      for (let key in detailMap) {
        if (key.startsWith('np-') ) {
          years.push(parseInt(key.substring(3)))
        }
      }
      years.sort()
    }

    if (needUpdate) {
      fetchRequest({
        url: `${server}/company/report/update`,
        headers: {
          'Authorization': localStorage.getItem('token')
        },
        data: {
          id: item.id, 
          detail: detailMap
        }
      }, (data: any)=> {
        log('needUpdate', item, data)
      })
    }
    
    let report: any = {ts: item.ts, org: item.org, detailMap: {}}
    for (let i=0; i < years.length;i++) {
      let yearKey = years[i]
      for (let j=0;j< tps.length;j++) {
        let tpKey = `${tps[j][0]}-${yearKey}`
        let scatterKey = `${tps[j][1]}-${yearKey}`
        if (detailMap[tpKey]) {
          //单元转换到亿，保留2位小数
          report.detailMap[tpKey] = (parseFloat(detailMap[tpKey].replace(/,/g, ''))*mul).toFixed(2)
          if (yearKey >= thisYear) {
            if (!scatterMap[scatterKey]) {
              scatterMap[scatterKey] = []
            }
            let dateStr = toDateString(item.ts)
            scatterXAxis.push(dateStr)
            scatterMap[scatterKey].push([dateStr, report.detailMap[tpKey], report.org])
          }
        }
      }
    }
    reports.push(report)
  }

  scatterXAxis = scatterXAxis.filter((v, i)=> scatterXAxis.indexOf(v)==i)
  genCompanyValuePredictScatterChart('companyValuePredictChart', scatterXAxis.sort(), scatterMap)

  let stat: any[][] = []
  let orgMap: any = {}
  let seriesMap: any = {}
  let npMap: any = {} //实际净利润
  let rows: any = []
  for (let report of reports) {
    if (orgMap[report.org] || report.ts < startTs) {
      continue
    }
    //相同机构只保留最新研报
    orgMap[report.org] = 1
    let row = [toDateString(report.ts), report.org]
    let seriesName = `${row[0]}(${row[1]})`
    seriesMap[seriesName] = []
    for (let i=0; i < years.length;i++) {
      let yearKey = years[i]
      for (let j=0;j< tps.length;j++) {
        let tpKey = `${tps[j][0]}-${yearKey}`
        let v = report.detailMap[tpKey]
        if (v) {
          row.push(v)
          //记录统计值
          if (!stat[2*i+j]) {
            stat[2*i+j] = []
          }
          stat[2*i+j].push(v)
        } else {
          row.push('-')
        }
        if (tps[j][0] == 'np') {
          seriesMap[seriesName].push(v)
          npMap[years[i]] = v
        }
        
      }
    }
    rows.push(row)
  }

  for (let name in seriesMap) {
    if (!seriesMap[name][years.length-1]) {
      seriesMap[name].pop()
      continue
    }
    if (!seriesMap[name][0]) {
      seriesMap[name][0] = npMap[years[0]]
    }
  }
  
  let mr: any = []
  //最小、最大、平均、中位数
  for (let i=0; i < years.length;i++) {
    if (i == 0) {
      mr[0] = ['-', '最小']
      mr[1] = ['-', '最大']
      mr[2] = ['-', '平均值']
      mr[3] = ['-', '中位值']
    }
    if (!stat[2*i]) {
      for (let j=0;j< 4;j++) {
        mr[j].push('-', '-')
      }
      continue
    }
    stat[2*i].sort((a, b)=> a-b)
    stat[2*i+1].sort((a, b)=> a-b)
    mr[0].push(stat[2*i][0], stat[2*i+1][0])
    mr[1].push(stat[2*i][stat[2*i].length-1], stat[2*i+1][stat[2*i+1].length-1])
    let sum = 0
    stat[2*i].map((v)=> sum += v)
    mr[2].push(Math.round(sum/stat[2*i].length))
    sum = 0
    stat[2*i+1].map((v)=> sum += v)
    mr[2].push(Math.round(sum/stat[2*i+1].length))
    let mid = Math.floor(stat[2*i].length/2)
    mr[3].push(stat[2*i][mid], stat[2*i+1][mid])
  }

  //生成估值表
  genCompanyValuationTable(code, mr[3][mr[3].length-1], years[years.length-1])

  //折线图
  //@ts-ignore
  document.getElementById('netProfitPredictTitle').textContent = `${codeNameMap[code]}(${code})-净利润预估`
  //@ts-ignore
  echartsMultiLine('netProfitPredict', '预估净利润(百万元)', years, seriesMap)

  for (let j=0;j< 4;j++) {
    rows.unshift(mr[j])
  }

  //计算增长率
  for (let row of rows) {
    for (let i=row.length-1; i >3;i--) {
      if (row[i] != '-' && row[i-2] != '-') {
        row[i] = `${row[i]}<br>${((row[i]-row[i-2])*100/row[i-2]).toFixed(2)}%`
      }
    }
  }

  let yearHeader = ''
  years.map((year)=> {
    yearHeader += `<th scope="col">营收${year}</th><th scope="col">归母净利${year}</th>`
  })
  let html = `<thead class="table-info">
        <tr>
          <th scope="col">日期</th>
          <th scope="col">研报机构</th>
          ${yearHeader}
        </tr>
    </thead>`

  let tableid = 'companyResearch'
  //@ts-ignore
  document.getElementById(tableid).innerHTML = html
  //@ts-ignore
  bsTable(tableid, {data: rows})
}

//生成估值预测散点图(按照时间)
function genCompanyValuePredictScatterChart(id: string, xAxis: string[], scatterMap: any) {
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)
  let series: any = []
  let legend: string[] = []
  let yAxisIndex = -1
  for (let key in scatterMap) {
    yAxisIndex = (yAxisIndex+1) % 2
    legend.push(key)
    series.push({
      symbolSize: 10,
      yAxisIndex: yAxisIndex,
      name: key,
      type: 'scatter',
      data: scatterMap[key]
    })
  }
  myChart.setOption({
    title: {
      text: '估值预测趋势'
    },
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any) {
        params.sort((a: any,b: any)=> b.value[1] - a.value[1])
        let str = params[0].value[0] + '</br>'
        for (let param of params) {
          let name = param.seriesName
          str += `${param.marker}${name}(${param.value[2]}): ${param.value[1]}</br>`
        }

        return str
      }
    },
    legend: {},
    grid: {
      left: "3%",
      right: "7%",
      bottom: "3%",
      containLabel: true
    },
    xAxis: {
      type: 'category',
      data: xAxis
    },
    yAxis: [{
      type: "value",
      name: '营收(亿)',
      scale: true,
      splitLine: {
        show: false
      }
    },{
      type: "value",
      name: '利润(亿)',
      scale: true,
      splitLine: {
        show: false
      }
    }],
    series: series
  })
}

function echartsMultiLine(id: string, labelSuffix: string, xAxis: string[], seriesMap: Record<string, number[]>) {
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom);
  let series: any[] = []
  for (let name in seriesMap) {
    series.push({
      name: name,
      type: 'line',
      data: seriesMap[name]
    })
  }

  //log('min,max', ymin, ymax)
  // @ts-ignore
  myChart.setOption({
    tooltip: {
      trigger: 'axis',
      formatter: function (params: any) {
        let str = `${params[0].axisValueLabel}-${labelSuffix}</br>`
        let map = new Map()
        for (let i = 0; i < params.length; i++) {
          map.set(i, params[i].value)
        }

        // 数值倒序
        map[Symbol.iterator] = function* () {
          yield* [...this.entries()].sort((a, b) => b[1] - a[1])
        }

        for (let [key, v] of map) {
          let name = params[key].seriesName
          str += `${params[key].marker}${name}: ${params[key].value}${params[key].dataIndex == 0 ?'':'/增长率:' + (seriesMap[name][params[key].dataIndex]*100/seriesMap[name][params[key].dataIndex-1]-100).toFixed(2)+'%'}</br>`
        }

        return str
      }
    },
    legend: {
      top: 0,
    },
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
      type: 'category',
      boundaryGap: false,
      data: xAxis
    },
    yAxis: {
      type: 'value',
      min: function (value: any) {
        return Math.floor(value.min*0.97)
      },
      max: function (value: any) {
        return Math.ceil(value.max*1.03)
      }
    },
    series: series
  })
}

function genReportSelectOptions(code: string, page: number) {
  let cacheKey = `${code}-${page}-cr`
  let orgMap: any = {}
  let arr: number[][] = []
  let num = 0
  for (let item of cache[cacheKey]) {
    if (!item.detail || orgMap[item.org]) {
      continue
    }

    orgMap[item.org] = 1
    num++
    if (arr.length > 0 && arr[arr.length-1][0] == item.ts) {
      arr[arr.length-1][1] = num
    } else {
      arr.push([item.ts, num])
    }
  }

  if (arr.length == 0) {
    return
  }

  let html = ''
  let selected = false
  let selectStr = ''
  for (let i=0;i< arr.length; i++) {
    if (!selected && (arr[i][1] > 9 || i == arr.length-1)) {
      selectStr = 'selected="selected"'
      selected = true
    } else {
      selectStr = ''
    }
    html += `<option value="${arr[i][0]}" ${selectStr}>${toDateString(arr[i][0])}(累计最近${arr[i][1]}份研报)</option>`
    selectStr = ''
  }
  //@ts-ignore
  let elem: HTMLElement = document.getElementById('startDate')
  elem.innerHTML = html
  elem.addEventListener('change', ()=> {
    generateCompanyResearchTable(code, page)
  })
}

//估值
function initCompanyValuation() {
  fetchCompanyReports(code, 0, (code: string, page: number)=>{
    genReportSelectOptions(code, page)
    generateCompanyResearchTable(code, page)
  })
}

function drawChartOnChange() {
  const xKey = 'date'
  const chartType = $('#chartType')
  let yUnit = ''
  let yKeys: string[] = []
  let input = $('#input').value.trim()
  let lines = input.split('\n')
  let code = 'x'
  let dates: string[] = []
  let data: any = {}
  for (let i=0;i < lines.length; i++) {
    let arr: string[] = lines[i].split('\t')
    if (i == 0) {
      code = arr[0]
      data[code] = []
      dates = arr.slice(1, arr.length)
    } else {
      let key = arr[0]
      yKeys.push(key)
      let idx = 0
      for (let j=arr.length-1;j> 0;j--) {
        //倒序
        if (!data[code][idx]) {
          data[code][idx] = {}
        }
        data[code][idx][xKey] = dates[j-1]
        let value = parseFloat(arr[j].replace(/,/g, ''))
        if (isNaN(value)) {
          data[code][idx][key] = 0
        } else {
          data[code][idx][key] = value
        }
        idx++
      }
    }
  }

  genBarLineCompareChart('chart', [code], data, codeNameMap, yKeys, yKeys, xKey, yUnit, dates.length)
}

function formatFinanceRows(input: string) {
  let lines = input.trim().split('\n')
  let rows: any = []
  for (let i=0;i < lines.length; i++) {
    let needMerge = false
    let arr: string[] = lines[i].split(/\t+/g)
    if (arr.length < 2) {
      arr = lines[i].split(/\s+/g)
      needMerge = true
    }
    if (i == 0) {
      rows.push(arr)
    } else {
      //描述字段
      let row: any = []
      for (let j=0;j< arr.length;j++) {
        if (j == 0) {
          row.push(arr[j])
          continue
        }

        let value = arr[j]
        //先判断是否正常的财报数字
        if (/^[\(-]?[\d,\.]+\)?[%]?$/.test(value)) {
          //数字
          needMerge = false
          //处理财务负数
          if (value.startsWith('(') && value.endsWith(')')) {
            value = '-'+value.substring(1, value.length-1)
          }
          if (value.endsWith('%')) {
            value = value.substring(0, value.length-1)
          }
          row.push(value)
        } else if (needMerge) {
          row[0] += value
        } else {
          row.push(value)
        }
      }
      rows.push(row)
    }
  }
  return rows
}

function replaceInputData(rows: any) {
  let str = ''
  rows.map((row: any)=> str += row.join('\t')+'\n')
  $('#input').value = str
}

function drawChartRenderTable(rows: any) {
  bsTable('preview', {data: rows,
    row: (row: any, rowIdx: number)=>{
      let data: any = { row}
      if (rowIdx == 0) {
        data.class = 'table-dark'
      }
      return data
    },
    cell: (cell: any, columnIdx: number)=>{
      let data: any = { cell}
      if (columnIdx == 0) {
        data.class = 'table-info'
      }
      return data
    }
  })
}
function drawChartFormatData() {
  let rows = formatFinanceRows($('#input').value)
  replaceInputData(rows)
  drawChartRenderTable(rows)
}

//行列转换
function transformRow2Colum() {
  let rows = formatFinanceRows($('#input').value)
  let newRows: any = []
  for (let i=0; i< rows.length;i++) {
    for (let j=0; j< rows[i].length;j++) {
      if (!newRows[j]) {
        newRows[j] = []
      }
      newRows[j][i] = rows[i][j]
    }
  }
  replaceInputData(newRows)
  drawChartRenderTable(newRows)
}

function initDrawChart() {
  $('#format').addEventListener('click', drawChartFormatData)
  $('#transform').addEventListener('click', transformRow2Colum)
  $('#drawChart').addEventListener('click', drawChartOnChange)
  $('#chartType').addEventListener('change', drawChartOnChange)
  $('#compareType').addEventListener('change', drawChartOnChange)
  bsRadioButtons('compareType')
}

function guessFinanceData() {
  let reportMap: any = {
  }
  let success = function (codes: string[]) {
    guessReportDataUs(reportMap, codes, 'fsi')
  }

  fetchCodesData(Object.keys(reportMap), fetchFinanceIncome, success)
}


function guessReportDataUs(reportMap: any, codes: string[], sufix: string) {
  let nameMap: Record<string, string[]> = {}
  for (let code of codes) {
    if (!reportMap[code]) {
      continue
    }
    let lines = reportMap[code].split('\n')
    let reportDates = lines[0].split('\t')
    //跳过表头
    for (let i=5;i< lines.length;i++) {
      let arr = lines[i].trim().split('\t')
      if (arr.length < 4) {
        //rows.push(`//${arr[0]}`)
        continue
      }
      let name = arr[0]
      if (!nameMap[name]) {
        nameMap[name] = []
      } else if (nameMap[name].length == 1) {
        //已经唯一确定，不需要继续处理了
        continue
      }

      for (let j=1; j< arr.length;j++) {
        let str = arr[j].trim()
        if (str == '--') {
          continue
        }      
        
        let minMax = genMinMaxValue(str)
        let keys: string[] = []
        let items = cache[`${code}-${sufix}`]
        for (let item of items) {
          if (item.reportDate != reportDates[j]) {
            continue
          }
          for (let key in item) {
            /*if (!/^\d+$/.test(key)) {
              //非数字，说明已经处理好了
              continue
            }*/
            let value = Math.round(item[key]*100)
            if (value > minMax[0] && value < minMax[1]) {
              keys.push(key)
            }
          }
        }

        if (!nameMap[name] || nameMap[name].length == 0 || keys.length == 1) {
          nameMap[name] = keys
        } else {
          //取交集
          nameMap[name] = nameMap[name].filter(value => keys.includes(value))
        }
      }
    }
  }

  let trans: string[][] = []
  let str = ''
  for (let name in nameMap) {
    for (let key of nameMap[name]) {
      str += `['${key}', '${key}', '${name}'],\n`
      //trans.push([key, key, name])
    }
  }

  log('guessReportDataUs', nameMap, str)
}

//单位统一到分
function genMinMaxValue(str: string) {
  str = str.trim()
  let unit = 100
  if (str.endsWith('亿')) {
    unit = 1e10
    str = str.replace('亿', '')
  } else if (str.endsWith('万')) {
    unit = 1e6
    str = str.replace('万', '')
  }

  let c = str.indexOf(".") > -1 ? str.split(".")[1].length: 0
  let value = 0
  if (c == 0) {
    value = parseInt(str)*unit
  } else {
    value = Math.round(parseFloat(str)*unit)
  }
  
  return [value-5*unit/Math.pow(10, c+1), value+5*unit/Math.pow(10, c+1)]
}

//13f 公司列表
function init13f() {
  const enChsMap: any = {
    'Berkshire Hathaway Inc': '伯克希尔哈撒韦',
    'HHLR ADVISORS, LTD.': '高领资本',
    'GOLDMAN SACHS GROUP INC': '高盛集团',
    'Bridgewater Associates, LP': '桥水基金',
    'ARK Investment Management LLC': '方舟基金',
    'BlackRock Inc.': '贝莱德公司',
    'VANGUARD GROUP INC': '先锋集团',
    'STATE STREET CORP': '道富银行',
    'GEODE CAPITAL MANAGEMENT, LLC': '富达资管',
    'SOROS FUND MANAGEMENT LLC': '索罗斯基金'
  }
  bsTable('managementTable', {
    request: (sortBy: string, asc: boolean, page: string): any => {
      return {
        url: 'https://13f.info/managers',
        cacheKey: '13f-company-list',
        cacheTtl: 360000,
        proxy: true
      }
    },
    row: (row: any, rowIdx: number) => {
      return {
        row: [rowIdx+1, `<a href="13f-position.html?id=${row[0]}&name=${row[2]}">${row[1]}<a>`, row[2], row[3]]
      }
    },
    transResults: (data: any) => {
      let begin = data.indexOf('<body')
      let end = data.indexOf('</body>')
      const root = document.createElement('div')
      root.innerHTML = data.substring(begin, end + '</body>'.length)
      let trs = root.querySelectorAll('tbody tr')
      let items: any = [
        ['0001759760-h-h-international-investment-llc', 'H&H International Investment, LLC', '段永平基金', '$14 B', 14000]
      ]
      trs.forEach((tr: Element, index: number)=>{
        let tds: any = tr.querySelectorAll('td')
        let id = tds[0].querySelector('a').getAttribute('href')?.substring('/manager/'.length)
        let en = tds[0].textContent?.trim()
        let chs = enChsMap[en]? enChsMap[en]: en
        let value = tds[4].textContent?.trim()
        let unit = 0
        if (value.indexOf('B') > 0) {
          unit = 1e3
        } else if (value.indexOf('T') > 0) {
          unit = 1e6
        } else if (value.indexOf('M') > 0) {
          unit = 1
        }

        let volume = parseInt(value.substring(1, value.length-2)) * unit
        items.push([id, en, chs, value, volume])
      })

      items.sort((a: any, b: any)=> {
        if (a[1] == a[2] && b[1] == b[2]) {
          return b[4]-a[4]
        } else if (a[1] == a[2]) {
          return 1
        } else if (b[1] == b[2]) {
          return -1
        }
        return b[4]-a[4]
      })
      return items
    }
  })

  /*let arr = []
  document.querySelectorAll('tbody tr').forEach((e)=>{
    let tds = e.querySelectorAll('td')
    let value = tds[4].textContent
    if (value?.indexOf('T') || value?.indexOf('B')) {
      let id = tds[0].querySelector('a').getAttribute('href')?.substring('/manager/'.length)
      arr.push([id, tds[0].textContent?.trim(), ''])
    }
  })
  console.log(arr)*/
}

function parse13fSeasionList(data: any) {
  let begin = data.indexOf('<body')
  let end = data.indexOf('</body>')
  const root = document.createElement('div')
  root.innerHTML = data.substring(begin, end + '</body>'.length)
  //log(data.substring(begin, end + '</body>'.length))
  let trs = root.querySelectorAll('#managerFilings tbody tr')
  let items: any = []
  trs.forEach((tr: Element, index: number)=>{
    let tds: any = tr.querySelectorAll('td')
    let item: any = []
    for (let td of tds) {
      item.push(td.textContent.trim())
    }
    let arr = item[0].split(' ')
    item[0] = arr[1]+arr[0]
    item[5] = tds[5].dataset.order
    //过滤掉非13F持仓的报告
    if (['13F-HR', 'RESTATEMENT'].includes(item[4])) {
      items.push(item)
    }
  })
  return items
}

function fetch13fPosition(filingId: string, callback: Callback) {
  let cacheKey =  `13f-${filingId}`
  fetchRequest({
    url:`https://13f.info/data/13f/${filingId}`,
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 360000
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss && data.data) {
      data = data.data
      for (let item of data) {
        if (!item[0]) {
          item[0] = ''
        } else if (item[0].indexOf(' ') < 0 && item[0].indexOf('.') < 0) {
          item[0] = item[0].replace('-', '.') + '.US'
        }
        //价值单位处理成万
        item[4] = (item[4]/10).toFixed(1)
      }
      cache[cacheKey] = data
    }

    callback(data)
  })
}

function gen13fKey(item: any) {
  let key = item[1]
  if (codeNameMap[item[0]]) {
    key = codeNameMap[item[0]]
  }

  if (item[8]) {
    key += '-' + item[8]
  }
  return key
}

function fetchCodeNames(codes: string[], callback: Callback) {
  if (codes.length < 1) {
    callback({})
    return
  }

  codes.sort()
  fetchRequest({
    url: `${server}/code/name?code=${codes.join(',')}`,
    cacheKey: 'fetchCodeNames' + codes.join(','),
    cacheTtl: 360000
  }, (data: any)=>{
    let hasNew = false
    for (let code in data) {
      if (data[code]) {
        hasNew = true
        codeNameMap[code] = data[code]
      }
    }
    if (hasNew)
      localStorage.setItem('codeNameMap', JSON.stringify(codeNameMap))
    callback(data)
  })
}

//data1是老的，data2是新的
function position13fCompare(data1: any, data2: any) {
  let key: string
  let positionMap: any = {}
  for (let item of data2) {
    key = gen13fKey(item)
    positionMap[key] = [0, item[0], key]
    positionMap[key][3] = 0
    positionMap[key][4] = item[5]
    positionMap[key][6] = 0
    positionMap[key][7] = item[4]
    positionMap[key][10] = 0
    positionMap[key][11] = item[6]
  }

  for (let item of data1) {
    key = gen13fKey(item)
    if (!positionMap[key]) {
      positionMap[key] = [0, item[0], key]
      positionMap[key][4] = 0
      positionMap[key][7] = 0
      positionMap[key][11] = 0
    }
    
    positionMap[key][3] = item[5]
    positionMap[key][6] = item[4]
    positionMap[key][10] = item[6]
  }

  let idx = 0
  let items: any = []
  let codes: string[] = []
  for (let key in positionMap) {
    idx++
    let item = positionMap[key]
    if (item[1] && codeNameMap[item[1]] == undefined) {
      codes.push(item[1])
    }
    item[0] = idx
    item[2] = `<a data-bs-toggle="modal" data-bs-target="#componentChangeModal" data-key="${key}">${item[2]}</a>`
    for (let i of [3, 6, 10]) {
      if (i != 3) {
        if (i == 6) {
          item[i+2] = (item[i+1] - item[i]).toFixed(1)
        } else {
          item[i+2] = item[i+1] - item[i]
        }
        if (item[i+1] == 0) {
          item[i+3] = '清仓'
        } else if (item[i] == 0) {
          item[i+3] = '新增'
        } else {
          item[i+3] = (100*item[i+1]/item[i] - 100).toFixed(2)
        }
      } else {
        item[i+2] = (item[i+1] - item[i]).toFixed(2)
      }
    }

    items.push(item)
  }
  
  bsTable('positionCompareTable', {
    data: items,
    cell(cell, columnIdx) {
      if ([5, 9, 13].includes(columnIdx)){
        let clss = ''
        if (cell > 0 || cell =='新增') {
          clss = 'text-danger'
        } else if (cell < 0 || cell == '清仓') {
          clss = 'text-success'
        }
        return {
          class: clss,
          data: cell
        }
      } else {
        return {
          data: cell
        }
      }
    }
  })

  codes = codes.filter((item, pos) => codes.indexOf(item) == pos)
  while (codes.length > 0) {
    let cut = codes.splice(0, 100)
    fetchCodeNames(cut, ()=>{})
  }
}

function fetchAndCompare13f() {
  let e1 = $('#reportDate1')
  let e2 = $('#reportDate2')

  document.querySelectorAll('span[name="date1"]').forEach((elem: any)=>{
    e1.querySelectorAll('option').forEach((e: any)=>{
      if (e.value == e1.value) {
        elem.textContent = e.textContent
      }
    })
  })

  document.querySelectorAll('span[name="date2"]').forEach((elem: any)=>{
    e2.querySelectorAll('option').forEach((e: any)=>{
      if (e.value == e2.value) {
        elem.textContent = e.textContent
      }
    })
  })

  let data1: any, data2: any
  let success = ()=>{
    if (!data1 || !data2) {
      return
    }
    position13fCompare(data1, data2)
  }

  fetch13fPosition(e1.value, (data: any)=>{
    data1 = data
    success()
  })

  fetch13fPosition(e2.value, (data: any)=>{
    data2 = data
    success()
  })
}

//管理规模趋势
function managementTrend(name: string, items: any) {
  let  seasons = items.length
  const xKey = 'reportDate'
  const yKey = 'value'
  let yUnit = '(亿元)'
  const code = 'code'
  let unit = 1e8
  let nameMap: any = {}
  nameMap[code] = name
  let yKeys = [yKey]
 
  let data: any = {}
  data[code] = []
  for (let i=0;i<items.length;i++) {
    data[code][i] = {}
    data[code][i][xKey] = items[i][0]
    data[code][i][yKey] = (parseInt(items[i][2].replace(/,/g, ''))/1e5).toFixed(2)
  }

  genBarLineCompareChart('managementTrendChart', [code], data, nameMap, yKeys, ['资产价值'], xKey, yUnit, seasons)
}

function quarter2ts(str: string) {
  let quarter = str.substring(str.length-2, str.length)
  let suffix = ''
  switch (quarter) {
    case 'Q1':
      suffix = '03-31'
      break
    case 'Q2':
      suffix = '06-30'
      break
    case 'Q3':
      suffix = '09-31'
      break
    case 'Q4':
      suffix = '12-31'
      break
    default:
      log('seasionquarter2ts2ts wrong quarter', quarter)
      return
  }

  str = str.substring(0, str.length-2) + '-' + suffix
  return toTimestamp(str)
}

function componentKlineChart(code: string, klineData: any, positionData: any) {
  const legendData = [`${codeNameMap[code]}-股价`, `${codeNameMap[code]}-持仓量`]
  let seriesData: any = [[], []]
  for (let item of positionData) {
    seriesData[1].push([quarter2ts(item[0]), item[1]])
  }

  let startTs = new Date(new Date().getFullYear()-3, 0, 1).getTime() + 3600*1000*8
  for (let item of klineData) {
    if (item[0] < startTs) {
      continue
    }
    seriesData[0].push([item[0], item[1]])
    //barData.push(item[1])
  }

  let yAxis: any[] = [
    {
      type: 'value',
      scale: true,
      position: 'right',
      //boundaryGap: [0.1, 0.1],
      axisLabel: {
        formatter: '{value}'
      },
    },
    {
      type: 'value',
      scale: true,
      position: 'left',
      //boundaryGap: [0.1, 0.1],
      axisLabel: {
        formatter: '{value}'
      },
      splitLine: {
        show: false
      }
    }
  ]

  let series: any = []
  for (let i=0;i<legendData.length;i++) {
    series.push({
      name: legendData[i],
      type: 'line',
      yAxisIndex: i,
      showSymbol: i > 0,
      emphasis: {
        scale: false,
      },
      data: seriesData[i]
    })
  }
 
  let id = 'financeChart'
  //let html = `<div id="${id}" style="min-height: 600px; min-width: 300px;"></div>`
  let chartDom: any = document.getElementById(id)
  // @ts-ignore
  echarts.dispose(chartDom)
  // @ts-ignore
  let myChart: any = echarts.init(chartDom)

  // @ts-ignore
  myChart.setOption({
    title: {
      text: ''
    },
    color: echartsColor,
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'cross'
      },
      formatter: function (params: any) {
        params.sort((a: any,b: any)=> b.value[1] - a.value[1])
        let str = toTimeString(params[0].value[0])
        for (let param of params) { // get data sorted
          let name = param.seriesName
          let value = param.value[1]
          str += `</br>${param.marker}${name}: ${formatKlineValue(value)}`
        }
        return str
      }
    },
    legend: {
      middle: 10
    },
    xAxis: {
      type: 'time',
      splitLine: {
        show: false
      },
    },
    yAxis: yAxis,
    series: series
  })
}

function componentChanageAndKlineChart(code: string, positionData: any) {
  fetchKline(code, '', (data: any)=> {
    componentKlineChart(code, data, positionData.reverse())
  })
}

function componentChangeModalShow(event: any) {
  const key = event.relatedTarget.dataset.key
  $('#singleChart').innerHTML = `<div id="financeChart" style="min-height: 600px; min-width: 300px;"></div>
  <table id="componentChangeTable" class="table table-bordered table-hover text-end">
    <thead class="table-success theadFix">
      <tr>
        <th class="sortable">持仓代码</th>
        <th class="sortable">持仓名称</th>
        <th class="sortable">季度</th>
        <th class="sortable">持仓占比%</th>
        <th class="sortable">价值(千)</th>
        <th class="sortable">价值变化%</th>
        <th class="sortable">股数</th>
        <th class="sortable">股数变化%</th>
      </tr>
    </thead>
  </table>`

  let dates: string[][] = []
  $('#reportDate1').querySelectorAll('option').forEach((elem: any)=>{
    dates.push([elem.value, elem.textContent])
  })

  dates = dates.slice(0, 10)
  let should = dates.length
  let done = 0
  let dataMap: any = {}
  let code = ''
  const success = ()=>{
    done++
    if (done != should) {
      return
    }
    let rows: any = []
    let positionData: any = []
    for (let date of dates) {
      for (let item of dataMap[date[1]]) {
        let name = gen13fKey(item)
        if (name != key) {
          continue
        }
        if (!code) {
          code = item[0]
        }
        rows.push([item[0], name, date[1], item[5], item[4], 0, item[6], 0])
        positionData.push([date[1], item[6], item[4]])
      }
    }

    for (let i =0; i < rows.length-1;i++) {
      rows[i][5] = (100*rows[i][4]/rows[i+1][4]- 100).toFixed(2)
      rows[i][7] = (100*rows[i][6]/rows[i+1][6]- 100).toFixed(2)
    }

    componentChanageAndKlineChart(code, positionData)

    bsTable('componentChangeTable', {
      data: rows,
      cell(cell, columnIdx) {
        let clss = ''
        if ([5, 7].includes(columnIdx)) {
          
          if (cell > 0) {
            clss = 'text-danger'
          } else if (cell < 0) {
            clss = 'text-success'
          }
          
        } 

        return {
          cell: cell,
          class: clss
        }
      },
    })
  }

  for (let date of dates) {
    fetch13fPosition(date[0], (data: any)=>{
      dataMap[date[1]] = data
      success()
    })
  }
}

function init13fPosition() {
  let id = query.id
  document.querySelectorAll('[name="company13f"]').forEach((elem: any)=>{
    elem.textContent = query.name
  })
  let cacheKey = '13f-' + id
  fetchRequest({
    url:`https://13f.info/manager/${id}`,
    proxy: true,
    cacheKey: cacheKey,
    cacheTtl: 3600
  }, (data: any, cacheMiss: boolean)=>{
    if (cacheMiss) {
      cache[cacheKey] = parse13fSeasionList(data)
      data = cache[cacheKey]
    }

    managementTrend(query.name, data)
    //下拉填充
    let options: any[] = []
    for (let item of data) {
      options.push({value: item[6], text: item[0]})
    }
    fillSelectOptions(options, data[1][6], 'reportDate1')
    fillSelectOptions(options, data[0][6], 'reportDate2')
    $('#reportDate1').addEventListener('change', fetchAndCompare13f)
    $('#reportDate2').addEventListener('change', fetchAndCompare13f)
    fetchAndCompare13f()
  })

  $('#componentChangeModal').addEventListener('shown.bs.modal', componentChangeModalShow)
}

function date2reportForamt(date: string, yearly: boolean) {
  if (yearly) {
    return date.substring(0, 4)
  }

  let month = date.substring(5, 7)
  let suffix = ''
  switch (month) {
    case '03':
      suffix = 'Q1'
      break
    case '06':
      suffix = 'Q2'
      break
    case '09':
      suffix = 'Q3'
      break
    case '12':
      suffix = 'Q4'
      break
    default:
      log('date2reportForamt,wrong date', date)
      return ''
  }
  return date.substring(2,4) + suffix
}

function genInfoTable(codes: string[]) {
  // @ts-ignore
  let  seasons = parseInt($('#seasons').value)
  let yearly: boolean = $('#compareType').dataset.id == 'yearly'
  let keys: any = [
    ['totalOperateIncome', '营收', 1e8],
    ['parentNetprofit', '净利润', 1e8],
    ['deductParentNetprofit', '扣非净利润', 1e8],
  ]
  let rows: any = []
  for (let code of codes) {
    for (let i = 0; i < seasons; i++) {
      for (let key of keys) {
        rows.push([codeNameMap[code], key[1], date2reportForamt(reportsMap[code][i].reportDate, yearly), (reportsMap[code][i][key[0]]/key[2]).toFixed(2)])
      }
    }
  }
  bsTable('infoTable', {data: rows})
}

function onInfoCodeSelectChange() {
  selectedCodes = $('#codes').val()
  if (selectedCodes.length == 0) {
    log('codes none')
    return
  }

  fetch2FormatFinanceData(selectedCodes, genInfoTable)
}

function initInfo() {
  codeSelectInit(['SH', 'SZ', 'HK', 'US'], 'codes', '股票对比', false)
  $("#codes").addEventListener("change", onInfoCodeSelectChange)
  $('#compareType').addEventListener('bs.change', onInfoCodeSelectChange)
  $('#seasons').addEventListener('change', onInfoCodeSelectChange)
  onInfoCodeSelectChange()
  bsRadioButtons('compareType')
}

function genFollowTable(codes: string[]) {
  const days = [30, 90]
  let success = (codes: string[])=>{
    let rows: any = []
    for (let code of codes) {
      let kline = cache[code]
      //时间戳、收盘，开盘、最低、最高、成交量、成交额、市值、pe、pb、ps、pcf
      let idx = kline.length-1
      let changeRatio = (kline[idx][1]*100/kline[idx-1][1] - 100).toFixed(2)
      let row: any = [code, codeNameMap[code], kline[idx][1], changeRatio]
      let high: any = {}
      let low: any = {}
      let lastDayTs = kline[idx][0]
      for (let j = kline.length - 1; j >= 0; j--) {  
        for (let k = 0; k < days.length; k++) {
          if (days[k] in high) { } else {
            high[days[k]] = 0;
          }
          if (days[k] in low) { } else {
            low[days[k]] = 9999999;
          }
  
          let ts = lastDayTs - 24 * 3600 * 1000 * days[k]
          if (ts < kline[j][0]) {
            if (kline[j][1] > high[days[k]]) {
              high[days[k]] = kline[j][1]
            }
            if (kline[j][1] < low[days[k]]) {
              low[days[k]] = kline[j][1]
            }
          } else if (row.length <= 4 + k * 2) {
            row.push((row[2]*100/high[days[k]] -100).toFixed(2))
            row.push((row[2]*100/low[days[k]] -100).toFixed(2))
          }
        }
      }
      row.push((kline[idx][7]/1e8).toFixed(2), kline[idx][8].toFixed(2), kline[idx][9].toFixed(2), (kline[idx][9]*100/kline[idx][8]).toFixed(2))
      rows.push(row)
    }

    bsTable('companiesFollowTable', {
      data: rows,
      cell: (cell: any, columnIdx: number): any => {
        let data: any = {}
        if (columnIdx == 0) {
          data.cell = `<a href="company.html?code=${cell}" target="_blank">${cell}</a>`
        } else if (columnIdx > 1 && columnIdx < 8) {
          if (cell > 0) {
            data.class = 'text-danger'
          } else if ( cell < 0) {
            data.class = 'text-success'
          }
        }
        return data
      }
    })
  }

  fetchKlines(codes, '', success)
}

function onFollowCodeSelectChange() {
  selectedCodes = $('#codes').val()
  if (selectedCodes.length == 0) {
    log('codes none')
    return
  }
  
  localStorage.setItem('follow', selectedCodes.join(','))
  genFollowTable(selectedCodes)
}

function initFollowCodes() {
  let codeStr = localStorage.getItem('follow')
  if (!codeStr) {
    return
  }

  let codes = codeStr.split(',')
  replaceUrlParam('code', codeStr)
  genFollowTable(codes)
}

function initCompaniesFollow() {
  initFollowCodes()
  $('#codes').addEventListener('change', onFollowCodeSelectChange)
  codeSelectInit(['SH', 'SZ', 'HK', 'US'], 'codes', '股票关注', false)
}

export function pageInit() {
  commonInit()
  switch (currentPage()) {
    case 'coin.html':
      initCoin()
      break
    case '':
    case 'companies-holding.html':
      initCompaniesHolding()
      break
    case 'companies-filter.html':
      initCompaniesFilter()
      break
    case 'companies-follow.html':
      initCompaniesFollow()
      break
    case 'company.html':
      initCompany()
      break
    case 'company-option.html':
      initCompanyOption()
      break
    case 'company-finance.html':
      initCompanyFinance()
      break
    case 'company-holders.html':
      initCompanyHolders()
      break
    case 'company-dividend.html':
      initCompanyDividend()
      break
    case 'company-shares.html':
      initCompanyShares()
      break
    case 'company-notice.html':
      initCompanyNotice()
      break
    case 'company-report.html':
      initCompanyReport()
      break
    case 'company-news.html':
      initCompanyNews()
      break
    case 'company-valuation.html':
      initCompanyValuation()
      break
    case 'company-report-predict.html':
      initCompanyReportPredict()
      break
    case 'reports.html':
      initReports()
      break
    case 'fund.html':
      initFund()
      break
    case 'fund-position.html':
      initFundPosition()
      break
    case 'funds.html':
      initFunds()
      break
    case 'home.html':
      initHome()
      break
    case 'indexes.html':
      initIndexes()
      break
    case 'index.html':
      initIndex()
      break
    case 'index-position.html':
      initIndexPosition()
      break
    case 'options.html':
      initOptions()
      break
    case 'stg.html':
      initStg()
      break
    case 'login.html':
      initLogin()
      break
    case 'chart.html':
      initDrawChart()
      break
    case '13f.html':
      init13f()
      break
    case '13f-position.html':
      init13fPosition()
      break
    case 'info.html':
      initInfo()
      break
    default:
      log('wrong page', currentPage())
      break
  }
}
